import { Mesh, Tags, Vector3 } from 'babylonjs';
import { DBSCAN } from 'density-clustering';
import { PatientGender, VertebralBody } from '@workflow-nx/common';
import { AutoCorrectionConfigType } from '../shared/types';
import {
  evaluateDerivative,
  evaluateFirstVBSegment,
  evaluateInverseHeightMap,
  evaluateLargestNumberSegment,
  evaluateNormalizedDerivative,
  evaluateStartAndEndIndices,
  evaluateSubarrayIndices,
} from './landmarkingPointEvaluation';
import { cloneDeep } from 'lodash';
import { CrossSection } from '../shared/enum';

export const filterNullIntercepts = (intercepts: (Vector3 | null)[][]) => {
  const refinedIntercepts: Vector3[] = [];

  intercepts.flat().forEach((intercept: Vector3 | null) => {
    if (intercept) {
      refinedIntercepts.push(intercept);
    }
  });

  return refinedIntercepts;
};

export const validateClusterCount = (intercepts: Vector3[], config: AutoCorrectionConfigType) => {
  const convertedInterceptVectors: number[][] = [];
  intercepts.forEach((col: Vector3) => {
    const { x, y, z } = col;
    convertedInterceptVectors.push([x, y, z]);
  });

  const dbScan = new DBSCAN();
  const clusters: number[][] = dbScan.run(
    convertedInterceptVectors,
    config.DBSCAN_AXIAL_VALIDATION,
    config.DBSCAN_CLUSTER_COUNT,
  );

  return clusters.length;
};

export const filterInterceptsDBScanDerivatives = (
  derivatives: (number | null)[],
  threshold: number,
  config: AutoCorrectionConfigType,
): number[][] => {
  const refinedEndplateIntercepts: number[][] = [];
  const coordinates: number[][] = [];

  derivatives.forEach((derivative, i) => {
    if (derivative) {
      coordinates.push([i, derivative]);
    }
  });

  const dbScan = new DBSCAN();
  const clusters: number[][] = dbScan.run(coordinates, threshold, config.DBSCAN_CLUSTER_COUNT);

  let targetCluster: number[] = [];

  let highestClusterCount = 0;
  clusters.forEach((cluster: number[]) => {
    if (cluster.length > highestClusterCount) {
      highestClusterCount = cluster.length;
      targetCluster = cluster;
    }
  });

  let count = 0;
  coordinates.forEach((row: number[]) => {
    if (targetCluster.includes(count)) {
      refinedEndplateIntercepts.push(row);
    }

    count++;
  });

  return refinedEndplateIntercepts;
};

export const filterInterceptsDBScanXYCoordinates = (
  coordinates: number[][],
  config: AutoCorrectionConfigType,
): number[][] => {
  const refinedCoordinates: number[][] = [];

  const dbScan: DBSCAN = new DBSCAN();
  const clusters: number[][] = dbScan.run(
    coordinates,
    config.DBSCAN_SCAN_XY_RADIUS,
    config.DBSCAN_CLUSTER_COUNT,
  );

  let targetCluster: number[] = [];

  let highestClusterCount = 0;
  clusters.forEach((cluster: number[]) => {
    if (cluster.length > highestClusterCount) {
      highestClusterCount = cluster.length;
      targetCluster = cluster;
    }
  });

  let count = 0;

  coordinates.forEach((row: number[]) => {
    if (targetCluster.includes(count)) {
      refinedCoordinates.push(row);
    }

    count++;
  });

  return refinedCoordinates;
};

export const filterInterceptsDBScanVectors = (
  intercepts: (Vector3 | null)[][],
  config: AutoCorrectionConfigType,
  isHighFidelity = false,
): (Vector3 | null)[][] => {
  const refinedEndplateIntercepts: (Vector3 | null)[][] = [];

  const convertedInterceptVectors: number[][] = [];
  intercepts.forEach((row: (Vector3 | null)[]) => {
    row.forEach((col: Vector3 | null) => {
      if (col) {
        const { x, y, z } = col;
        convertedInterceptVectors.push([x, y, z]);
      }
    });
  });

  const dbScan: DBSCAN = new DBSCAN();
  const clusters: number[][] = dbScan.run(
    convertedInterceptVectors,
    isHighFidelity
      ? config.DBSCAN_SCAN_HIGH_FIDELITY_ARTIFACT_RADIUS
      : config.DBSCAN_SCAN_LOW_FIDELITY_ARTIFACT_RADIUS,
    config.DBSCAN_CLUSTER_COUNT,
  );

  let targetCluster: number[] = [];

  let highestClusterCount = 0;
  clusters.forEach((cluster: number[]) => {
    if (cluster.length > highestClusterCount) {
      highestClusterCount = cluster.length;
      targetCluster = cluster;
    }
  });

  let count = 0;

  intercepts.forEach((row: (Vector3 | null)[]) => {
    const refinedEndplateInterceptsRow: (Vector3 | null)[] = [];

    row.forEach((col: Vector3 | null) => {
      if (targetCluster.includes(count)) {
        refinedEndplateInterceptsRow.push(col);
      } else {
        refinedEndplateInterceptsRow.push(null);
      }

      if (col) {
        count++;
      }
    });
    refinedEndplateIntercepts.push(refinedEndplateInterceptsRow);
  });

  return refinedEndplateIntercepts;
};

export const transposedData = (intercepts: (Vector3 | null)[][]) => {
  const rows = intercepts.length;
  const cols = intercepts[0].length;

  // Transpose the array
  const transposedData: (Vector3 | null)[][] = [];
  for (let j = 0; j < cols; j++) {
    transposedData[j] = [];
    for (let i = 0; i < rows; i++) {
      transposedData[j][i] = intercepts[i][j];
    }
  }

  return transposedData;
};

export const filterSacrumByDerivative = (
  derivative: (number | null)[][],
  intercepts: (Vector3 | null)[][],
  config: AutoCorrectionConfigType,
  gender: PatientGender,
): (Vector3 | null)[][] => {
  const filteredIntercepts: (Vector3 | null)[][] = cloneDeep(intercepts);

  let sacrumMaxCutOff = Math.round(
    config.SACRUM_MAX_ML_CUT_OFF_MALE / config.RAY_CAST_LOW_FIDELITY,
  );
  if (gender === PatientGender.Female) {
    sacrumMaxCutOff = Math.round(
      config.SACRUM_MAX_ML_CUT_OFF_FEMALE / config.RAY_CAST_LOW_FIDELITY,
    );
  }

  // find target row where identify the first continuous intersection of promontory and ala sacralis
  // intersection of promontory and ala sacralis is key anatomical feature which will set the sacrum row limits
  let targetIndex = 0;
  for (let i = 0; i < derivative.length; i++) {
    const row: (number | null)[] = cloneDeep(derivative[i]);
    const next: (number | null)[] = cloneDeep(derivative[i + 1]);

    // Remove nulls from the beginning of the array
    while (row.length > 0 && row[0] === null) {
      row.shift();
    }

    // Remove nulls from the end of the array
    while (row.length > 0 && row[row.length - 1] === null) {
      row.pop();
    }

    while (next?.length > 0 && next[0] === null) {
      next.shift();
    }

    // Remove nulls from the end of the array
    while (next?.length > 0 && next[next?.length - 1] === null) {
      next.pop();
    }

    if (row.includes(null) && !next.includes(null) && next.length >= sacrumMaxCutOff) {
      targetIndex = i + 1;
      break;
    }
  }

  const targetRow: (number | null)[] = derivative[targetIndex];

  const { startIndex, endIndex } = evaluateStartAndEndIndices(targetRow);

  for (let i = endIndex - 10; i <= endIndex; i++) {
    targetRow[i] = null;
  }

  for (let i = startIndex; i < startIndex + 10; i++) {
    targetRow[i] = null;
  }

  const { startIndex: reEvaluatedStartIndex, endIndex: reEvaluatedEndIndex } =
    evaluateStartAndEndIndices(targetRow);

  let minIndex = reEvaluatedStartIndex;
  let maxIndex = reEvaluatedEndIndex;

  for (let i = 1; i < targetRow.length; i++) {
    const currentTarget: number | null = targetRow[i];

    if (!currentTarget) {
      continue;
    }

    if (parseFloat(`${currentTarget}`) < parseFloat(`${targetRow[minIndex]}`)) {
      minIndex = i;
    }

    if (parseFloat(`${currentTarget}`) > parseFloat(`${targetRow[maxIndex]}`)) {
      maxIndex = i;
    }
  }

  const evaluatedEndplateLength = maxIndex - minIndex;

  if (
    evaluatedEndplateLength * config.RAY_CAST_LOW_FIDELITY < sacrumMaxCutOff / 2 ||
    evaluatedEndplateLength * config.RAY_CAST_LOW_FIDELITY > sacrumMaxCutOff * 2
  ) {
    const midIndex: number = startIndex + Math.round((endIndex - startIndex) / 2);
    minIndex = Math.round(
      sacrumMaxCutOff / 2 > midIndex ? startIndex : midIndex - sacrumMaxCutOff / 2,
    );
    maxIndex = Math.round(midIndex + sacrumMaxCutOff / 2);
  }

  filteredIntercepts.forEach((row) => {
    row.forEach((_, j) => {
      if (j > maxIndex || j < minIndex) {
        row[j] = null;
      }
    });
  });

  return filteredIntercepts;
};

export const filterByDerivative = (
  endplatePoints: (Vector3 | null)[][],
  config: AutoCorrectionConfigType,
  isTransposed = false,
): (Vector3 | null)[][] => {
  const filteredIntercepts: (Vector3 | null)[][] = cloneDeep(endplatePoints);

  const inverseHeights: (number | null)[][] = evaluateInverseHeightMap(filteredIntercepts);
  const firstDerivative: (number | null)[][] = evaluateDerivative(inverseHeights, config);
  const secondDerivative: (number | null)[][] = evaluateDerivative(firstDerivative, config);

  const midIndex = isTransposed
    ? Math.round(secondDerivative.length / 2)
    : Math.round(secondDerivative.length / 3);
  const { startIndex: endplateStart, endIndex: endplateEnd } = evaluateStartAndEndIndices(
    secondDerivative[midIndex],
  );

  secondDerivative.forEach((derivativeRow: (number | null)[], i) => {
    const normalizedDerivative: (number | null)[] = evaluateNormalizedDerivative(derivativeRow);
    const DBSCANDerivative: number[] = filterInterceptsDBScanDerivatives(
      normalizedDerivative,
      isTransposed
        ? config.DBSCAN_SCAN_DERIVATIVE_TRANSPOSED_RADIUS
        : config.DBSCAN_SCAN_DERIVATIVE_RADIUS,
      config,
    ).map((coordinates: number[]) => coordinates[1]);

    const clusterStart: number = normalizedDerivative.findIndex(
      (derivative) => DBSCANDerivative[0] === derivative,
    );
    const clusterEnd: number = normalizedDerivative.findIndex(
      (derivative) => DBSCANDerivative[DBSCANDerivative.length - 1] === derivative,
    );

    const startIndex: number = clusterStart > endplateStart ? clusterStart : endplateStart;
    const endIndex: number = clusterEnd < endplateEnd ? clusterEnd : endplateEnd;

    normalizedDerivative.forEach((_derivative: number | null, m) => {
      if (
        m < startIndex - Math.round(1 / config.RAY_CAST_LOW_FIDELITY) ||
        m > endIndex + Math.round(1 / config.RAY_CAST_LOW_FIDELITY)
      ) {
        filteredIntercepts[i][m] = null;
      }
    });
  });

  return filteredIntercepts;
};

export const filterByAlaPromontoryIntersection = (
  endplatePoints: (Vector3 | null)[][],
  config: AutoCorrectionConfigType,
  gender: PatientGender,
): (Vector3 | null)[][] => {
  let filteredIntercepts: (Vector3 | null)[][] = cloneDeep(endplatePoints);

  const inverseHeights: (number | null)[][] = evaluateInverseHeightMap(filteredIntercepts);
  const firstDerivative: (number | null)[][] = evaluateDerivative(inverseHeights, config);

  filteredIntercepts = filterSacrumByDerivative(
    firstDerivative,
    filteredIntercepts,
    config,
    gender,
  );

  return filteredIntercepts;
};

export const filterEndplateIntercepts = (
  endplatePoints: (Vector3 | null)[][],
  mesh: Mesh,
  config: AutoCorrectionConfigType,
  gender: PatientGender,
): (Vector3 | null)[][] => {
  let filteredEndplatePoints: (Vector3 | null)[][] = endplatePoints;

  if (Tags.GetTags(mesh).includes(VertebralBody.S1)) {
    filteredEndplatePoints = filterByAlaPromontoryIntersection(
      filteredEndplatePoints,
      config,
      gender,
    );
    filteredEndplatePoints = filterInterceptsDBScanVectors(filteredEndplatePoints, config);
  } else {
    filteredEndplatePoints = filterByDerivative(filteredEndplatePoints, config);
    filteredEndplatePoints = filterInterceptsDBScanVectors(filteredEndplatePoints, config);
  }

  return filteredEndplatePoints;
};

export const validateCrossSection = (
  intercepts: (Vector3 | null)[],
  crossSection: CrossSection,
  config: AutoCorrectionConfigType,
): (Vector3 | null)[] => {
  const [inverseHeights]: (number | null)[][] = evaluateInverseHeightMap([intercepts]);
  const firstDerivative: (number | null)[] = evaluateDerivative([inverseHeights], config)[0];
  const secondDerivative: (number | null)[] = evaluateDerivative([firstDerivative], config)[0];
  const normalizedDerivative: (number | null)[] = evaluateNormalizedDerivative(secondDerivative);

  const targetSegment: number[] =
    crossSection === CrossSection.AP
      ? evaluateFirstVBSegment(normalizedDerivative)
      : evaluateLargestNumberSegment(normalizedDerivative);

  const subTargetIndex: number = evaluateSubarrayIndices(normalizedDerivative, targetSegment);

  const filteredSegment: number[][] = filterInterceptsDBScanDerivatives(
    targetSegment,
    config.DBSCAN_SCAN_CROSS_SECTION_RADIUS,
    config,
  );

  const startIndex: number = subTargetIndex + filteredSegment[0][0];
  const endIndex: number = subTargetIndex + filteredSegment[filteredSegment.length - 1][0];

  const targetIntercept: (Vector3 | null)[] = intercepts.slice(startIndex, endIndex);

  const filteredTargetIntercepts: (Vector3 | null)[][] = filterInterceptsDBScanVectors(
    [targetIntercept],
    config,
    true,
  );

  return filteredTargetIntercepts[0];
};
