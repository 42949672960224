import {
  AssetType,
  CaseStageType,
  caseUtils,
  planUtils,
  format,
  IAsset,
  ICase,
  IPlan,
  IUser,
  Permission,
  UserRoleType,
  ImplantCharacteristicType,
  LevelSize,
  LevelAdditionalImages,
  LevelType,
  EncodedAdditionalImageType,
  CaseRiskAssessmentType,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState, useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  FIND_FORM19,
  FIND_USERS,
  UPSERT_CASE_IMPLANT_SPECIFICATION,
  UPSERT_FORM19,
  DELETE_ASSET,
} from '../../../../gql';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Box } from '@mui/material';
import CustomDialog from '../../../../components/CustomDialog';
import { useConfirm } from 'material-ui-confirm';
import useAuth from '../../../../hooks/useAuth';
import ActionButton from '../../../../components/ActionButton';
import { date, file, pdf } from '@workflow-nx/utils';
import * as Yup from 'yup';
import * as form19Utils from '../../../../utils/form19';
import {
  Form19FillDialogReducer,
  Form19StateType,
  CaseImplantSpecificationInputType,
  Form19StateLevelsValuesType,
} from './Form19FillDialog.reducer';
import { Form19ManualEntryForm } from './Form19ManualEntryForm';
import * as FileSaver from 'file-saver';
import { Alert } from '@mui/material';
import useCreateAndUploadAsset from '../../../../hooks/useCreateAndUploadAsset';
import { useSurgeonExcludedImplantSizes } from '../../../../hooks/useSurgeonExcludedImplantSizes';
import { FeatureFlag } from '../../../../utils/featureFlags';

function isValidDesignStageForm19Approver(activeCase: ICase, currentUser: IUser | undefined) {
  if (!currentUser) {
    return false;
  }

  if (activeCase?.assignedUser?.userId === currentUser?.userId) {
    return true;
  }

  return [
    UserRoleType.SiteAdministrator,
    UserRoleType.CaseAdmin,
    UserRoleType.CaseEngineer,
  ].includes(currentUser.role);
}

export function Form19FillDialog({
  activeCase,
  onClose,
  open,
  plan,
}: {
  activeCase: ICase;
  onClose: (shouldUpdate: boolean) => void;
  open: boolean;
  plan: IPlan;
}) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { hasRole, user: currentUser } = useAuth();
  const [upsertForm19] = useMutation(UPSERT_FORM19);
  const [deleteAsset] = useMutation(DELETE_ASSET);
  const [upsertCaseImplantSpecification] = useMutation(UPSERT_CASE_IMPLANT_SPECIFICATION);
  const { createAndUploadAsset } = useCreateAndUploadAsset();
  const [peerReviewers, setPeerReviewers] = useState<{ key: string; value: string }[]>([]);
  const [peerReviewerUsers, setPeerReviewerUsers] = useState<IUser[]>([]);
  const [encodedImageData, setEncodedImageData] = useState('');
  const [encodedAdditionalImages, setEncodedAdditionalImages] = useState<
    EncodedAdditionalImageType[]
  >([]);
  const [deleteAdditionalImagesAssetIds, setDeleteAdditionalImagesAssetIds] = useState<number[]>(
    [],
  );
  const [uploadingImage, setUploadingImage] = useState(false);

  const engineeringUserId =
    hasRole &&
    hasRole([UserRoleType.CaseEngineer, UserRoleType.SiteAdministrator, UserRoleType.CaseAdmin])
      ? currentUser?.userId
      : undefined;
  const isValidQAReviewStageForm19Approver =
    hasRole && hasRole([UserRoleType.QualityEngineer, UserRoleType.SiteAdministrator]);
  const approvedAt = date.getCurrentISODateTimeString();
  const permissions = auth?.user?.permissions;
  const canEditForm19 =
    !!permissions?.includes(Permission.ManageCase) ||
    !!permissions?.includes(Permission.EditCase) ||
    !!permissions?.includes(Permission.QaReviewCase);

  const canApproveDesignStageForm19 =
    isValidDesignStageForm19Approver(activeCase, auth.user) && canEditForm19;
  const warningWhoCanApproveForm19 =
    !canApproveDesignStageForm19 && activeCase.stage === CaseStageType.Design;
  const canApproveQAReviewStageForm19 = !!isValidQAReviewStageForm19Approver && canEditForm19;
  const isPreProductionEditable = caseUtils.isCaseInPreProduction(activeCase.stage);
  const caseLevelsTypes = caseUtils.getValidCaseLevelsTypes(activeCase.levels);

  const allowedPlusSizeExcludedImplantTypes = useSurgeonExcludedImplantSizes(
    activeCase?.levels,
    activeCase?.surgeonUser?.userId,
  );
  const excludedLevelsImplants = planUtils.getExcludedLevelsImplants(
    activeCase?.levels,
    plan as IPlan,
  );
  const validCaseExcludedImplants = planUtils.getValidCaseExcludedImplants(
    allowedPlusSizeExcludedImplantTypes,
    excludedLevelsImplants,
  );
  const isImplantSizeExclusionEnabled = auth.hasFeatureFlag?.(
    FeatureFlag.implantSizeExclusionEnabled,
  );
  const planKitBom = plan?.planKitBom;

  const [findForm19, { loading }] = useLazyQuery(FIND_FORM19, {
    variables: {
      caseId: activeCase.caseId,
      planId: plan.planId,
      assetTypeFilter: [
        ...form19Utils.getValidImplantAssets(caseLevelsTypes),
        ...form19Utils.getValidForm19AdditionalImageAssets(caseLevelsTypes),
        AssetType.Form19ApprovalSignature,
      ],
      deletedAt: null,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch({ type: 'INIT', data });
    },
  });

  useQuery(FIND_USERS, {
    fetchPolicy: 'network-only',
    variables: { roleFilter: [UserRoleType.CaseEngineer, UserRoleType.CaseAdmin] },
    onCompleted: (data) => {
      const peerReviewers: { key: string; value: string }[] = [];
      const users = data?.users?.users ?? [];

      setPeerReviewerUsers(users);
      for (const user of users) {
        peerReviewers.push({ key: user.userId, value: format.formatName(user) });
      }
      setPeerReviewers(peerReviewers);
    },
  });

  const [state, dispatch] = useReducer(Form19FillDialogReducer(activeCase), {
    areAssetsReady: false,
    assets: [],
    caseImplantSpecifications: [],
    isInitialized: false,
    form19Settings: {},
    form19Data: {},
    form19History: [],
    form19Id: 0,
    caseId: activeCase.caseId,
    isImageSliceValid: false,
    isNonConformance: false,
    nonConformanceReason: '',
    regenerateReason: '',
    caseRiskAssessment: '',
    revision: 0,
    description: 'Initial Approval of Implant Designs',
    peerReviewerId: '',
    plusLevelSize: plan?.plusLevelSize ? plan?.plusLevelSize : 2,
    planApprovedAt: plan?.approvedAt ? plan?.approvedAt : null,
    form20Products: [],
    productDates: {},
  });

  const defaultLevelValueSchema = Yup.lazy((valueData?: Form19StateLevelsValuesType | any) => {
    if (!valueData?.type) {
      return Yup.object();
    }

    switch (valueData.type) {
      case 'ANGLE':
      case 'PART_ATTRIBUTE':
        return Yup.object().shape({
          minus01: Yup.number().required(),
          plan02: Yup.number().required(),
          plus03: Yup.number().required(),
        });
      case 'MANUAL_ATTRIBUTE':
        break;
      case 'NOTE':
        return Yup.object().shape({
          notes: Yup.string().optional(),
        });
      case 'REVISION':
        return Yup.object().shape({
          revision: Yup.number().required(),
          description: Yup.string().required(),
        });
    }

    return Yup.object();
  });

  const form19DialogSchema = Yup.object().shape({
    isImageSliceValid: Yup.bool().required(),
    revision: Yup.number().required(),
    description: Yup.string().required(),
    ...(canApproveQAReviewStageForm19 && activeCase.stage === CaseStageType.Production
      ? { regenerateReason: Yup.string().required('Regenerate Reason is required') }
      : {}),
    isNonConformance: Yup.bool(),
    nonConformanceReason: Yup.string().when('isNonConformance', {
      is: true,
      then: (schema) => schema.required('Non Conformance Reason Required'),
    }),
    caseRiskAssessment: Yup.string(),
    peerReviewerId: Yup.string().when('caseRiskAssessment', {
      is: 'CASE_REVIEW_QA_REVIEW',
      then: (schema) => schema.required('Peer Reviewer Required'),
    }),
    angles: Yup.array().of(
      Yup.object().shape({
        minus01: Yup.number().required(),
        plan02: Yup.number().required(),
        plus03: Yup.number().required(),
      }),
    ),
    manualAttributes: Yup.array().of(
      Yup.object().shape({
        minus01: Yup.bool().required(),
        plan02: Yup.bool().required(),
        plus03: Yup.bool().required(),
      }),
    ),
    partAttributes: Yup.array().of(
      Yup.object().shape({
        minus01: Yup.number().required(),
        plan02: Yup.number().required(),
        plus03: Yup.number().required(),
      }),
    ),
    levels: Yup.array().of(
      Yup.object().shape({
        values: Yup.array().of(defaultLevelValueSchema),
      }),
    ),
  });

  const handlePreviewClick = async (form19ManualData: any) => {
    let form19Data: any = {};
    if (form19ManualData?.isNonConformance && form19ManualData.nonConformanceReason === '') {
      enqueueSnackbar('Non Conformance Reason is missing', {
        variant: 'error',
      });
      return;
    }
    try {
      if (!state.form19Data?.engineeringApprovedAt) {
        const caseLevelsData = caseUtils.getValidCaseLevelsWithPartTypes(activeCase.levels);
        const partAttributes = form19Utils.getAttributes(form19ManualData.levels, 'PART_ATTRIBUTE');
        const manualAttributes = form19Utils.getAttributes(
          form19ManualData.levels,
          'MANUAL_ATTRIBUTE',
        );

        const levelAdditionalImages: LevelAdditionalImages[] = form19Utils.getAttributes(
          form19ManualData.levels,
          'ADDITIONAL_IMAGE',
        );
        const encodedAdditionalImagesArr = encodedAdditionalImages;
        for (const levelAdditionalImage of levelAdditionalImages) {
          if (levelAdditionalImage.additionalImage) {
            const encodeImage = await file.encodeBlobToBase64(levelAdditionalImage.additionalImage);
            const encodedImageIndex = encodedAdditionalImagesArr.findIndex(
              (element) => element.levelType === levelAdditionalImage.level,
            );
            if (encodedImageIndex !== -1) {
              encodedAdditionalImagesArr[encodedImageIndex].encodedAdditionalImage = encodeImage;
            } else {
              encodedAdditionalImagesArr.push({
                levelType: levelAdditionalImage.level,
                encodedAdditionalImage: encodeImage,
              });
            }
          }
        }

        setEncodedAdditionalImages(encodedAdditionalImagesArr);

        const angles = form19Utils.getAttributes(form19ManualData.levels, 'ANGLE');
        const levelNotes = form19Utils.getAttributes(form19ManualData.levels, 'NOTE');
        const levelRevisions = form19Utils.getAttributes(form19ManualData.levels, 'REVISION');
        const alifXLevelScrewLengthTypes = form19Utils.getAttributes(
          form19ManualData.levels,
          'SCREW_LENGTH',
        );
        form19ManualData.partAttributes = partAttributes;
        form19ManualData.manualAttributes = manualAttributes;
        form19ManualData.angles = angles;
        form19ManualData.levelNotes = levelNotes;
        form19ManualData.levelRevisions = levelRevisions;
        form19ManualData.alifXLevelScrewLengthTypes = alifXLevelScrewLengthTypes;

        form19Data = form19Utils.generateForm19CaseData(
          activeCase,
          state.form19Settings,
          form19ManualData,
          caseLevelsData,
          encodedImageData,
          encodedAdditionalImages,
          state.form20Products,
          state.planApprovedAt,
          state.productDates,
          isImplantSizeExclusionEnabled,
          planKitBom,
          validCaseExcludedImplants,
        );
      } else {
        form19Data = JSON.parse(JSON.stringify(state.form19Data?.data));
      }

      const form19Pdf = await pdf.form19(form19Data);
      FileSaver.saveAs(
        form19Pdf,
        `${activeCase.number} FORM-019 Patient Specific Workflow Approval.pdf`,
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred creating the FORM-019 pdf', {
        variant: 'error',
      });
    }
  };

  const handlePreviewByQuality = async () => {
    try {
      const form19Pdf = await pdf.form19(state.form19Data?.data);
      FileSaver.saveAs(
        form19Pdf,
        `${activeCase.number} FORM-019 Patient Specific Workflow Approval.pdf`,
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred creating the FORM-019 pdf', {
        variant: 'error',
      });
    }
  };

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    try {
      let title = 'Save and approve FORM-019?';
      let description = 'Are you sure you want to approve FORM-019?';
      if (canApproveQAReviewStageForm19) {
        title = 'Regenerate and approve FORM-019?';
        description = 'Are you sure you want to regenerate FORM-019?';
      }

      await confirm({
        title,
        description,
      });

      const levelAdditionalImages: LevelAdditionalImages[] = form19Utils.getAttributes(
        values.levels,
        'ADDITIONAL_IMAGE',
      );
      const assets = [];
      const encodedAdditionalImagesArr = encodedAdditionalImages;
      for (const levelAdditionalImage of levelAdditionalImages) {
        if (levelAdditionalImage.additionalImage) {
          assets.push({
            fileName: levelAdditionalImage.additionalImage.name,
            size: levelAdditionalImage.additionalImage.size,
            assetType: levelAdditionalImage.assetType,
            metadata: undefined,
            file: levelAdditionalImage.additionalImage,
          });
          const encodeImage = await file.encodeBlobToBase64(levelAdditionalImage.additionalImage);
          const encodedImageIndex = encodedAdditionalImagesArr.findIndex(
            (element) => element.levelType === levelAdditionalImage.level,
          );
          if (encodedImageIndex !== -1) {
            encodedAdditionalImagesArr[encodedImageIndex].encodedAdditionalImage = encodeImage;
          } else {
            encodedAdditionalImagesArr.push({
              levelType: levelAdditionalImage.level,
              encodedAdditionalImage: encodeImage,
            });
          }
        }
      }
      setEncodedAdditionalImages(encodedAdditionalImagesArr);

      const form19Data = await createForm19Data(values);
      if (engineeringUserId) {
        form19Data.approvals.engineeringApprovedBy = format.formatName(currentUser);
        form19Data.approvals.engineeringApprovedAt = format.formatISODate(approvedAt, 'MM/dd/yyyy');

        form19Data.approvals.peerReviewer = format.formatName(
          peerReviewerUsers.find((user) => user.userId === Number(values?.peerReviewerId)),
        );
      }

      const specificationDataRows = createCaseImplantSpecificationData(
        state.caseId,
        values as Form19StateType,
      );

      await upsertCaseImplantSpecification({
        variables: {
          caseId: state.caseId,
          caseImplantSpecifications: specificationDataRows,
        },
      });

      const levelNotes = form19Utils.getAttributes(values.levels, 'NOTE');
      await upsertForm19({
        variables: {
          caseId: state.caseId,
          isImageSliceValid: !!values?.isImageSliceValid,
          levelNotes: levelNotes,
          engineeringApprovedBy: engineeringUserId ? engineeringUserId : undefined,
          engineeringApprovedAt: engineeringUserId ? approvedAt : undefined,
          qualityApprovedBy: isValidQAReviewStageForm19Approver
            ? currentUser?.userId ?? undefined
            : undefined,
          qualityApprovedAt: isValidQAReviewStageForm19Approver ? approvedAt : undefined,
          peerReviewerId:
            values?.caseRiskAssessment === CaseRiskAssessmentType.QaReviewOnly
              ? null
              : values?.peerReviewerId
              ? Number(values?.peerReviewerId)
              : undefined,
          isNonConformance: values?.isNonConformance ? values?.isNonConformance : false,
          nonConformanceReason: values?.isNonConformance ? values?.nonConformanceReason : null,
          description: values?.description ? values?.description : null,
          caseRiskAssessment: values?.caseRiskAssessment ? values?.caseRiskAssessment : null,
          data: form19Data,
          regenerateReason: values?.regenerateReason ? values?.regenerateReason : null,
        },
      });

      for (const deleteAssetId of deleteAdditionalImagesAssetIds) {
        await deleteAsset({
          variables: {
            assetId: deleteAssetId,
          },
        });
      }

      for (const asset of assets) {
        setUploadingImage(true);

        const createdAsset = await createAndUploadAsset(
          { ...asset.file, name: asset.fileName, size: asset.size },
          asset.assetType,
          state.caseId,
          plan.planId,
          asset.metadata,
        );

        if (createdAsset.error) {
          console.error(createdAsset?.error);
          enqueueSnackbar(`Upload failed for asset: ${asset.assetType}`, {
            variant: 'error',
          });
        }
      }

      setStatus({ success: true });
      enqueueSnackbar('FORM-019 updated', {
        variant: 'success',
      });

      findForm19();
      onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the FORM-019', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
      setUploadingImage(false);
    }
  };

  const handleApproveByQuality = async () => {
    try {
      await confirm({
        title: 'Save and approve FORM-019?',
        description: 'Are you sure you want to approve FORM-019?',
      });

      const form19Data = JSON.parse(JSON.stringify(state.form19Data.data));

      if (canApproveQAReviewStageForm19) {
        form19Data.approvals.qualityApprovedBy = format.formatName(currentUser);
        form19Data.approvals.qualityApprovedAt = format.formatISODate(approvedAt, 'MM/dd/yyyy');
      }

      await upsertForm19({
        variables: {
          caseId: state.caseId,
          qualityApprovedBy: isValidQAReviewStageForm19Approver
            ? currentUser?.userId ?? undefined
            : undefined,
          qualityApprovedAt: isValidQAReviewStageForm19Approver ? approvedAt : undefined,
          data: form19Data,
        },
      });

      enqueueSnackbar('FORM-019 updated', {
        variant: 'success',
      });

      findForm19();
      onClose(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred updating the FORM-019', {
        variant: 'error',
      });
    }
  };

  const createForm19Data = async (form19ManualData: any) => {
    const caseLevelsData = caseUtils.getValidCaseLevelsWithPartTypes(activeCase.levels);
    const partAttributes = form19Utils.getAttributes(form19ManualData.levels, 'PART_ATTRIBUTE');
    const manualAttributes = form19Utils.getAttributes(form19ManualData.levels, 'MANUAL_ATTRIBUTE');
    const angles = form19Utils.getAttributes(form19ManualData.levels, 'ANGLE');
    const levelNotes = form19Utils.getAttributes(form19ManualData.levels, 'NOTE');
    const levelRevisions = form19Utils.getAttributes(form19ManualData.levels, 'REVISION');
    const alifXLevelScrewLengthTypes = form19Utils.getAttributes(
      form19ManualData.levels,
      'SCREW_LENGTH',
    );

    form19ManualData.partAttributes = partAttributes;
    form19ManualData.manualAttributes = manualAttributes;
    form19ManualData.angles = angles;
    form19ManualData.levelNotes = levelNotes;
    form19ManualData.levelRevisions = levelRevisions;
    form19ManualData.alifXLevelScrewLengthTypes = alifXLevelScrewLengthTypes;

    return form19Utils.generateForm19CaseData(
      activeCase,
      state.form19Settings,
      form19ManualData,
      caseLevelsData,
      encodedImageData,
      encodedAdditionalImages,
      state.form20Products,
      state.planApprovedAt,
      state.productDates,
      isImplantSizeExclusionEnabled,
      planKitBom,
      validCaseExcludedImplants,
    );
  };

  const createCaseImplantSpecificationData = (
    caseId: number,
    form19StateData: Form19StateType,
  ): CaseImplantSpecificationInputType[] => {
    const caseImplantSpecificationData: CaseImplantSpecificationInputType[] = [];
    if (form19StateData?.levels?.length) {
      for (const level of form19StateData?.levels) {
        const minusDataRow: CaseImplantSpecificationInputType = {};
        const planDataRow: CaseImplantSpecificationInputType = {};
        const plusDataRow: CaseImplantSpecificationInputType = {};

        minusDataRow.level = level?.level?.levelType;
        minusDataRow.partType = level?.level?.partType;
        minusDataRow.size = LevelSize.Minus;

        planDataRow.level = level?.level?.levelType;
        planDataRow.partType = level?.level?.partType;
        planDataRow.size = LevelSize.Normal;

        plusDataRow.level = level?.level?.levelType;
        plusDataRow.partType = level?.level?.partType;
        plusDataRow.size = LevelSize.Plus;

        for (const value of level.values) {
          if (
            value.type === 'ANGLE' ||
            value.type === 'PART_ATTRIBUTE' ||
            value.type === 'MANUAL_ATTRIBUTE'
          ) {
            switch (value.attribute) {
              case ImplantCharacteristicType.ApDepthB:
                minusDataRow.apDepth = Number(value.minus01) ? Number(value.minus01) : undefined;
                planDataRow.apDepth = Number(value.plan02) ? Number(value.plan02) : undefined;
                plusDataRow.apDepth = Number(value.plus03) ? Number(value.plus03) : undefined;
                break;
              case ImplantCharacteristicType.MlWidthA:
                minusDataRow.mlWidth = Number(value.minus01) ? Number(value.minus01) : undefined;
                planDataRow.mlWidth = Number(value.plan02) ? Number(value.plan02) : undefined;
                plusDataRow.mlWidth = Number(value.plus03) ? Number(value.plus03) : undefined;
                break;
              case ImplantCharacteristicType.MaxHeightC:
                minusDataRow.maxHeight = Number(value.minus01) ? Number(value.minus01) : undefined;
                planDataRow.maxHeight = Number(value.plan02) ? Number(value.plan02) : undefined;
                plusDataRow.maxHeight = Number(value.plus03) ? Number(value.plus03) : undefined;
                break;
              case ImplantCharacteristicType.BulletAngle:
                minusDataRow.bulletAngle = Number(value.minus01)
                  ? Number(value.minus01)
                  : undefined;
                planDataRow.bulletAngle = Number(value.plan02) ? Number(value.plan02) : undefined;
                plusDataRow.bulletAngle = Number(value.plus03) ? Number(value.plus03) : undefined;
                break;
              case ImplantCharacteristicType.BulletHeight:
                minusDataRow.bulletHeight = Number(value.minus01)
                  ? Number(value.minus01)
                  : undefined;
                planDataRow.bulletHeight = Number(value.plan02) ? Number(value.plan02) : undefined;
                plusDataRow.bulletHeight = Number(value.plus03) ? Number(value.plus03) : undefined;
                break;
              case ImplantCharacteristicType.RightBulletAngle:
                minusDataRow.rightBulletAngle = Number(value.minus01)
                  ? Number(value.minus01)
                  : undefined;
                planDataRow.rightBulletAngle = Number(value.plan02)
                  ? Number(value.plan02)
                  : undefined;
                plusDataRow.rightBulletAngle = Number(value.plus03)
                  ? Number(value.plus03)
                  : undefined;
                break;
              case ImplantCharacteristicType.RightBulletHeight:
                minusDataRow.rightBulletHeight = Number(value.minus01)
                  ? Number(value.minus01)
                  : undefined;
                planDataRow.rightBulletHeight = Number(value.plan02)
                  ? Number(value.plan02)
                  : undefined;
                plusDataRow.rightBulletHeight = Number(value.plus03)
                  ? Number(value.plus03)
                  : undefined;
                break;
              case ImplantCharacteristicType.LeftBulletAngle:
                minusDataRow.leftBulletAngle = Number(value.minus01)
                  ? Number(value.minus01)
                  : undefined;
                planDataRow.leftBulletAngle = Number(value.plan02)
                  ? Number(value.plan02)
                  : undefined;
                plusDataRow.leftBulletAngle = Number(value.plus03)
                  ? Number(value.plus03)
                  : undefined;
                break;
              case ImplantCharacteristicType.LeftBulletHeight:
                minusDataRow.leftBulletHeight = Number(value.minus01)
                  ? Number(value.minus01)
                  : undefined;
                planDataRow.leftBulletHeight = Number(value.plan02)
                  ? Number(value.plan02)
                  : undefined;
                plusDataRow.leftBulletHeight = Number(value.plus03)
                  ? Number(value.plus03)
                  : undefined;
                break;
              case ImplantCharacteristicType.CoronalAngle:
                minusDataRow.coronalAngle = Number(value.minus01)
                  ? Number(value.minus01)
                  : undefined;
                planDataRow.coronalAngle = Number(value.plan02) ? Number(value.plan02) : undefined;
                plusDataRow.coronalAngle = Number(value.plus03) ? Number(value.plus03) : undefined;
                break;
              case ImplantCharacteristicType.LordoticAngle:
                minusDataRow.lordoticAngle = Number(value.minus01)
                  ? Number(value.minus01)
                  : undefined;
                planDataRow.lordoticAngle = Number(value.plan02) ? Number(value.plan02) : undefined;
                plusDataRow.lordoticAngle = Number(value.plus03) ? Number(value.plus03) : undefined;
                break;
              case ImplantCharacteristicType.BulletMinDistance:
                minusDataRow.isBulletMinDistanceValid = Boolean(value.minus01);
                planDataRow.isBulletMinDistanceValid = Boolean(value.plan02);
                plusDataRow.isBulletMinDistanceValid = Boolean(value.plus03);
                break;
              case ImplantCharacteristicType.BulletMinHeight:
                minusDataRow.isBulletMinHeightValid = Boolean(value.minus01);
                planDataRow.isBulletMinHeightValid = Boolean(value.plan02);
                plusDataRow.isBulletMinHeightValid = Boolean(value.plus03);
                break;
              case ImplantCharacteristicType.CosmeticCheck:
                minusDataRow.isCosmeticCheckValid = Boolean(value.minus01);
                planDataRow.isCosmeticCheckValid = Boolean(value.plan02);
                plusDataRow.isCosmeticCheckValid = Boolean(value.plus03);
                break;
              case ImplantCharacteristicType.PartMarkings:
                minusDataRow.isPartMarkingsValid = Boolean(value.minus01);
                planDataRow.isPartMarkingsValid = Boolean(value.plan02);
                plusDataRow.isPartMarkingsValid = Boolean(value.plus03);
                break;
            }
          } else if (value.type === 'REVISION') {
            minusDataRow.revision = Number(value.revision) || 0;
            minusDataRow.description =
              (Number(value.revision) || 0) === 0 ? 'Initial Release' : value.description;
            planDataRow.revision = Number(value.revision) || 0;
            planDataRow.description =
              (Number(value.revision) || 0) === 0 ? 'Initial Release' : value.description;
            plusDataRow.revision = Number(value.revision) || 0;
            plusDataRow.description =
              (Number(value.revision) || 0) === 0 ? 'Initial Release' : value.description;
          }
        }
        caseImplantSpecificationData.push(minusDataRow, planDataRow, plusDataRow);
      }
    }
    return caseImplantSpecificationData;
  };

  const handleUnApproveForm19 = async () => {
    try {
      await confirm({
        title: 'Un-approve FORM-019?',
        description: 'Are you sure you want to un-approve FORM-019?',
      });

      await upsertForm19({
        variables: {
          caseId: state.caseId,
          engineeringApprovedBy: null,
          engineeringApprovedAt: null,
          qualityApprovedBy: null,
          qualityApprovedAt: null,
          data: {},
        },
      });

      enqueueSnackbar('FORM-019 un-approved', {
        variant: 'success',
      });
      findForm19();
      onClose(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred un-approving the FORM-019', {
        variant: 'error',
      });
    }
  };

  const handleOnRemove = (levelType: LevelType, assetId: number | undefined) => {
    if (assetId) {
      const deleteAdditionalImagesAssets = deleteAdditionalImagesAssetIds;
      const deleteAdditionalImageAssetId = deleteAdditionalImagesAssets.find(
        (element) => element === assetId,
      );
      if (!deleteAdditionalImageAssetId) {
        deleteAdditionalImagesAssets.push(assetId);
        setDeleteAdditionalImagesAssetIds(deleteAdditionalImagesAssets);
      }
    }

    const encodedAdditionalImagesArr = encodedAdditionalImages.filter(
      (element) => element.levelType !== levelType,
    );
    setEncodedAdditionalImages(encodedAdditionalImagesArr);
  };

  useEffect(() => {
    if (open) {
      findForm19();
    }
  }, [open, findForm19]);

  const findAsset = useCallback(
    (assetType: AssetType): IAsset | undefined => {
      return state.assets.find((asset: IAsset) => asset.assetType === assetType);
    },
    [state.assets],
  );

  const getEncodedImageData = useCallback(async () => {
    const asset = findAsset(AssetType.Form19ApprovalSignature);
    let encodedImage = '';

    if (asset?.signedDownloadUrl) {
      const response = await file.downloadFile(asset?.signedDownloadUrl);
      if (response.status === 200) {
        encodedImage = await file.encodeBlobToBase64(response.data);
      }
    }

    setEncodedImageData(encodedImage);
  }, [findAsset]);

  const getEncodedAdditionalImagesData = useCallback(async () => {
    const encodedAdditionalImages: EncodedAdditionalImageType[] = [];
    const validCaseLevels = caseUtils.getValidCaseLevelsTypes(activeCase.levels);
    const validForm19AdditionalImageAssetsTypes =
      form19Utils.getValidForm19AdditionalImageAssets(validCaseLevels);

    for (const caseLevel of validCaseLevels) {
      const assetType = validForm19AdditionalImageAssetsTypes.find((assetType) =>
        assetType.includes(caseLevel),
      );
      const asset = findAsset(assetType as AssetType);
      let encodedImage = '';

      if (asset?.signedDownloadUrl) {
        const response = await file.downloadFile(asset?.signedDownloadUrl);
        if (response.status === 200) {
          encodedImage = await file.encodeBlobToBase64(response.data);
          encodedAdditionalImages.push({
            levelType: caseLevel,
            encodedAdditionalImage: encodedImage,
          });
        }
      }
    }

    setEncodedAdditionalImages(encodedAdditionalImages);
  }, [findAsset, activeCase.levels]);

  useEffect(() => {
    if (open && state.isInitialized) {
      dispatch({ type: 'ASSETS_READY' });
      getEncodedImageData();
      getEncodedAdditionalImagesData();
    }

    return function cleanup() {
      setEncodedImageData('');
      setEncodedAdditionalImages([]);
    };
  }, [state.isInitialized, open, getEncodedImageData, getEncodedAdditionalImagesData]);

  return !loading && open ? (
    <Box m={1}>
      <Formik
        initialValues={state}
        validationSchema={form19DialogSchema}
        onSubmit={handleSubmitForm}
        enableReinitialize={true}
      >
        {({ values, validateForm, isSubmitting, submitForm }) => {
          const form19Errors = form19Utils.getForm19Errors(
            activeCase,
            values,
            state.form19Settings,
            !!findAsset(AssetType.Form19ApprovalSignature),
            validCaseExcludedImplants,
          );

          return (
            <CustomDialog
              maxWidth={'lg'}
              open={open}
              title={'Form-19'}
              onClose={() => {
                onClose(true);
              }}
              positiveActionButtons={
                canEditForm19 && activeCase.stage === CaseStageType.Design
                  ? [
                      <ActionButton
                        loading={isSubmitting}
                        onClick={() => {
                          validateForm(values);
                          handlePreviewClick(values);
                        }}
                        variant={'outlined'}
                      >
                        Preview
                      </ActionButton>,
                      state.form19Data?.engineeringApprovedAt ? (
                        <ActionButton
                          disabled={
                            state.form19Data?.qualityApprovedAt || !canApproveDesignStageForm19
                          }
                          loading={isSubmitting}
                          onClick={handleUnApproveForm19}
                          variant={'outlined'}
                        >
                          Un-Approve Form-19
                        </ActionButton>
                      ) : (
                        <ActionButton
                          disabled={
                            (form19Errors.length > 0 && !values.isNonConformance) ||
                            !canApproveDesignStageForm19
                          }
                          loading={isSubmitting}
                          onClick={() => submitForm()}
                          variant={'outlined'}
                        >
                          Approve Form-19
                        </ActionButton>
                      ),
                    ]
                  : canEditForm19 && activeCase.stage === CaseStageType.QaReview
                  ? [
                      <ActionButton
                        disabled={!state.form19Data?.engineeringApprovedAt}
                        loading={isSubmitting}
                        onClick={() => handlePreviewByQuality()}
                        variant={'outlined'}
                      >
                        Preview
                      </ActionButton>,
                      state.form19Data?.qualityApprovedAt ? (
                        <ActionButton
                          disabled={
                            !isPreProductionEditable ||
                            !canApproveQAReviewStageForm19 ||
                            !state.form19Data?.engineeringApprovedAt
                          }
                          loading={isSubmitting}
                          onClick={handleUnApproveForm19}
                          variant={'outlined'}
                        >
                          Un-Approve by Quality
                        </ActionButton>
                      ) : (
                        <ActionButton
                          disabled={
                            (form19Errors.length > 0 && !values.isNonConformance) ||
                            !canApproveQAReviewStageForm19 ||
                            !state.form19Data?.engineeringApprovedAt
                          }
                          loading={isSubmitting}
                          onClick={() => handleApproveByQuality()}
                          variant={'outlined'}
                        >
                          Approve by Quality
                        </ActionButton>
                      ),
                    ]
                  : canEditForm19 && activeCase.stage === CaseStageType.Production
                  ? [
                      <ActionButton
                        disabled={!state.form19Data?.engineeringApprovedAt}
                        loading={isSubmitting}
                        onClick={() => handlePreviewByQuality()}
                        variant={'outlined'}
                      >
                        Preview
                      </ActionButton>,
                      <ActionButton
                        disabled={
                          (form19Errors.length > 0 && !values.isNonConformance) ||
                          !canApproveQAReviewStageForm19 ||
                          !state.form19Data?.engineeringApprovedAt
                        }
                        loading={isSubmitting}
                        onClick={() => submitForm()}
                        variant={'outlined'}
                      >
                        Regenerate Form-19
                      </ActionButton>,
                    ]
                  : []
              }
            >
              {open ? (
                <>
                  {state.form19Data?.engineeringApprovedAt ? (
                    <Box mb={2}>
                      <Alert severity={'success'}>
                        {format.formatName(state.form19Data?.engineeringApprovedByUser)} has given
                        engineering approval for the FORM-019 on{' '}
                        {format.formatDate(state.form19Data?.engineeringApprovedAt)}
                      </Alert>
                    </Box>
                  ) : (
                    <Box mb={2}>
                      <Alert severity={'warning'}>
                        This FORM-019 has not been approved by engineering yet
                      </Alert>
                    </Box>
                  )}
                  {activeCase.stage === CaseStageType.QaReview ? (
                    state.form19Data?.qualityApprovedAt ? (
                      <Box mb={2}>
                        <Alert severity={'success'}>
                          {format.formatName(state.form19Data?.qualityApprovedByUser)} has given
                          quality approval for the FORM-019 on{' '}
                          {format.formatDate(state.form19Data?.qualityApprovedAt)}
                        </Alert>
                      </Box>
                    ) : (
                      <Box mb={2}>
                        <Alert severity={'warning'}>
                          This FORM-019 has not been approved by quality yet
                        </Alert>
                      </Box>
                    )
                  ) : null}
                  <Form19ManualEntryForm
                    activeCase={activeCase}
                    disabled={
                      isSubmitting ||
                      state.form19Data?.engineeringApprovedAt ||
                      activeCase.stage === CaseStageType.QaReview ||
                      uploadingImage
                    }
                    errors={form19Errors}
                    loading={state.areAssetsReady || isSubmitting}
                    peerReviewers={peerReviewers}
                    plan={plan}
                    onRemove={handleOnRemove}
                    warningWhoCanApproveForm19={warningWhoCanApproveForm19}
                    canQualityApproveForm19={canApproveQAReviewStageForm19}
                    validCaseExcludedImplants={validCaseExcludedImplants}
                  />
                </>
              ) : null}
            </CustomDialog>
          );
        }}
      </Formik>
    </Box>
  ) : null;
}
