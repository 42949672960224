import { ILocation } from '@workflow-nx/common';
import React from 'react';
import { Box, Chip, Typography, Popover } from '@mui/material';

export function OrganizationLocationsView(props: {
  organizationId: number;
  locations: ILocation[];
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (props.locations.length) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    if (props.locations.length) {
      setAnchorEl(null);
    }
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? `organizations-locations-popover${props?.organizationId}` : undefined;

  return (
    <>
      <Chip
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
        label={props.locations.length}
      ></Chip>
      <Popover
        style={{ pointerEvents: 'none' }}
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Typography variant={'h4'} sx={{ mb: 1 }}>
            Locations
          </Typography>
          {props.locations.map((location, index) => (
            <Typography key={index}>{`${location?.description}`}</Typography>
          ))}
        </Box>
      </Popover>
    </>
  );
}
