import React from 'react';
import { Box, Divider, Menu, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CaseSetType, format } from '@workflow-nx/common';

const CaseLoadPopup = (props: {
  event: SVGRectElement;
  open: boolean;
  caseSets: CaseSetType[];
  handlePopupClose: () => void;
}) => {
  const navigate = useNavigate();

  const handleClick = (caseId: number) => {
    navigate(`/app/cases/${caseId}`);
    props.handlePopupClose();
  };
  const ITEM_HEIGHT = 48;

  return (
    <Menu
      id="caseLoadPopup"
      anchorEl={props.event}
      open={props.open}
      onClose={props.handlePopupClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.7,
        },
      }}
    >
      <Box textAlign={'center'}>
        <Typography variant={'button'}>
          {format.formatCaseStage(props.caseSets[0]?.stage)}
        </Typography>
      </Box>
      <Divider />
      {props.caseSets.map((set) => (
        <MenuItem onClick={() => handleClick(set.caseId)}>{set.number}</MenuItem>
      ))}
    </Menu>
  );
};

export default CaseLoadPopup;
