import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import CaseStageStats from './CaseStageStats';
import CaseLoadResources from './CaseLoadResources';
import Page from '../../components/Page';
import CaseDaysInStage from './CaseDaysInStage';
import CasesByQuarter from './CasesByQuarter';
import ForecastReport from './ForecastReport';
import EstimateCaseDeliveryForm from '../../components/EstimateCaseDeliveryForm';
import config from '../../extras/config';
import useAuth from '../../hooks/useAuth';
import { Permission, UserRoleType } from '@workflow-nx/common';
import CasesByUpcomingSurgeries from './CasesByUpcomingSurgeries';
import CaseCalendarWeek from './CaseCalendarWeek';
import ExportCasesForm from './ExportCasesForm';

const DashboardView = () => {
  const auth = useAuth();

  const canViewForecastReport = auth?.hasPermission?.([Permission.ForecastReport]);
  const canExportCasesData =
    auth?.hasRole?.([
      UserRoleType.FieldRep,
      UserRoleType.FieldRepManager,
      UserRoleType.CustomerRepManager,
      UserRoleType.CustomerRep,
    ]) || auth?.hasPermission?.([Permission.ReportExportCaseData]);

  return (
    <Page title="Dashboard">
      <Container maxWidth={false}>
        <Box mt={2} />
        <Grid container spacing={3}>
          {canExportCasesData ? (
            <Grid item xs={12}>
              <ExportCasesForm />
            </Grid>
          ) : null}
          {canViewForecastReport ? (
            <Grid item xs={12}>
              <ForecastReport />
            </Grid>
          ) : null}
          {config.featureFlags.dashboardProductionCalendar ? (
            <Grid item xs={12}>
              <EstimateCaseDeliveryForm />
            </Grid>
          ) : null}

          <Grid item lg={12} xs={12}>
            <CaseLoadResources />
          </Grid>

          <Grid item lg={6} md={12} xs={12}>
            <CasesByUpcomingSurgeries />
          </Grid>

          <Grid item lg={6} md={12} xs={12}>
            <CasesByQuarter />
          </Grid>

          <Grid item lg={6} md={12} xs={12}>
            <CaseStageStats />
          </Grid>

          <Grid item lg={6} md={12} xs={12}>
            <CaseCalendarWeek />
          </Grid>

          <Grid item xs={12}>
            <CaseDaysInStage />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardView;
