export const environment = {
  production: false,

  NX_AUTH_CLIENT_ID: process.env.NX_PUBLIC_AUTH_CLIENT_ID,
  NX_AUTH_REDIRECT_URI: process.env.NX_PUBLIC_AUTH_REDIRECT_URI,
  NX_AUTH_RESPONSE_TYPE: process.env.NX_PUBLIC_AUTH_RESPONSE_TYPE,
  NX_AUTH_SCOPE: process.env.NX_PUBLIC_AUTH_SCOPE,

  NX_PUBLIC_BRANCH: process.env.NX_PUBLIC_BRANCH,

  NX_CLIENT_ID: process.env.NX_PUBLIC_CLIENT_ID,
  NX_CLIENT_URL: process.env.NX_PUBLIC_CLIENT_URL,

  NX_ENVIRONMENT: process.env.NX_PUBLIC_ENVIRONMENT,

  NX_OAUTH_URL: process.env.NX_PUBLIC_OAUTH_URL,

  NX_SERVER_GQL: process.env.NX_PUBLIC_SERVER_GQL,

  NX_PUBLIC_VERSION: process.env.NX_PUBLIC_VERSION,

  // other env vars
  NX_DICOM_MAX_FILE_SIZE_BYTES: '3221225472',

  NX_MIXPANEL_TOKEN: 'ba2586bd067b7beec988c5f9590be933',
  NX_ONESIGNAL_APP_ID: '194548bd-852b-4939-ae24-f5b37aa12b66',
  NX_ONESIGNAL_ENABLED: false,

  NX_FF_ALIFX_ORIENTATION: true,
  NX_FF_TLIFC_ORIENTATION: true,
  NX_FF_AUTO_CORRECT: true,
  NX_FF_DASHBOARD_PRODUCTION_CALENDAR: true,
  NX_FF_M4_INSERTER_INVENTORY_MANAGEMENT: true,
  NX_FF_M4L_EXPANDED_INVENTORY_AVAILABLE: true,
  NX_FF_DESIGN_REQUIRE_TEM_013: true,
  NX_FF_TEM_013_AUTOMATION: false,
  NX_FF_MAGIC_LINKS: true,
};
