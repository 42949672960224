import {
  caseUtils,
  IAsset,
  ILevels,
  IPlan,
  LevelType,
  PartType,
  IPlanExcludedImplantSizes,
  planUtils,
} from '@workflow-nx/common';
import React from 'react';
import { DesignAssetsLevelView } from './DesignAssetsLevelView';
import { Box } from '@mui/material';

export function DesignDetailsView(props: {
  plan: IPlan;
  levels: ILevels;
  planAssets: IAsset[];
  caseNumber: string;
  validPlanExcludedImplantSizes: IPlanExcludedImplantSizes[];
}) {
  return (
    <>
      {caseUtils.getValidCaseLevels(props.levels).map((key: string) => {
        const partType = props.levels[key] as PartType;
        const levelType = key as LevelType;
        const isPlusSizeExcluded = planUtils.checkPlusSizeExcluded(
          levelType,
          props.validPlanExcludedImplantSizes,
        );

        return (
          <Box key={key}>
            <DesignAssetsLevelView
              caseNumber={props.caseNumber}
              levelType={levelType}
              partType={partType}
              plan={props.plan}
              isPlusSizeExcluded={isPlusSizeExcluded}
            />
          </Box>
        );
      })}
    </>
  );
}
