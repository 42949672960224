import React, { Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableBody, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, ITableHeader, IUser } from '@workflow-nx/common';
import { TableCell } from '../../../components/TableCell';
import { CommonTable } from '../../../components/CommonTable';
import { ListSurgeonsActionType } from './ListSurgeons.reducer';
import { Globals } from '../../../layouts/DashboardLayout';

type SurgeonListTableProps = {
  users: IUser[];
  orderBy?: any;
  dispatch?: Dispatch<ListSurgeonsActionType>;
  loading?: boolean;
  page?: number;
  rowsPerPage?: number;
  totalSurgeonCount?: number;
  onPagination: (pageSize: number, pageNumber: number) => void;
};

const useStyles = makeStyles(() => ({
  row: {
    '& :hover': {
      cursor: 'pointer',
    },
  },
}));

const SurgeonListTable = ({
  users,
  orderBy,
  dispatch,
  loading,
  page,
  rowsPerPage,
  totalSurgeonCount,
  onPagination,
}: SurgeonListTableProps) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const headers: ITableHeader[] = [
    {
      id: 'lastName',
      sortable: true,
      label: 'Name',
    },
    {
      id: 'organizationId',
      sortable: true,
      label: 'Institution',
    },
    {
      id: 'locationId',
      sortable: false,
      label: 'Location',
    },
    {
      id: 'email',
      sortable: true,
      label: 'Email',
    },
    {
      id: 'linkedOrganizationId',
      sortable: true,
      label: 'Distributor',
    },
  ];

  const showPagination = Boolean(rowsPerPage && page !== undefined);

  return (
    <CommonTable
      orderBy={orderBy}
      headers={headers}
      onHeaderClick={(orderBy) => {
        if (dispatch) {
          dispatch({ type: 'ORDER_BY_CHANGED', data: orderBy });
        }
      }}
      rows={
        <TableBody>
          {users?.map((user: IUser) => {
            return (
              <TableRow
                hover
                key={user.userId}
                onClick={() => navigate(`/app/surgeon/${user.userId}`)}
                className={`${styles.row}`}
              >
                <TableCell>
                  {user ? (
                    <Typography noWrap={true}>{format.formatName(user)}</Typography>
                  ) : (
                    <span>&mdash;</span>
                  )}
                </TableCell>
                <TableCell>{user?.organization?.name}</TableCell>
                <TableCell>{user?.location?.description}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {user.linkedOrganization ? user.linkedOrganization.name : <>&mdash;</>}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      }
      loading={loading}
      paginationConfig={
        showPagination
          ? {
              totalCount: totalSurgeonCount || 0,
              onPaginationChange: (pagination) => {
                onPagination(pagination.rowsPerPage, pagination.page);
              },
              rowsPerPage: rowsPerPage || Globals.DefaultListPageSize,
              page: page || 0,
              rowsPerPageOptions: Globals.DefaultListPageSizeOptions,
            }
          : undefined
      }
    />
  );
};

export default SurgeonListTable;
