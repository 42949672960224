import { Box, Stack, Theme, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  ICloudDesignQueue,
  IPlanImplant,
  LevelSize,
  LevelType,
  PartType,
  format,
} from '@workflow-nx/common';
import { Scene } from 'babylonjs';
import { useEffect, useState } from 'react';
import { ImplantListLevelProps, partTypesWithBulletsList } from './ImplantList';
import { ImplantListProgressView } from './ImplantListProgressView';
import useAuth from '../../../../../hooks/useAuth';
import { FeatureFlag } from '../../../../../utils/featureFlags';

export const getImplantPlanStatusInfo = (theme: Theme) => ({
  noImplant: {
    label: 'No Implant',
    color: theme.palette.error.dark,
  },
  positionedUncut: {
    label: 'Implant Positioned Not Cut',
    color: theme.palette.warning.light,
  },
  positionedCut: {
    label: 'Implant Positioned And Cut',
    color: theme.palette.success.light,
  },
});

export function ImplantListLevelView(props: {
  caseLevel: ImplantListLevelProps;
  cloudDesignQueue: ICloudDesignQueue | undefined;
  disabled: boolean | undefined;
  onClick: () => void;
  onCutCancel: () => Promise<void>;
  scene: Scene | undefined;
  isPlusSizeImplantExclusionAllowed: boolean;
}) {
  const { hasFeatureFlag } = useAuth();
  const isImplantSizeExclusionEnabled = hasFeatureFlag?.(FeatureFlag.implantSizeExclusionEnabled);
  const theme = useTheme();
  const cuttingStatus = props.cloudDesignQueue?.status;
  const [hasCutAsset, setHasCutAsset] = useState(false);
  const [planImplant, setPlanImplant] = useState<IPlanImplant | undefined>();
  const partType = props.caseLevel.partType;

  function checkCutAsset(levelType: LevelType, scene: Scene) {
    const cutAsset = scene.getMeshByName(`${levelType}_APP`);
    const minusCutAsset = scene.getMeshByName(`${levelType}_APP_MINUS`);
    setHasCutAsset(cutAsset !== null && minusCutAsset !== null);
  }

  useEffect(() => {
    if (props.scene) {
      checkCutAsset(props.caseLevel.level, props.scene);
    }
    setPlanImplant(props.caseLevel?.planImplant);
  }, [props.scene, props.caseLevel?.planImplant]);

  function getStatusColor() {
    const planStatusInfo = getImplantPlanStatusInfo(theme);

    if (!props.caseLevel.planImplant) return planStatusInfo.noImplant.color;
    else if (planImplant && !hasCutAsset) return planStatusInfo.positionedUncut.color;
    else if (planImplant && hasCutAsset) return planStatusInfo.positionedCut.color;

    return 'grey';
  }

  const statusSectionVisible =
    cuttingStatus === 'PENDING' || cuttingStatus === 'PROCESSING' || planImplant;

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
      <Box display={'flex'} alignItems={'center'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexGrow={1}>
          <Box display={'flex'}>
            <Typography color={'textPrimary'}>
              <strong>{format.formatLevelType(props.caseLevel.level)}</strong>
            </Typography>
            <Box mx={0.5} />
            <Typography color={'textSecondary'}>
              {format.formatPartType(props.caseLevel.partType)}
            </Typography>
          </Box>
          <Box bgcolor={getStatusColor()} width={12} height={12} borderRadius={5} />
        </Box>
      </Box>
      {statusSectionVisible ? (
        <Box
          mt={1}
          p={1}
          style={{
            border: `1px solid ${grey[200]}`,
            borderRadius: 5,
            backgroundColor: grey[50],
          }}
        >
          {cuttingStatus === 'PENDING' ? (
            <Stack direction={'row'} mb={1} justifyContent={'center'}>
              <Typography>Starting cutting process...</Typography>
            </Stack>
          ) : null}
          {cuttingStatus === 'PROCESSING' ? (
            <Box mb={1}>
              <ImplantListProgressView
                level={props.caseLevel.level}
                onCutCancel={async () => {
                  props.onCutCancel();
                }}
                cutCancelVisible={false}
                cloudDesignQueue={props.cloudDesignQueue}
              />
            </Box>
          ) : null}
          {planImplant ? (
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Box>
                <Typography variant={'body2'}>
                  Size: {planImplant?.ap}x{planImplant.ml}{' '}
                  {planImplant?.screwLength ? (
                    <>&mdash; {format.formatImplantScrewLength(planImplant.screwLength)} screw</>
                  ) : null}
                </Typography>
                {partTypesWithBulletsList.includes(partType) ? (
                  <Typography variant={'body2'}>
                    Bullet (Insertion) &mdash; {planImplant?.bullet?.insertionSide?.angle}
                    &deg; x {planImplant?.bullet?.insertionSide?.height}mm
                  </Typography>
                ) : null}
                {[PartType.LLIF_LEFT, PartType.LLIF_RIGHT].includes(partType) ? (
                  <Typography variant={'body2'}>
                    Bullet (Threaded) &mdash; {planImplant?.bullet?.threadedSide?.angle}
                    &deg; x {planImplant?.bullet?.threadedSide?.height}mm
                  </Typography>
                ) : null}
                {!partTypesWithBulletsList.includes(partType) ? (
                  <Typography variant={'body2'}>No Bullet</Typography>
                ) : null}
                {isImplantSizeExclusionEnabled &&
                props.isPlusSizeImplantExclusionAllowed &&
                planImplant?.excludedImplantSizes.includes(LevelSize.Plus) ? (
                  <Typography variant={'body2'}>Plus&mdash;size excluded</Typography>
                ) : null}
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}
