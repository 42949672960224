import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import {
  ActivityOriginType,
  ILocation,
  IOrganizationMetadata,
  IUser,
  OrganizationType,
  Permission,
  UserRoleType,
  format,
} from '@workflow-nx/common';
import { Button, IconFontButton, TextField, UserAvatar } from '@workflow-nx/ui';
import { Formik, FormikHelpers, FormikValues, useField } from 'formik';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomAppBar } from '../../../components/CustomAppBar';
import Page from '../../../components/Page';
import SplashScreen from '../../../components/SplashScreen';
import { TableCell } from '../../../components/TableCell';
import { TableHeadCell } from '../../../components/TableHeadCell';
import {
  IUpdateOrganizationDialogFormValues,
  updateOrganizationDialogFormValues,
} from '../../../extras/formValues';
import { updateOrganizationDialogSchema } from '../../../extras/schemas';
import {
  DELETE_LOCATION,
  FIND_ACTIVITIES,
  FIND_ORGANIZATION,
  FIND_USERS_BY_ORGANIZATION,
  UPDATE_ORGANIZATION,
} from '../../../gql';
import useAuth from '../../../hooks/useAuth';
import { ActivityListTable } from '../../cases/CaseView/ActivityTab/ActivityListTable';
import { PriceLevelsListTable } from '../ListOrganizationsView/PriceLevelsListTable';
import { CreateLocationDialog } from './CreateLocationDialog';

const SwitchField = (props: { name: string; label: string }) => {
  const [input, , helpers] = useField<boolean>(props.name);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={input.value}
            onChange={(event) => {
              helpers.setValue(event.target.checked);
            }}
          />
        }
        label={
          <Typography variant={'body1'} noWrap={true}>
            {props.label}
          </Typography>
        }
      />
    </FormGroup>
  );
};
const OrganizationView = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION);
  const [deleteLocation] = useMutation(DELETE_LOCATION);
  const { organizationId } = useParams<{ organizationId?: string }>();
  const [orgActivities, setOrgActivities] = useState([]);

  const { data, loading, refetch } = useQuery(FIND_ORGANIZATION, {
    variables: {
      organizationId: Number(organizationId),
      roleFilter: [UserRoleType.CustomerRep, UserRoleType.CustomerRepManager],
    },
    onCompleted: (data) => {
      const organization = data?.organization;
      if (!organization) {
        navigate('/app/institutions');

        return;
      }

      findOrgActivities({
        variables: {
          originId: organization?.organizationId,
          originTypeFilter: [ActivityOriginType.Organization],
          orderBy: { createdAt: 'desc' },
        },
      }).then(({ data }) => {
        const activities = data?.activities?.activities ?? [];
        setOrgActivities(activities);
      });

      setOrganizationType(organization?.organizationType ?? null);
    },
  });

  const [findOrgActivities, { loading: loadingActivityData }] = useLazyQuery(FIND_ACTIVITIES, {
    variables: {
      originId: null,
      originTypeFilter: [ActivityOriginType.Organization],
      orderBy: { createdAt: 'desc' },
    },
  });

  const { data: usersData, loading: usersLoading } = useQuery(FIND_USERS_BY_ORGANIZATION, {
    variables: { organizationId: Number(organizationId) },
  });
  const [selectedLocationId, setSelectedLocationId] = useState<number | undefined>();
  const [showLocationDialog, setShowLocationDialog] = useState(false);
  const [organizationType, setOrganizationType] = useState<OrganizationType | null>(
    data?.organization?.organizationType,
  );

  const isHospital = organizationType === OrganizationType.Hospital;
  const isVendor = organizationType === OrganizationType.Vendor;

  const { hasRole } = useAuth();

  const handleDeleteLocation = async (location: ILocation) => {
    try {
      await confirm({
        title: `Delete ${location.description}?`,
        description: `This will delete the location`,
      });

      await deleteLocation({
        variables: {
          locationId: location.locationId,
        },
      });
      refetch({});
    } catch (e) {
      console.info('Cancelled delete');
    }
  };

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<IUpdateOrganizationDialogFormValues>,
  ) => {
    try {
      const metadata: IOrganizationMetadata = {
        ...data.organization.metadata,
        heatTreatAvailable: values.heatTreatAvailable,
      };

      await updateOrganization({
        variables: {
          organizationId: data?.organization?.organizationId,
          name: values.name,
          organizationType: values.organizationType,
          externalSyncDataSourceId: values.externalSyncDataSourceId,
          metadata,
        },
      });

      setStatus({ success: true });
      enqueueSnackbar('Institution Updated', {
        variant: 'success',
      });

      refetch();
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the institution', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return loading || !data?.organization || usersLoading ? (
    <SplashScreen />
  ) : (
    <Formik
      initialValues={updateOrganizationDialogFormValues(data.organization)}
      validationSchema={updateOrganizationDialogSchema}
      enableReinitialize={true}
      onSubmit={handleSubmitForm}
    >
      {({ submitForm, isSubmitting }) => {
        const isSiteAdministrator = hasRole?.([UserRoleType.SiteAdministrator]);
        return (
          <Page title={'Institution'}>
            <Container>
              <CustomAppBar
                title={data.organization.name}
                actions={[<Button variant={'contained'} onClick={submitForm} label={'Update'} />]}
              />
              <Box mt={3}>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box mt={3}>
                          <Stack spacing={2}>
                            <TextField name={'name'} label={'Name'} disabled={isSubmitting} />

                            {isVendor ? (
                              <SwitchField name="heatTreatAvailable" label="Heat Treat Available" />
                            ) : null}

                            {isHospital && isSiteAdministrator ? (
                              <TextField
                                name={'externalSyncDataSourceId'}
                                label={'External Sync Data Source Id'}
                                disabled={isSubmitting}
                              />
                            ) : null}
                          </Stack>
                        </Box>
                        <Box
                          mt={3}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Typography variant={'h4'}>Locations</Typography>
                          {auth?.hasPermission?.([Permission.ManageOrganization]) ? (
                            <Button
                              label={'Create Location'}
                              variant={'outlined'}
                              onClick={() => setShowLocationDialog(true)}
                            />
                          ) : null}
                        </Box>
                        <Box mb={2}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableHeadCell>Description</TableHeadCell>
                                <TableHeadCell>Region</TableHeadCell>
                                <TableHeadCell>Territory</TableHeadCell>
                                <TableHeadCell>Customer Reps</TableHeadCell>
                                {isSiteAdministrator ? (
                                  <TableHeadCell>External Sync Data Source Id</TableHeadCell>
                                ) : null}
                                <TableHeadCell></TableHeadCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.organization?.locations?.map(
                                (location: ILocation, index: number) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>{location.description}</TableCell>
                                      <TableCell>
                                        {location?.region?.name ?? <>&mdash;</>}
                                      </TableCell>
                                      <TableCell>
                                        {location?.territory?.name ?? <>&mdash;</>}
                                      </TableCell>
                                      <TableCell>
                                        <Stack direction="row">
                                          {location?.locationUser?.length ? (
                                            location.locationUser.map((ele, index: number) => {
                                              return (
                                                <span key={index}>
                                                  <UserAvatar user={ele.user} />
                                                </span>
                                              );
                                            })
                                          ) : (
                                            <>&mdash;</>
                                          )}
                                        </Stack>
                                      </TableCell>
                                      {isSiteAdministrator ? (
                                        <TableCell>
                                          {location?.externalSyncData?.sourceId ?? <>&mdash;</>}
                                        </TableCell>
                                      ) : null}
                                      <TableCell>
                                        <Box display={'flex'} justifyContent={'flex-end'}>
                                          <IconFontButton
                                            onClick={() => {
                                              setSelectedLocationId(location.locationId);
                                              setShowLocationDialog(true);
                                            }}
                                            size={'small'}
                                            icon={faPencil}
                                          />
                                          <Box mx={0.5} />
                                          <IconFontButton
                                            onClick={() => handleDeleteLocation(location)}
                                            size={'small'}
                                            icon={faTrash}
                                          />
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  );
                                },
                              )}
                            </TableBody>
                          </Table>
                        </Box>

                        <Box
                          mt={3}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          {isHospital ? (
                            <Typography variant={'h4'}>Surgeons</Typography>
                          ) : (
                            <Typography variant={'h4'}>Users</Typography>
                          )}
                        </Box>
                        <Box mb={2}>
                          {usersData?.users?.users?.length > 0 ? (
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {isHospital ? (
                                    <>
                                      <TableHeadCell>Surgeon</TableHeadCell>
                                      <TableHeadCell>Location</TableHeadCell>
                                    </>
                                  ) : (
                                    <TableHeadCell>Name</TableHeadCell>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {usersData?.users?.users.map((user: IUser, index: number) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      hover
                                      onClick={() =>
                                        isHospital
                                          ? navigate(`/app/surgeon/${user.userId}`)
                                          : navigate(`/app/user/${user.userId}`)
                                      }
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <TableCell sx={{ color: blue['600'] }}>
                                        {format.formatName({
                                          firstName: user.firstName,
                                          lastName: user.lastName,
                                          role: user.role,
                                        })}
                                      </TableCell>
                                      {isHospital ? (
                                        <TableCell>
                                          {user.location?.description ?? <>&mdash;</>}
                                        </TableCell>
                                      ) : null}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          ) : (
                            <Alert severity={'info'} sx={{ mt: 2 }}>
                              There are no {isHospital ? 'surgeons' : 'users'} associated with{' '}
                              {data.organization.name}.
                            </Alert>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Grid container spacing={2}>
                  {auth?.hasPermission?.([Permission.ListPriceLevels]) && isHospital ? (
                    <Grid item xs={12}>
                      <Card>
                        <PerfectScrollbar>
                          <Box p={3}>
                            <PriceLevelsListTable organizationId={Number(organizationId)} />
                          </Box>
                        </PerfectScrollbar>
                      </Card>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
              <Box mt={2} />
              <Box>
                <Card>
                  <CardContent>
                    <Box m={1}>
                      <Typography variant={'h4'}>Activity</Typography>
                    </Box>
                    <ActivityListTable
                      orderBy={{ createdAt: 'desc' }}
                      loading={loadingActivityData}
                      activities={orgActivities}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Container>
            {showLocationDialog ? (
              <CreateLocationDialog
                organizationId={Number(organizationId)}
                organizationType={data?.organization?.organizationType}
                locationId={selectedLocationId}
                open={showLocationDialog}
                onClose={(shouldUpdate) => {
                  setShowLocationDialog(false);
                  setSelectedLocationId(undefined);

                  if (shouldUpdate) {
                    refetch();
                  }
                }}
              />
            ) : null}
          </Page>
        );
      }}
    </Formik>
  );
};

export default OrganizationView;
