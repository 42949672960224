import { Badge, Box, Button, IconButton, Stack } from '@mui/material';
import {
  CaseStageType,
  caseUtils,
  format,
  ICase,
  Permission,
  UserRoleType,
} from '@workflow-nx/common';
import { IconFontButton, LargeValueView } from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import useAuth from '../../../../hooks/useAuth';
import config from '../../../../extras/config';
import { faUserRobot } from '@fortawesome/pro-light-svg-icons';
import { FeatureFlag } from '../../../../utils/featureFlags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export function ViewCaseDetailsHeaderView(props: {
  activeCase: ICase;
  open: boolean;
  onEdit?: () => void;
  onClick: () => void;
}) {
  const { hasFeatureFlag, hasPermission, externalApps } = useAuth();

  const activeCase = props.activeCase;
  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <LargeValueView
        value={format.formatISODate(activeCase?.surgeryDate as unknown as string) || 'TBD'}
        label={'Surgery Date'}
      />
      <LargeValueView
        value={
          activeCase?.surgeonUser ? (
            format.formatName(activeCase?.surgeonUser)
          ) : (
            <span>&mdash;</span>
          )
        }
        label={'Surgeon'}
      />
      <LargeValueView
        value={
          activeCase?.assignedUser ? (
            format.formatName(activeCase?.assignedUser)
          ) : (
            <span>&mdash;</span>
          )
        }
        label={'Assigned To'}
      />
      <LargeValueView
        value={
          activeCase?.fieldRepUser ? (
            format.formatName(activeCase?.fieldRepUser)
          ) : (
            <span>&mdash;</span>
          )
        }
        label={`${format.formatUserRole(UserRoleType.FieldRep)}`}
      />
      <LargeValueView
        value={caseUtils.getValidCaseLevels(activeCase?.levels).length}
        label={'Levels'}
      />
      <LargeValueView
        value={
          activeCase?.studyDate?.expiryDate ? (
            format.formatISODate(activeCase?.studyDate?.expiryDate)
          ) : (
            <span>&mdash;</span>
          )
        }
        label={'Image Expiry'}
      />
      <Box flexGrow={1} />

      <Box mr={2}>
        <Stack direction={'row'} spacing={2}>
          <IconFontButton
            color={'primary'}
            disabled={[CaseStageType.Open, CaseStageType.Segmentation].includes(
              props.activeCase.stage,
            )}
            onClick={() => {
              window.open(
                `${config.cyborg.endpoint}/app/integrated/${props.activeCase.number}`,
                'cyborg',
              );
            }}
            icon={faUserRobot}
          />

          {hasFeatureFlag?.(FeatureFlag.cyborgV2Enabled) && externalApps?.cyborgUrl ? (
            <IconButton
              size={'large'}
              onClick={() => {
                window.open(
                  `${externalApps?.cyborgUrl}/cases/${props.activeCase.caseId}`,
                  'cyborg',
                );
              }}
            >
              <Badge badgeContent={'V2'} color="secondary">
                <FontAwesomeIcon icon={faUserRobot} />
              </Badge>
            </IconButton>
          ) : null}
        </Stack>
      </Box>

      {hasPermission?.([Permission.ManageCase, Permission.EditCase]) && props.onEdit ? (
        <Box mr={2}>
          <Button onClick={props.onEdit} variant={'contained'}>
            Edit
          </Button>
        </Box>
      ) : null}

      <Box>
        <IconFontButton
          icon={props.open ? faChevronCircleUp : faChevronCircleDown}
          onClick={props.onClick}
        />
      </Box>
    </Box>
  );
}
