import React from 'react';
import { Grid, Typography } from '@mui/material';
import { DatePickerField } from '../../../components/DatePickerField';
import { PriceLevelType } from '@workflow-nx/common';
import { NumberTextField } from '../../../components/NumberTextField';
import { SelectField } from '@workflow-nx/ui';

export function CreatePriceLevelForm(props: { isSubmitting: boolean }) {
  const priceLevelTypes = [
    { key: PriceLevelType.PerImplant, value: 'Per-implant' },
    { key: PriceLevelType.BasePlusLevel, value: 'Base plus level' },
    { key: PriceLevelType.Fixed, value: 'Fixed' },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography variant={'h3'}>Price Level</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectField
          name={'priceLevelType'}
          label={'Price Level Type'}
          hideNone={true}
          menuItems={priceLevelTypes}
          disabled={props.isSubmitting}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DatePickerField
          name={'startDate'}
          label={'Start Date'}
          required={true}
          loading={props.isSubmitting}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DatePickerField name={'endDate'} label={'End Date'} loading={props.isSubmitting} />
      </Grid>

      <Grid item xs>
        <NumberTextField label={'One Level'} name={'oneLevel'} disabled={props.isSubmitting} />
      </Grid>
      <Grid item xs>
        <NumberTextField label={'Two Level'} name={'twoLevel'} disabled={props.isSubmitting} />
      </Grid>
      <Grid item xs>
        <NumberTextField label={'Three Level'} name={'threeLevel'} disabled={props.isSubmitting} />
      </Grid>
      <Grid item xs>
        <NumberTextField label={'Four Level'} name={'fourLevel'} disabled={props.isSubmitting} />
      </Grid>
      <Grid item xs>
        <NumberTextField label={'Five Level'} name={'fiveLevel'} disabled={props.isSubmitting} />
      </Grid>
    </Grid>
  );
}
