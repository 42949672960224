import { gql } from '@apollo/client/core';
import * as fragment from '../fragments/index';

export const FIND_ACTIVITIES = gql`
  query FindActivities(
    $caseId: Int
    $originId: Int
    $originTypeFilter: [ActivityOriginType]
    $activityTypeFilter: [ActivityType]
  ) {
    activities(
      caseId: $caseId
      originId: $originId
      originTypeFilter: $originTypeFilter
      activityTypeFilter: $activityTypeFilter
    ) {
      activities {
        caseId
        activityId
        activityType
        originId
        originType
        delta
        createdByUser {
          userId
          firstName
          lastName
        }
        createdAt
      }
      count
    }
  }
`;

export const FIND_ACTIVITY = gql`
  query FindActivity($activityId: Int!) {
    activity(activityId: $activityId) {
      caseId
      activityId
      activityType
      originId
      originType
      delta
      createdByUser {
        userId
        firstName
        lastName
      }
      createdAt
    }
  }
`;

export const FIND_ASSETS = gql`
  ${fragment.ASSET_FIELDS_FRAGMENT}

  query FindAssets(
    $caseId: Int
    $planId: Int
    $assetTypeFilter: [AssetType]
    $showDeleted: Boolean
    $ignorePlanFilter: Boolean
  ) {
    assets(
      caseId: $caseId
      planId: $planId
      assetTypeFilter: $assetTypeFilter
      showDeleted: $showDeleted
      ignorePlanFilter: $ignorePlanFilter
    ) {
      ...assetFields
    }
  }
`;

export const FIND_ASSETS_WITH_URL = gql`
  ${fragment.ASSET_FIELDS_FRAGMENT}

  query FindAssets(
    $caseId: Int!
    $planId: Int
    $assetTypeFilter: [AssetType]
    $showDeleted: Boolean
    $ignorePlanFilter: Boolean
  ) {
    assets(
      caseId: $caseId
      planId: $planId
      assetTypeFilter: $assetTypeFilter
      showDeleted: $showDeleted
      ignorePlanFilter: $ignorePlanFilter
    ) {
      ...assetFields
      signedDownloadUrl
    }
  }
`;

export const FIND_ASSET = gql`
  ${fragment.ASSET_FIELDS_FRAGMENT}

  query FindAsset($assetId: Int!) {
    asset(assetId: $assetId) {
      ...assetFields
    }
  }
`;

export const FIND_DESIGN_ASSETS = gql`
  query FindDesignAssets(
    $caseId: Int!
    $planId: Int!
    $levelSize: LevelSize!
    $level: LevelType!
    $type: CloudDesignQueueType
    $assetTypeFilter: [AssetType]
  ) {
    plan(planId: $planId) {
      assetPositions
      implants {
        planImplantId
        planId
        level
        partType
        bullet
        position
        rotation
        referencePoints
        ap
        ml
        threadHeight
        screwLength
        excludedImplantSizes
        obliqueThreadAngle
        cranialCaudalThreadAngle
      }
    }
    assets(caseId: $caseId, planId: $planId, assetTypeFilter: $assetTypeFilter) {
      assetId
      assetType
      fileName
    }
    cloudDesignQueueByLevel(
      levelSize: $levelSize
      level: $level
      planId: $planId
      caseId: $caseId
      type: $type
    ) {
      cloudDesignQueueId
      caseId
      planId
      assetId
      level
      levelSize
      name
      status
      minutesProcessing
      startedAt
      finishedAt
      log
    }
  }
`;

export const FIND_CLOUD_DESIGN_QUEUE_ITEMS = gql`
  query FindCloudDesignQueueItems(
    $caseId: Int!
    $planId: Int!
    $level: LevelType
    $type: CloudDesignQueueType!
  ) {
    cloudDesignQueues(planId: $planId, caseId: $caseId, level: $level, type: $type) {
      cloudDesignQueueId
      caseId
      planId
      assetId
      level
      name
      status
      minutesProcessing
      startedAt
      finishedAt
      log
    }
  }
`;

export const FIND_PLAN_ASSETS = gql`
  query FindAssets(
    $caseId: Int!
    $planId: Int
    $assetTypeFilter: [AssetType]
    $ignorePlanFilter: Boolean
  ) {
    assets(
      caseId: $caseId
      planId: $planId
      assetTypeFilter: $assetTypeFilter
      ignorePlanFilter: $ignorePlanFilter
    ) {
      assetId
      assetType
      signedDownloadUrl
      deletedAt
    }
  }
`;

export const FIND_ASSET_HISTORY = gql`
  ${fragment.ASSET_FIELDS_FRAGMENT}

  query FindAssetHistory($caseId: Int!, $planId: Int, $assetType: AssetType) {
    assetHistory(caseId: $caseId, planId: $planId, assetType: $assetType) {
      ...assetFields
    }
  }
`;

export const FIND_REPORT_CASE_STAGES = gql`
  query {
    reportCaseStages {
      stage
      _count {
        stage
      }
    }
  }
`;

export const FIND_REPORT_CASE_STAGE_STATUS = gql`
  query {
    reportCaseStageStatus {
      activities {
        activityType
        createdAt
      }
      caseId
      assignedUser {
        userId
        firstName
        lastName
      }
      number
      stage
      caseType
      surgeryDate
      isSurgeryDateTentative
      receivedAt
    }
  }
`;

export const FIND_REPORT_CASE_UPCOMING_STUDY_DATE = gql`
  query {
    reportCaseUpcomingStudyDate {
      caseId
      number
      surgeryDate
      stage
    }
  }
`;

export const FIND_REPORT_CASE_LOAD_EXTENDED = gql`
  query {
    reportCaseLoadExtended {
      userId
      firstName
      lastName
      email
      userRole
      cases {
        caseId
        number
        stage
      }
    }
  }
`;

export const FIND_REPORT_CASES_BY_QUARTER = gql`
  query {
    reportCasesByQuarter {
      quarter
      count
      cancelled
      missed
      complete
      inProgress
      draft
    }
  }
`;

export const FIND_REPORT_CASES_EXPORT = gql`
  query {
    reportCasesExport {
      number
      caseType
      caseId
      surgeryDate
      receivedAt
      hospitalDeliveryDate
      isSurgeryDateTentative
      organization {
        name
      }
      regionTerritory {
        region
        territory
      }
      surgeonUser {
        firstName
        lastName
      }
      assignedUser {
        firstName
        lastName
        role
      }
      fieldRepUser {
        firstName
        lastName
        role
      }
      stage
      patient {
        birthDate
        gender
      }
      levelsPlanned
      levelsCompleted
      levelsBilled
      priceLevel {
        priceLevelType
        oneLevel
        twoLevel
        threeLevel
        fourLevel
        fiveLevel
      }
      levels {
        C2_C3
        C3_C4
        C4_C5
        C5_C6
        C6_C7
        C7_C8
        C7_T1
        C8_T1
        C6_T1
        L1_L2
        L2_L3
        L3_L4
        L4_L5
        L5_S1
        L5_L6
        L6_S1
        L4_S1
      }
      planRevisions
      planFirstCompletedAt
      planCompletedAt
      planApprovedAt
      dicomCtAsset {
        createdAt
        assetType
        metadata
      }
      dicomXrayAsset {
        createdAt
        assetType
        metadata
      }
      oldestDicomCtAsset {
        createdAt
        assetType
        metadata
      }
      oldestDicomXrayAsset {
        createdAt
        assetType
        metadata
      }
      lastEvent {
        eventType
        note
        occurredAt
      }
      caseCancellation {
        cancelledAt
        cancellationType
        reason
        note
      }
      pinnedComment {
        createdAt
        comment
      }
      nearestExpiryAsset {
        studyDate
        expiryDate
        assetType
        assetId
      }
    }
  }
`;

export const FIND_REPORT_CASES_EXPORT_DWH = gql`
  query ($dailySnapshotDate: Date!) {
    reportCasesExportDwh(dailySnapshotDate: $dailySnapshotDate) {
      dailySnapshotDate
      number
      caseType
      caseId
      surgeryDate
      receivedAt
      ageSinceReceived
      hospitalDeliveryDate
      isSurgeryDateTentative
      organization
      locationId
      location
      region
      territory
      surgeonId
      surgeonUser
      assignedUser
      fieldRepUser
      stage
      patientGender
      patientBirthDate
      levelsPlanned
      levelsCompleted
      levelsBilled
      projectedRevenue
      actualRevenue
      priceLevelType
      priceOneLevel
      priceTwoLevel
      priceThreeLevel
      priceFourLevel
      priceFiveLevel
      L1_L2
      L2_L3
      L3_L4
      L4_L5
      L5_S1
      L5_L6
      L6_S1
      L4_S1
      planRevisions
      planFirstCompletedAt
      planCompletedAt
      planApprovedAt
      latestDicomCtAsset
      latestDicomXrayAsset
      oldestDicomCtAsset
      oldestDicomXrayAsset
      allImagesReceived
      nearestExpAssetType
      nearestExpStudyDate
      nearestExpExpiryDate
      lastEventType
      eventOccurredAt
      eventNote
      cancelledAt
      cancellationType
      cancelReason
      cancelNote
      imagingToFirstPlan
      firstPlanToApproval
      caseDelivered
      approvalToDelivery
      imagingToFirstPlanWorkingDays
      sixWeeksPostOpImaging
      sixMonthsPostOpImaging
    }
  }
`;

export const FIND_CASES = gql`
  ${fragment.CASES_FIELDS_FRAGMENT}
  query FindCases(
    $stageFilter: [CaseStage]
    $assignedToFilter: [Int]
    $organizationFilter: [Int]
    $surgeonFilter: [Int]
    $fieldRepFilter: [Int]
    $caseTypeFilter: [CaseType]
    $caseSpineTypeFilter: [CaseSpineType]
    $caseCancellationTypeFilter: [CaseCancellationType]
    $tagsFilter: [Int]
    $partTypeFilter: [LevelPart]
    $planVersion: Int
    $showTentativeSurgeryDates: Boolean
    $showOnlyOnHoldCases: Boolean
    $orderBy: CaseOrderByInput
    $search: String
    $startDate: Date
    $endDate: Date
    $take: Int
    $skip: Int
  ) {
    cases(
      stageFilter: $stageFilter
      assignedToFilter: $assignedToFilter
      organizationFilter: $organizationFilter
      surgeonFilter: $surgeonFilter
      fieldRepFilter: $fieldRepFilter
      caseTypeFilter: $caseTypeFilter
      caseSpineTypeFilter: $caseSpineTypeFilter
      caseCancellationTypeFilter: $caseCancellationTypeFilter
      partTypeFilter: $partTypeFilter
      planVersion: $planVersion
      tagsFilter: $tagsFilter
      showTentativeSurgeryDates: $showTentativeSurgeryDates
      showOnlyOnHoldCases: $showOnlyOnHoldCases
      orderBy: $orderBy
      search: $search
      startDate: $startDate
      endDate: $endDate
      take: $take
      skip: $skip
    ) {
      cases {
        ...casesFields
        caseHold {
          caseHoldId
          holdType
          reason
        }
      }
      count
    }
  }
`;

export const FIND_CALENDAR_CASES = gql`
  query FindCases(
    $stageFilter: [CaseStage]
    $assignedToFilter: [Int]
    $organizationFilter: [Int]
    $surgeonFilter: [Int]
    $fieldRepFilter: [Int]
    $caseTypeFilter: [CaseType]
    $caseCancellationTypeFilter: [CaseCancellationType]
    $tagsFilter: [Int]
    $partTypeFilter: [LevelPart]
    $showTentativeSurgeryDates: Boolean
    $showOnlyOnHoldCases: Boolean
    $orderBy: CaseOrderByInput
    $search: String
    $startDate: Date
    $endDate: Date
    $take: Int
    $skip: Int
  ) {
    cases(
      stageFilter: $stageFilter
      assignedToFilter: $assignedToFilter
      organizationFilter: $organizationFilter
      surgeonFilter: $surgeonFilter
      fieldRepFilter: $fieldRepFilter
      caseTypeFilter: $caseTypeFilter
      caseCancellationTypeFilter: $caseCancellationTypeFilter
      partTypeFilter: $partTypeFilter
      tagsFilter: $tagsFilter
      showTentativeSurgeryDates: $showTentativeSurgeryDates
      showOnlyOnHoldCases: $showOnlyOnHoldCases
      orderBy: $orderBy
      search: $search
      startDate: $startDate
      endDate: $endDate
      take: $take
      skip: $skip
    ) {
      cases {
        caseId
        events {
          eventId
          eventType
          description
          note
          occurredAt
        }
        spineType
        spineProfile
        number
        shortNumber
        caseType
        stage
        surgeryDate
        isSurgeryDateTentative
        levels {
          C2_C3
          C3_C4
          C4_C5
          C5_C6
          C6_C7
          C7_C8
          C7_T1
          C8_T1
          C6_T1
          L1_L2
          L2_L3
          L3_L4
          L4_L5
          L5_S1
          L5_L6
          L6_S1
          L4_S1
        }
        location {
          locationId
          description
          organization {
            organizationId
            name
          }
        }
        studyDate {
          assetType
          studyDate
          expiryDate
          daysOld
          monthsUntilExpiry
          isExpiryDateSetManually
          manualExpiryReason
        }
        surgeonUser {
          userId
          firstName
          lastName
          organization {
            organizationId
            name
          }
          firstCaseId
        }
        fieldRepUser {
          userId
          role
          firstName
          lastName
        }
        assignedUser {
          userId
          firstName
          lastName
        }
        caseCancellation {
          cancelledAt
          cancellationType
          reason
          note
        }
        deliveredAt
      }
      count
    }
  }
`;

export const FIND_CASE_EVENTS = gql`
  query FindCaseEvents(
    $assignedToFilter: [Int]
    $organizationFilter: [Int]
    $surgeonFilter: [Int]
    $fieldRepFilter: [Int]
    $shippingStatusFilter: [CaseShippingStatusType]
    $eventsFilter: [EventType]
    $orderBy: CaseOrderByInput
    $search: String
    $take: Int
    $skip: Int
  ) {
    caseEvents(
      assignedToFilter: $assignedToFilter
      organizationFilter: $organizationFilter
      surgeonFilter: $surgeonFilter
      fieldRepFilter: $fieldRepFilter
      shippingStatusFilter: $shippingStatusFilter
      eventsFilter: $eventsFilter
      take: $take
      skip: $skip
      orderBy: $orderBy
      search: $search
    ) {
      cases {
        caseId
        number
        stage
        eventType
        status
        surgeryDate
        occurredAt
        additivePrintingVendorId
        additivePrintingVendorMetadata
        finalShipmentVendorId
      }
      count
    }
  }
`;

export const FIND_POST_OP_EXPORT_CASES = gql`
  query FindPostOpExportCases {
    reportPostOpCasesExport {
      number
      caseType
      spineProfile
      caseId
      surgeryDate
      receivedAt
      levels {
        C2_C3
        C3_C4
        C4_C5
        C5_C6
        C6_C7
        C7_C8
        C7_T1
        C8_T1
        C6_T1
        L1_L2
        L2_L3
        L3_L4
        L4_L5
        L5_S1
        L5_L6
        L6_S1
        L4_S1
      }
      stage
      deletedAt
      isSurgeryDateTentative
      isXrayNotRequired
      updatedAt
      uiv
      liv
      measurementsVersion
      deliveredAt
      proposedAt
      fieldRepUser {
        firstName
        lastName
        role
      }
      surgeonUser {
        firstName
        lastName
        role
      }
      organization {
        name
      }
      assignedUser {
        firstName
        lastName
        role
      }
      patient {
        birthDate
        gender
      }
      patientRecord {
        pelvicIncidence
        lumbarCoronalCobb
        lumbarLordosis
      }
      dicomCtAsset {
        createdAt
        assetType
        metadata
      }
      dicomXrayAsset {
        createdAt
        assetType
        metadata
      }
      caseCancellation {
        cancelledAt
        cancellationType
        reason
        note
      }
      tags {
        tagCategoryId
        label
      }
      caseResults {
        size
        part
        result
        exceptionReason
        level
      }
      acceptedPlan {
        assetPositions
        planId
        updatedAt
      }
      postOpSixWeeksImaging {
        createdAt
        assetType
        metadata
      }
      postOpAnalysisSixWeeks {
        lumbarCoronalAngulation
        lumbarLordosis
        segmentalLumbarLordosis
        segmentalAnteriorHeight
        segmentalPosteriorHeight
        segmentalCoronalAngle
        angleToS1
      }
    }
  }
`;

export const FIND_REPORT_REVENUE_FORECAST = gql`
  query FindReportRevenueForecast($year: Int!) {
    reportRevenueForecast(year: $year) {
      backlogProcedures
      backlogLevels
      backlogRevenue
      proceduresByMonth {
        month
        actualProcedures
        actualLevels
        scheduledProcedures
        scheduledLevels
      }
      revenueByMonth {
        month
        actualRevenue
        scheduledRevenue
      }
    }
  }
`;

export const FIND_USERS = gql`
  ${fragment.USER_FIELDS_FRAGMENT}

  query FindUsers(
    $statusFilter: [UserStatus]
    $roleFilter: [UserRole]
    $regionId: Int
    $territoryId: Int
    $associatedUserIds: [Int]
    $orderBy: UserOrderByInput
    $search: String
    $take: Int
    $skip: Int
  ) {
    users(
      roleFilter: $roleFilter
      statusFilter: $statusFilter
      regionId: $regionId
      territoryId: $territoryId
      associatedUserIds: $associatedUserIds
      orderBy: $orderBy
      search: $search
      take: $take
      skip: $skip
    ) {
      users {
        ...userFields
        locationUser {
          location {
            description
            organization {
              name
            }
          }
        }
      }
      count
    }
  }
`;

export const FIND_SURGEONS = gql`
  ${fragment.USER_FIELDS_FRAGMENT}
  query FindSurgeons(
    $organizationFilter: [Int]
    $linkedOrganizationFilter: [Int]
    $territoryId: Int
    $orderBy: SurgeonOrderByInput
    $search: String
    $take: Int
    $skip: Int
  ) {
    surgeons(
      organizationFilter: $organizationFilter
      linkedOrganizationFilter: $linkedOrganizationFilter
      territoryId: $territoryId
      orderBy: $orderBy
      search: $search
      take: $take
      skip: $skip
    ) {
      surgeons {
        ...userFields
        locationUser {
          location {
            description
            organization {
              name
            }
          }
        }
      }
      count
    }
  }
`;

export const FIND_USERS_WITH_TERRITORIES = gql`
  query FindUsers($roleFilter: [UserRole]) {
    users(roleFilter: $roleFilter) {
      users {
        userId
        firstName
        lastName
        role
        userRegionTerritories {
          userRegionTerritoryId
          userId
          territoryId
          territory {
            territoryId
            name
          }
        }
      }
    }
  }
`;

export const FIND_USERS_BY_ROLE = gql`
  query FindUsers($roleFilter: [UserRole], $statusFilter: [UserStatus]) {
    users(roleFilter: $roleFilter, statusFilter: $statusFilter) {
      users {
        userId
        firstName
        lastName
        role
        status
        associatedUserIds
        organization {
          name
        }
        reportsToUser {
          userId
          firstName
          lastName
          role
        }
      }
    }
  }
`;

export const FIND_USERS_BY_ORGANIZATION = gql`
  query FindUsers($organizationId: Int!) {
    users(organizationId: $organizationId) {
      users {
        userId
        firstName
        lastName
        role
        reportsToUser {
          userId
          firstName
          lastName
          role
        }
        location {
          description
        }
      }
    }
  }
`;

export const FIND_USERS_BY_REGION_TERRITORY = gql`
  query FindUsers($regionId: Int, $territoryId: Int, $roleFilter: [UserRole]) {
    usersByRegionTerritory(
      regionId: $regionId
      territoryId: $territoryId
      roleFilter: $roleFilter
    ) {
      userId
      firstName
      lastName
      role
      userRegionTerritories {
        region {
          regionId
          name
        }
        territory {
          territoryId
          name
        }
        userId
      }
    }
  }
`;

export const FIND_USER = gql`
  ${fragment.USER_FIELDS_FRAGMENT}

  query FindUser($userId: Int!) {
    user(userId: $userId) {
      ...userFields
    }
  }
`;

export const FIND_ORGANIZATIONS = gql`
  query FindOrganizations(
    $organizationTypeFilter: [OrganizationType]
    $orderBy: OrganizationOrderByInput
    $search: String
    $roleFilter: [UserRole]
  ) {
    organizations(
      organizationTypeFilter: $organizationTypeFilter
      orderBy: $orderBy
      search: $search
      roleFilter: $roleFilter
    ) {
      organizations {
        organizationId
        organizationType
        name
        locations {
          description
          locationUser {
            user {
              userId
              firstName
              lastName
              role
            }
          }
        }
      }
      count
    }
  }
`;

export const FIND_ORGANIZATION = gql`
  query FindOrganization($organizationId: Int!, $roleFilter: [UserRole]) {
    organization(organizationId: $organizationId, roleFilter: $roleFilter) {
      organizationId
      organizationType
      name
      metadata
      externalSyncData {
        sourceId
      }
      locations {
        locationId
        description
        region {
          regionId
          name
        }
        territory {
          territoryId
          name
        }
        locationUser {
          user {
            userId
            firstName
            lastName
            role
          }
        }
        externalSyncData {
          sourceId
        }
      }
    }
  }
`;

export const FIND_LOCATIONS = gql`
  query FindLocations($organizationId: Int, $regionId: Int, $territoryId: Int) {
    locations(organizationId: $organizationId, regionId: $regionId, territoryId: $territoryId) {
      locationId
      description
      region {
        regionId
        name
      }
      territory {
        territoryId
        name
      }
    }
  }
`;

export const FIND_LOCATION_AND_REGIONS = gql`
  query FindLocationAndRegions($locationId: Int!) {
    location(locationId: $locationId) {
      locationId
      description
      region {
        regionId
        name
      }
      territory {
        territoryId
        name
      }
    }
    regions {
      regionId
      name
      territories {
        territoryId
        name
        regionId
      }
    }
  }
`;

export const FIND_LOCATION_AND_REGIONS_AND_USERS = gql`
  query FindLocationAndRegionsAndUsers($locationId: Int!, $roleFilter: [UserRole]) {
    location(locationId: $locationId, roleFilter: $roleFilter) {
      locationId
      description
      region {
        regionId
        name
      }
      territory {
        territoryId
        name
      }
      locationUser {
        user {
          userId
          firstName
          lastName
          role
        }
      }
      externalSyncData {
        sourceId
      }
    }
    regions {
      regionId
      name
      territories {
        territoryId
        name
        regionId
      }
    }
  }
`;

export const FIND_PLAN = gql`
  ${fragment.PLANS_FIELDS_FRAGMENT}

  query FindPlan($planId: Int!, $input: FindPlanInput) {
    plan(planId: $planId, input: $input) {
      ...plansFields
      implants {
        planImplantId
        planId
        level
        partType
        bullet
        position
        rotation
        referencePoints
        ap
        ml
        threadHeight
        screwLength
        excludedImplantSizes
        obliqueThreadAngle
        cranialCaudalThreadAngle
      }
    }
  }
`;

export const FIND_PLAN_IMPLANTS = gql`
  query FindPlanImplants($planId: Int!) {
    planImplants(planId: $planId) {
      planImplantId
      planId
      partType
      level
      bullet
      position
      rotation
      referencePoints
      ap
      ml
      threadHeight
      screwLength
      excludedImplantSizes
      obliqueThreadAngle
      cranialCaudalThreadAngle
    }
  }
`;

export const FIND_PLAN_IMPLANT_BY_LEVEL = gql`
  query FindPlanImplantByLevel($planId: Int!, $level: LevelType!) {
    planImplant(planId: $planId, level: $level) {
      planImplantId
      planId
      level
      bullet
      position
      rotation
      referencePoints
      ap
      ml
      threadHeight
      screwLength
      excludedImplantSizes
    }
  }
`;

export const FIND_PLANS = gql`
  ${fragment.PLANS_FIELDS_FRAGMENT}

  query FindPlans($caseId: Int!, $showDeleted: Boolean) {
    plans(caseId: $caseId, showDeleted: $showDeleted) {
      ...plansFields
      implants {
        planImplantId
        planId
        level
        partType
        bullet
        position
        rotation
        referencePoints
        ap
        ml
        threadHeight
        screwLength
        excludedImplantSizes
        obliqueThreadAngle
        cranialCaudalThreadAngle
      }
    }
  }
`;

export const FIND_PLAN_BY_STATUS = gql`
  ${fragment.PLANS_FIELDS_FRAGMENT}

  query FindPlanByStatus($caseId: Int!, $status: [PlanStatus!]) {
    planByStatus(caseId: $caseId, status: $status) {
      ...plansFields
      implants {
        planImplantId
        planId
        level
        partType
        bullet
        position
        rotation
        referencePoints
        ap
        ml
        threadHeight
        screwLength
        excludedImplantSizes
        obliqueThreadAngle
        cranialCaudalThreadAngle
      }
    }
  }
`;

export const FIND_CASE_RESULTS_BY_CASE_ID = gql`
  query FindCaseResultsByCaseId($caseId: Int!) {
    caseResultsByCaseId(caseId: $caseId) {
      caseId
      level
      size
      part
      result
      notUsedReasons
      exceptionReason
      screwsUsed
      screwLengthUsed
      screwLengthLeft
      screwLengthMiddle
      screwLengthRight
    }
  }
`;

export const FIND_POST_OP_ANALYSIS_FORM_DATA = gql`
  query FindPostOpAnalysis($caseId: Int!, $analysisType: AnalysisType!) {
    postOpAnalysis(caseId: $caseId, analysisType: $analysisType) {
      caseId
      postOpAnalysisId
      analysisType
      coronalBalance
      lumbarCoronalAngulation
      lumbarLordosis
      pelvicTilt
      sagittalVerticalAxis
      segmentalLumbarLordosis
      segmentalCoronalAngle
      segmentalAnteriorHeight
      segmentalPosteriorHeight
      angleToS1
      thoracicKyphosis
    }
    assets(caseId: $caseId, assetTypeFilter: [POST_OP_SIX_WEEKS_XRAY, POST_OP_ONE_YEAR]) {
      assetId
      assetType
      metadata
    }
  }
`;

export const FIND_POST_OP_ANALYSIS_DATA = gql`
  ${fragment.CASE_FIELDS_FRAGMENT}
  ${fragment.TAG_FIELDS_FRAGMENT}
  query FindPostOpAnalysisData($caseId: Int!, $measurementsVersion: Int) {
    case(caseId: $caseId) {
      ...caseFields
      caseResults {
        size
        part
        result
        exceptionReason
        level
      }
      caseTags {
        caseTagId
        tag {
          ...tagFields
        }
      }
      settings {
        measurementsVersion
      }
    }
    comments(caseId: $caseId) {
      count
    }
    caseImplantSpecifications(caseId: $caseId) {
      caseImplantSpecifications {
        cloudDesignQueueId
        level
        caseId
        apDepth
        bulletAngle
        bulletHeight
        coronalAngle
        leftBulletAngle
        leftBulletHeight
        lordoticAngle
        maxHeight
        mlWidth
        partType
        rightBulletAngle
        rightBulletHeight
        size
        screwLength
        anteriorHeight
        posteriorHeight
        revision
        description
      }
    }
    assets(
      caseId: $caseId
      assetTypeFilter: [
        POST_OP_INTRA_OP_XRAY
        POST_OP_SIX_WEEKS_XRAY
        POST_OP_SIX_MONTHS_XRAY
        POST_OP_ONE_YEAR_XRAY
        POST_OP_TWO_YEAR_XRAY
        POST_OP_INTRA_OP_STANDING_XRAY_LATERAL
        POST_OP_SIX_WEEKS_STANDING_XRAY_LATERAL
        POST_OP_SIX_MONTHS_STANDING_XRAY_LATERAL
        POST_OP_ONE_YEAR_STANDING_XRAY_LATERAL
        POST_OP_TWO_YEAR_STANDING_XRAY_LATERAL
        POST_OP_INTRA_OP_STANDING_XRAY_AP
        POST_OP_SIX_WEEKS_STANDING_XRAY_AP
        POST_OP_SIX_MONTHS_STANDING_XRAY_AP
        POST_OP_ONE_YEAR_STANDING_XRAY_AP
        POST_OP_TWO_YEAR_STANDING_XRAY_AP
      ]
    ) {
      assetId
      assetType
      caseId
      createdAt
      createdByUser {
        firstName
        lastName
      }
      deletedAt
      fileName
      metadata
      planId
      size
      updatedAt
      updatedByUser {
        firstName
        lastName
      }
      version
    }
    planByStatus(caseId: $caseId, status: [DRAFT, PROPOSED, APPROVED]) {
      assetPositions
      implants {
        planImplantId
        planId
        level
        partType
        bullet
        position
        rotation
        referencePoints
        ap
        ml
        threadHeight
        screwLength
        excludedImplantSizes
        obliqueThreadAngle
        cranialCaudalThreadAngle
      }
    }
    preOp: postOpAnalysis(
      caseId: $caseId
      analysisType: PRE_OP
      measurementsVersion: $measurementsVersion
    ) {
      analysisType
      segmentalPosteriorHeight
      segmentalAnteriorHeight
      segmentalCoronalAngle
      segmentalLumbarLordosis
      angleToS1
      coronalBalance
      caseId
      sagittalVerticalAxis
      thoracicKyphosis
      pelvicTilt
      lumbarLordosis
      lumbarCoronalAngulation
      angleToS1
    }
    plan: postOpAnalysis(
      caseId: $caseId
      analysisType: PLAN
      measurementsVersion: $measurementsVersion
    ) {
      analysisType
      segmentalPosteriorHeight
      segmentalAnteriorHeight
      segmentalCoronalAngle
      segmentalLumbarLordosis
      angleToS1
      coronalBalance
      caseId
      sagittalVerticalAxis
      thoracicKyphosis
      pelvicTilt
      lumbarLordosis
      lumbarCoronalAngulation
      angleToS1
    }
    postOpIntraOp: postOpAnalysis(caseId: $caseId, analysisType: POST_OP_INTRA_OP) {
      analysisType
      segmentalPosteriorHeight
      segmentalAnteriorHeight
      segmentalCoronalAngle
      segmentalLumbarLordosis
      angleToS1
      coronalBalance
      caseId
      sagittalVerticalAxis
      thoracicKyphosis
      pelvicTilt
      lumbarLordosis
      lumbarCoronalAngulation
      angleToS1
    }
    postOpSixWeeks: postOpAnalysis(caseId: $caseId, analysisType: POST_OP_SIX_WEEKS) {
      analysisType
      segmentalPosteriorHeight
      segmentalAnteriorHeight
      segmentalCoronalAngle
      segmentalLumbarLordosis
      angleToS1
      coronalBalance
      caseId
      sagittalVerticalAxis
      thoracicKyphosis
      pelvicTilt
      lumbarLordosis
      lumbarCoronalAngulation
      angleToS1
    }
    postOpSixMonths: postOpAnalysis(caseId: $caseId, analysisType: POST_OP_SIX_MONTHS) {
      analysisType
      segmentalPosteriorHeight
      segmentalAnteriorHeight
      segmentalCoronalAngle
      segmentalLumbarLordosis
      angleToS1
      coronalBalance
      caseId
      sagittalVerticalAxis
      thoracicKyphosis
      pelvicTilt
      lumbarLordosis
      lumbarCoronalAngulation
      angleToS1
    }
    postOpOneYear: postOpAnalysis(caseId: $caseId, analysisType: POST_OP_ONE_YEAR) {
      analysisType
      segmentalPosteriorHeight
      segmentalAnteriorHeight
      segmentalCoronalAngle
      segmentalLumbarLordosis
      angleToS1
      coronalBalance
      caseId
      sagittalVerticalAxis
      thoracicKyphosis
      pelvicTilt
      lumbarLordosis
      lumbarCoronalAngulation
      angleToS1
    }
    postOpTwoYears: postOpAnalysis(caseId: $caseId, analysisType: POST_OP_TWO_YEARS) {
      analysisType
      segmentalPosteriorHeight
      segmentalAnteriorHeight
      segmentalCoronalAngle
      segmentalLumbarLordosis
      angleToS1
      coronalBalance
      caseId
      sagittalVerticalAxis
      thoracicKyphosis
      pelvicTilt
      lumbarLordosis
      lumbarCoronalAngulation
      angleToS1
    }
  }
`;

export const FIND_CASE = gql`
  ${fragment.CASE_FIELDS_FRAGMENT}
  ${fragment.TAG_FIELDS_FRAGMENT}

  query FindCase($caseId: Int!) {
    case(caseId: $caseId) {
      ...caseFields
      assets {
        assetType
        assetId
        fileName
        metadata
        size
      }
      caseTags {
        caseTagId
        tag {
          ...tagFields
        }
      }
      settings {
        measurementsVersion
      }
      vendorAssignments {
        organizationId
        organization {
          organizationId
          name
          metadata
        }
        taskCategory
      }
    }
    comments(caseId: $caseId, commentTypeFilter: [CASE_COMMENT]) {
      comments {
        commentId
        comment
        createdAt
        createdByUser {
          firstName
          lastName
          email
        }
        isPinned
      }
      count
    }
    tasks(caseId: $caseId) {
      tasks {
        taskId
        status
        description
        createdAt
        createdByUser {
          firstName
          lastName
          email
        }
        updatedAt
        updatedByUser {
          firstName
          lastName
          email
        }
        assignedUser {
          firstName
          lastName
          email
        }
      }
      count
    }
    settings {
      form20
    }
  }
`;

export const FIND_CASE_LEVELS = gql`
  query FindCaseLevels($caseId: Int!) {
    case(caseId: $caseId) {
      levels {
        C2_C3
        C3_C4
        C4_C5
        C5_C6
        C6_C7
        C7_C8
        C7_T1
        C8_T1
        C6_T1
        L1_L2
        L2_L3
        L3_L4
        L4_L5
        L5_S1
        L5_L6
        L6_S1
        L4_S1
      }
    }
  }
`;

export const ESTIMATE_CASE_PRODUCTION_TIME = gql`
  query EstimateCaseProductionTime($startDate: DateTime!) {
    caseProductionTime(startDate: $startDate) {
      completedInDays
      endDate
      startDate
      pipeline {
        vendorId
        startDate
        endDate
        lengthInDays
        lengthInBusinessDays
        vendorEstimateInBusinessDays
        stage
        subStage
        order
      }
    }
  }
`;

export const FIND_DAISY_SEGMENTATION_QUEUE = gql`
  query FindDaisySegmentationQueueByLevel($caseId: Int!) {
    daisySegmentationQueue(caseId: $caseId) {
      daisySegmentationQueueId
      caseId
      assetId
      name
      status
      vertebraeStatus
      sacrumStatus
      landmarkingStatus
      minutesProcessing
      startedAt
      finishedAt
      log
      createdAt
      createdByUser {
        firstName
        lastName
        email
      }
    }
  }
`;

export const FIND_AUTO_CORRECT_QUEUE = gql`
  query FindAutoCorrectQueue($caseId: Int!) {
    autoCorrectQueue(caseId: $caseId) {
      autoCorrectQueueId
      caseId
      planId
      status
      minutesProcessing
      startedAt
      finishedAt
      log
      createdAt
      createdByUser {
        firstName
        lastName
        email
      }
    }
  }
`;

export const FIND_CLOUD_DESIGN_QUEUE_BY_LEVEL = gql`
  query FindCloudDesignQueueByLevel(
    $caseId: Int!
    $planId: Int!
    $level: LevelType!
    $levelSize: LevelSize
    $type: CloudDesignQueueType
  ) {
    cloudDesignQueueByLevel(
      caseId: $caseId
      planId: $planId
      level: $level
      levelSize: $levelSize
      type: $type
    ) {
      cloudDesignQueueId
      caseId
      planId
      assetId
      level
      name
      status
      minutesProcessing
      startedAt
      finishedAt
      log
      createdAt
      createdByUser {
        firstName
        lastName
        email
      }
    }
  }
`;

export const FIND_COMMENTS = gql`
  query FindComments($caseId: Int!) {
    comments(caseId: $caseId) {
      comments {
        commentId
        comment
        isPinned
        createdAt
        updatedAt
        createdByUser {
          userId
          firstName
          lastName
          email
          role
        }
      }
      count
    }
  }
`;

export const FIND_DISCUSSIONS = gql`
  query FindDiscussions($caseId: Int!, $commentTypeFilter: [CommentType]) {
    activities(
      caseId: $caseId
      activityTypeFilter: [
        CASE_STAGE_OPEN_COMPLETE
        CASE_STAGE_SEGMENTATION_COMPLETE
        CASE_STAGE_SEGMENTATION_REOPENED
        CASE_STAGE_PLANNING_COMPLETE
        CASE_STAGE_PLANNING_REOPENED
        CASE_STAGE_PROPOSED_COMPLETE
        CASE_STAGE_PROPOSED_REOPENED
        CASE_STAGE_DESIGN_COMPLETE
        CASE_STAGE_DESIGN_REOPENED
        CASE_STAGE_QA_REVIEW_COMPLETE
        CASE_STAGE_QA_REVIEW_REOPENED
        CASE_STAGE_PRODUCTION_COMPLETE
        CASE_STAGE_PRODUCTION_REOPENED
        CASE_STAGE_READY_COMPLETE
        CASE_STAGE_READY_REOPENED
      ]
    ) {
      activities {
        caseId
        activityId
        activityType
        originId
        originType
        delta
        createdByUser {
          userId
          firstName
          lastName
        }
        createdAt
      }
      count
    }
    comments(caseId: $caseId, commentTypeFilter: $commentTypeFilter) {
      comments {
        commentId
        comment
        isPinned
        createdAt
        updatedAt
        commentType
        createdByUser {
          userId
          firstName
          lastName
          email
          role
        }
      }
      count
    }
  }
`;

export const FIND_USER_CURRENT = gql`
  query {
    currentUser {
      userId
      firstName
      lastName
      email
      role
      reportsToUser {
        userId
        firstName
        lastName
        role
      }
      permissions
      userType
      status
    }
    settings {
      application
    }
  }
`;

export const FIND_EVENTS = gql`
  query FindEvents($caseId: Int!) {
    events(caseId: $caseId) {
      events {
        eventId
        eventType
        carrierType
        trackingNumber
        description
        note
        occurredAt
        createdAt
        createdByUser {
          userId
          firstName
          lastName
          email
          role
        }
      }
      count
    }
  }
`;

export const FIND_CASE_PRODUCTION_TAB_DATA = gql`
  query FindCaseProductionTabData($caseId: Int!) {
    events(caseId: $caseId) {
      events {
        eventId
        eventType
        carrierType
        trackingNumber
        purchaseOrderNumber
        dueDate
        description
        note
        occurredAt
        createdAt
        createdByUser {
          userId
          firstName
          lastName
          email
          role
        }
        assetId
        asset {
          assetId
          assetType
          caseId
          createdAt
          createdByUser {
            firstName
            lastName
          }
          deletedAt
          fileName
          metadata
          planId
          size
          updatedAt
          updatedByUser {
            firstName
            lastName
          }
          version
        }
      }
      count
    }
    caseShipping(caseId: $caseId) {
      update {
        caseShippingId
        source
        status
        trackingNumber
        result
        createdAt
      }
    }
  }
`;

export const FIND_SETTINGS = gql`
  query FindSettings {
    settings {
      emails
      form20
      form19
      application
    }
  }
`;

export const FIND_TASKS = gql`
  query FindTasks(
    $caseId: Int
    $assignedId: Int
    $status: TaskStatusType
    $orderBy: TaskOrderByInput
    $actionType: String
    $take: Int
    $skip: Int
  ) {
    tasks(
      caseId: $caseId
      assignedId: $assignedId
      status: $status
      actionType: $actionType
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      tasks {
        taskId
        description
        status
        statusDescription
        dueDate
        priority
        createdAt
        updatedAt
        createdByUser {
          userId
          firstName
          lastName
          email
          role
        }
        updatedByUser {
          userId
          firstName
          lastName
          email
          role
        }
        assignedUser {
          userId
          firstName
          lastName
          email
          role
        }
        taskCase {
          caseId
          number
        }
      }
      count
    }
  }
`;

export const FIND_TASK = gql`
  query FindTask($taskId: Int!) {
    task(taskId: $taskId) {
      taskId
      caseId
      description
      status
      statusDescription
      dueDate
      priority
      createdAt
      updatedAt
      createdByUser {
        userId
        firstName
        lastName
        email
        role
      }
      updatedByUser {
        userId
        firstName
        lastName
        email
        role
      }
      assignedUser {
        userId
        firstName
        lastName
        email
        role
      }
    }
  }
`;

export const FIND_POST_OP_ANALYSIS = gql`
  query FindPostOpAnalysis($caseId: Int!, $analysisType: AnalysisType!) {
    postOpAnalysis(caseId: $caseId, analysisType: $analysisType) {
      caseId
      postOpAnalysisId
      analysisType
      coronalBalance
      lumbarCoronalAngulation
      lumbarLordosis
      pelvicTilt
      sagittalVerticalAxis
      segmentalLumbarLordosis
      segmentalCoronalAngle
      segmentalAnteriorHeight
      segmentalPosteriorHeight
      angleToS1
      thoracicKyphosis
    }
  }
`;

export const FIND_FORM19 = gql`
  query FindForm19($caseId: Int!, $planId: Int!, $assetTypeFilter: [AssetType]!) {
    assets(caseId: $caseId, planId: $planId, assetTypeFilter: $assetTypeFilter) {
      assetId
      assetType
      caseId
      planId
      fileName
      size
      version
      signedDownloadUrl
    }
    form19(caseId: $caseId) {
      form19Id
      caseId
      isImageSliceValid
      levelNotes
      data
      isNonConformance
      nonConformanceReason
      caseRiskAssessment
      revision
      description
      engineeringApprovedByUser {
        userId
        firstName
        lastName
        email
        role
      }
      engineeringApprovedAt
      qualityApprovedByUser {
        userId
        firstName
        lastName
        email
        role
      }
      qualityApprovedAt
      peerReviewerUser {
        userId
        firstName
        lastName
        email
        role
      }
    }
    form19History(caseId: $caseId) {
      form19Id
      caseId
      revision
      description
    }
    caseImplantSpecifications(caseId: $caseId) {
      caseImplantSpecifications {
        caseImplantSpecificationId
        caseId
        cloudDesignQueueId
        level
        partType
        size
        apDepth
        mlWidth
        maxHeight
        bulletHeight
        bulletAngle
        leftBulletHeight
        leftBulletAngle
        rightBulletHeight
        rightBulletAngle
        coronalAngle
        lordoticAngle
        isBulletMinHeightValid
        isBulletMinDistanceValid
        isCosmeticCheckValid
        isPartMarkingsValid
        screwLength
        anteriorHeight
        posteriorHeight
        revision
        description
      }
      count
    }
    settings {
      form19
      form20
      application
    }
  }
`;

export const FIND_TAGS = gql`
  ${fragment.TAG_FIELDS_FRAGMENT}

  query FindTags($tagCategoryId: Int) {
    tags(tagCategoryId: $tagCategoryId) {
      ...tagFields
    }
  }
`;

export const FIND_FORM19_BY_CASE_ID = gql`
  query FindForm19($caseId: Int!) {
    form19(caseId: $caseId) {
      form19Id
      caseId
      isImageSliceValid
      levelNotes
      data
      isNonConformance
      nonConformanceReason
      caseRiskAssessment
      revision
      description
      engineeringApprovedByUser {
        userId
        firstName
        lastName
        email
        role
      }
      engineeringApprovedAt
      qualityApprovedByUser {
        userId
        firstName
        lastName
        email
        role
      }
      qualityApprovedAt
      peerReviewerUser {
        userId
        firstName
        lastName
        email
        role
      }
    }
  }
`;

export const FIND_CASE_IMPLANT_SPECIFICATIONS_BY_CASE_ID = gql`
  query FindCaseImplantSpecifications($caseId: Int!) {
    caseImplantSpecifications(caseId: $caseId) {
      caseImplantSpecifications {
        caseImplantSpecificationId
        caseId
        cloudDesignQueueId
        level
        partType
        size
        apDepth
        mlWidth
        maxHeight
        bulletHeight
        bulletAngle
        leftBulletHeight
        leftBulletAngle
        rightBulletHeight
        rightBulletAngle
        coronalAngle
        lordoticAngle
        isBulletMinHeightValid
        isBulletMinDistanceValid
        isCosmeticCheckValid
        isPartMarkingsValid
        screwLength
        anteriorHeight
        posteriorHeight
        revision
        description
      }
      count
    }
  }
`;

export const FIND_TAG_CATEGORIES = gql`
  ${fragment.TAG_CATEGORY_FIELDS_FRAGMENT}

  query FindTagCategories {
    tagCategories {
      ...tagCategoryFields
    }
  }
`;

export const FIND_ORGANIZATIONS_AND_PRICE_LEVELS = gql`
  query FindOrganizationsAndPriceLevels(
    $organizationTypeFilter: [OrganizationType]
    $orderBy: OrganizationOrderByInput
    $search: String
    $regionId: Int
    $take: Int
    $skip: Int
  ) {
    organizations(
      organizationTypeFilter: $organizationTypeFilter
      orderBy: $orderBy
      search: $search
      regionId: $regionId
      take: $take
      skip: $skip
    ) {
      organizations {
        organizationId
        organizationType
        name
        externalSyncData {
          sourceId
        }
        locations {
          description
          region {
            name
          }
        }
        priceLevels {
          priceLevelId
          priceLevelType
          startDate
          endDate
          organization {
            organizationId
            name
          }
        }
      }
      count
    }
  }
`;

export const FIND_PRICE_LEVELS_BY_ORGANIZATION = gql`
  query FindPriceLevels($organizationId: Int) {
    priceLevels(organizationId: $organizationId) {
      organization {
        name
        organizationId
      }
      priceLevelId
      priceLevelType
      startDate
      endDate
      oneLevel
      twoLevel
      threeLevel
      fourLevel
      fiveLevel
    }
  }
`;

export const FIND_PRICE_LEVEL_BY_PRICE_LEVEL_ID = gql`
  query FindPriceLevelDialog($priceLevelId: Int) {
    priceLevel(priceLevelId: $priceLevelId) {
      priceLevelId
      organization {
        organizationId
        name
      }
      priceLevelType
      startDate
      endDate
      oneLevel
      twoLevel
      threeLevel
      fourLevel
      fiveLevel
    }
  }
`;

export const FIND_REGIONS = gql`
  query FindRegions {
    regions {
      regionId
      name
      territories {
        territoryId
        name
        regionId
      }
    }
  }
`;

export const FIND_TIMELINE = gql`
  query FindTimeline($caseId: Int!, $commentTypeFilter: [CommentType]) {
    timeline(caseId: $caseId, commentTypeFilter: $commentTypeFilter) {
      activities {
        activityId
        activityType
        originId
        originType
        createdAt
        delta
        entry
      }
    }
  }
`;

export const EXPORT_CASE = gql`
  query ExportCase($caseId: Int!) {
    exportCase(caseId: $caseId) {
      version
      exportedTimestamp
      exportedCaseId
      caseData {
        stage
        number
        shortNumber
        spineType
        levels
        includeRodTemplates
        caseType
        approach
        surgeryDate
        isSurgeryDateTentative
        isXrayNotRequired
        liv
        pco
        pso
        uiv
        levelsMetadata
        stageSortOrder
        studyDate
        settings
        spineProfile
        receivedAt
      }
      caseResultsData {
        createdAt
        level
        part
        exceptionReason
        result
        screwLengthUsed
        screwsUsed
        size
      }
      patientData {
        firstName
        middleName
        lastName
        birthDate
        gender
        mrn
      }
      patientRecordData {
        preopMeasurements
        bmi
        odi
        boneDensity
        tScoreSpine
        tScoreHip
        vasBack
        vasLeg
        pelvicIncidence
        lumbarLordosis
        lumbarCoronalCobb
        coronalBalance
        sagittalVerticalAxis
        pelvicTilt
        crestHeight
        s1AnteriorPubisLength
        s1AnteriorPubisAngle
        biFemoralAxisLength
        biFemoralAxisMidpointS1EndplateMidpoint
        biIliacWidth
        posteriorIliacCrestWidth
        hasPelvisOblique
        hasPelvisHighCrest
        hasPelvisHighPelvicIncidence
      }
      planData {
        name
        status
        approvedAt
        operations
        isAppReady
        assetPositions
        implants
        plusLevelSize
        version
      }
      planImplantData {
        level
        partType
        bullet
        position
        rotation
        referencePoints
        excludedImplantSizes
        ap
        ml
        screwLength
        threadHeight
        obliqueThreadAngle
        cranialCaudalThreadAngle
      }
      planImplantSpecData {
        level
        partType
        size
        apDepth
        mlWidth
        maxHeight
        bulletHeight
        bulletAngle
        leftBulletHeight
        leftBulletAngle
        rightBulletHeight
        rightBulletAngle
        coronalAngle
        lordoticAngle
        isBulletMinHeightValid
        isBulletMinDistanceValid
        isCosmeticCheckValid
        isPartMarkingsValid
        screwLength
        anteriorHeight
        posteriorHeight
        revision
        description
      }
      assets {
        exportedAssetId
        exportedPlanId
        signedUrl
        assetData {
          fileName
          assetType
          metadata
          size
          version
        }
      }
      events {
        exportedEventId
        exportedAssetData {
          assetId
          assetType
          planId
        }
        eventData {
          note
          occurredAt
          description
          trackingNumber
          eventType
          carrierType
          purchaseOrderNumber
          dueDate
        }
      }
      caseLevels {
        level
        partType
        isFused
        threadType
        threadedHoles
        anteriorInstrumentation
        otherVendor
        implantType
        implantDirection
        implantOrientation
        createdAt
      }
      caseMeasurements {
        body
        endPlate
        position
        source
        point
        createdAt
      }
      postOpAnalysisData {
        analysisType
        lumbarCoronalAngulation
        lumbarLordosis
        pelvicTilt
        thoracicKyphosis
        sagittalVerticalAxis
        coronalBalance
        segmentalLumbarLordosis
        segmentalCoronalAngle
        segmentalAnteriorHeight
        segmentalPosteriorHeight
        angleToS1
      }
      form19Data {
        engineeringApprovedAt
        qualityApprovedAt
        isImageSliceValid
        isNonConformance
        nonConformanceReason
        caseRiskAssessment
        revision
        description
        levelNotes
        data
      }
    }
  }
`;

export const FIND_SITE_CONFIGURATIONS = gql`
  query FindSiteConfigurations {
    siteConfigurations {
      key
      value
    }
  }
`;
