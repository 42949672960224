import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@apollo/client';
import { FIND_REPORT_CASE_LOAD_EXTENDED } from '../../gql';
import {
  UserRoleType,
  format,
  CaseStageTypeColor,
  CaseStageType,
  CaseSetType,
} from '@workflow-nx/common';
import { DashboardCardView } from './DashboardCardView';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Box, useTheme } from '@mui/material';
import CaseLoadPopup from './CaseLoadPopup';
import { barChartTheme } from './CasesByQuarter';

type CaseLoadType = {
  designer: string;
  segmentation: number;
  planning: number;
  proposed: number;
  design: number;
  qaReview: number;
  segmentationCases: CaseSetType[];
  planningCases: CaseSetType[];
  proposedCases: CaseSetType[];
  designCases: CaseSetType[];
  qareviewCases: CaseSetType[];
};

const CaseLoadResources = () => {
  const { data: reportDataExtended, loading } = useQuery(FIND_REPORT_CASE_LOAD_EXTENDED);
  const [caseLoad, setCaseLoad] = useState<CaseLoadType[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | SVGRectElement>(null);
  const [caseSets, setCaseSets] = useState<CaseSetType[]>([]);
  const theme = useTheme();
  const open = Boolean(anchorEl);

  const handlePopupClick = (
    event: React.MouseEvent<SVGRectElement, MouseEvent>,
    data: Record<string, unknown>,
  ) => {
    const id = data?.id as string;
    const key = id?.toLowerCase().replace(' ', '') + 'Cases';
    const currentDataSet = (data?.data as Record<string, unknown | CaseSetType>)[
      key
    ] as CaseSetType[];

    if (currentDataSet) {
      setCaseSets(currentDataSet);
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (reportDataExtended && reportDataExtended.reportCaseLoadExtended.length > 0) {
      const caseLoad: CaseLoadType[] = [];
      reportDataExtended.reportCaseLoadExtended.forEach(
        (reportCase: {
          userId: number;
          firstName: string;
          lastName: string;
          email: string;
          role: UserRoleType;
          cases: CaseSetType[];
        }) => {
          const segmentationCases = reportCase.cases.filter(
            (ele) => ele.stage === CaseStageType.Segmentation,
          );
          const planningCases = reportCase.cases.filter(
            (ele) => ele.stage === CaseStageType.Planning,
          );
          const proposedCases = reportCase.cases.filter(
            (ele) => ele.stage === CaseStageType.Proposed,
          );
          const designCases = reportCase.cases.filter((ele) => ele.stage === CaseStageType.Design);
          const qareviewCases = reportCase.cases.filter(
            (ele) => ele.stage === CaseStageType.QaReview,
          );
          caseLoad.push({
            designer: format.formatName(reportCase),
            segmentation: segmentationCases.length,
            planning: planningCases.length,
            proposed: proposedCases.length,
            design: designCases.length,
            qaReview: qareviewCases.length,
            segmentationCases,
            planningCases,
            proposedCases,
            designCases,
            qareviewCases,
          });
        },
      );

      setCaseLoad(caseLoad);
    }
  }, [reportDataExtended]);

  return (
    <DashboardCardView label={'Designer Load'}>
      {loading ? (
        <Box height={'inherit'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <ResponsiveBar
          data={caseLoad as unknown as BarDatum[]}
          keys={['segmentation', 'planning', 'proposed', 'design', 'qaReview']}
          indexBy="designer"
          theme={barChartTheme(theme)}
          margin={{ top: 30, right: 50, bottom: 50, left: 150 }}
          padding={0.3}
          layout="horizontal"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={[
            CaseStageTypeColor.Segmentation, // SEGMENTATION
            CaseStageTypeColor.Planning, // PLANNING
            CaseStageTypeColor.Proposed, // PROPOSED
            CaseStageTypeColor.Design, // DESIGN
            CaseStageTypeColor.QaReview, // QA_REVIEW
          ]}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Number of Cases',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            format: (v) => {
              let value = v;
              if (v.length > 18) {
                value = v.substring(0, 18) + '...';
              }
              return value;
            },
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -115,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={'white'}
          legendLabel={(d) => {
            switch (d.id) {
              case 'segmentation':
                return 'Segmentation';
              case 'planning':
                return 'Planning';
              case 'proposed':
                return 'Proposed';
              case 'design':
                return 'Design';
              case 'qaReview':
                return 'QA Review';
              default:
                return d.id as string;
            }
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -20,
              itemsSpacing: 5,
              itemWidth: 130,
              itemHeight: 0,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          tooltip={() => <></>}
          role="application"
          ariaLabel="Designer Load"
          onClick={(data, event) => {
            handlePopupClick(event, data);
          }}
        />
      )}

      {anchorEl ? (
        <CaseLoadPopup
          event={anchorEl}
          open={open}
          caseSets={caseSets}
          handlePopupClose={handlePopupClose}
        />
      ) : null}
    </DashboardCardView>
  );
};
export default CaseLoadResources;
