import React, { useEffect, useState } from 'react';
import { Box, Chip, Popover, Stack, Typography } from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { ICase, format, CaseStageType, CaseType, UserRoleType } from '@workflow-nx/common';
import { InterbodyLevelsView } from '../CaseView/InterbodyLevelsView';
import { FormattedFieldView } from '../../../components/FormattedFieldView';
import { Button } from '@workflow-nx/ui';
import { useNavigate } from 'react-router-dom';
import { CaseStudyDateExpirationView } from '../CaseView/CaseStudyDateExpirationView';

const CalendarGridEventView = (props: { children: React.ReactNode; extendedProps: ICase }) => {
  const [activeCase, setActiveCase] = useState<ICase>();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'caseDetailsPopover',
  });
  const navigate = useNavigate();
  const isTentative = activeCase?.isSurgeryDateTentative;
  const caseType = activeCase?.caseType;
  const isComplete = activeCase?.stage === CaseStageType.Complete;
  const lastEvent = activeCase?.events?.[0] ?? undefined;

  useEffect(() => {
    setActiveCase(props.extendedProps);
  }, []);

  return (
    <Box display={'flex'} alignItems={'center'} width={'100%'}>
      <Box {...bindTrigger(popupState)} sx={{ cursor: 'pointer' }} width={'100%'}>
        {props.children}
      </Box>
      <Popover
        style={{ zIndex: 9999 }}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography variant={'h3'}>
          <Box p={2}>
            <Box display={'flex'} alignItems={'flex-start'}>
              <Stack direction={'row'} spacing={1}>
                <Typography variant={'h4'} gutterBottom={false}>
                  {activeCase?.number}
                </Typography>
                {caseType === CaseType.Draft ? (
                  <Chip size={'small'} color={'secondary'} label={'DRAFT'} />
                ) : null}
                {activeCase?.stage ? (
                  <Chip
                    size={'small'}
                    color={'secondary'}
                    label={format.formatCaseStage(activeCase.stage).toUpperCase()}
                  />
                ) : null}
              </Stack>
            </Box>
            {activeCase?.stage === CaseStageType.Production && lastEvent ? (
              <Typography variant={'body2'} color={'textSecondary'}>
                {format.formatEventType(lastEvent.eventType)}
              </Typography>
            ) : null}
            {!isComplete ? (
              <Box my={1}>
                <CaseStudyDateExpirationView studyDate={activeCase?.studyDate} />
              </Box>
            ) : undefined}
            <div>
              <Box display={'flex'} alignItems={'center'}>
                <FormattedFieldView
                  label={'Surgery Date'}
                  value={
                    <span>
                      {format.formatISODate(activeCase?.surgeryDate as unknown as string) || 'TBD'}
                      {isTentative ? (
                        <>
                          <Box pl={1} display={'inline'} />
                          <Chip size={'small'} color={'secondary'} label={'TENTATIVE'} />
                        </>
                      ) : null}
                    </span>
                  }
                />
              </Box>
              <Box my={1} />
              <Box>
                <FormattedFieldView
                  label={'Surgeon'}
                  value={
                    <Box>
                      <Typography variant={'body1'}>
                        {activeCase?.surgeonUser ? (
                          format.formatName(activeCase?.surgeonUser)
                        ) : (
                          <span>&mdash;</span>
                        )}
                      </Typography>
                      {activeCase?.surgeonUser?.organization ? (
                        <Typography color={'textSecondary'} variant={'button'}>
                          {activeCase?.surgeonUser?.organization?.name} &mdash;{' '}
                          {activeCase?.location?.description}
                        </Typography>
                      ) : null}
                    </Box>
                  }
                />
              </Box>
              <Box my={1} />
              <Box>
                <FormattedFieldView
                  label={'Assigned To'}
                  value={
                    activeCase?.assignedUser ? (
                      format.formatName(activeCase?.assignedUser)
                    ) : (
                      <span>&mdash;</span>
                    )
                  }
                />
              </Box>
              <Box my={1} />
              <Box>
                <FormattedFieldView
                  label={`${format.formatUserRole(UserRoleType.FieldRep)}`}
                  value={
                    activeCase?.fieldRepUser ? (
                      format.formatName(activeCase?.fieldRepUser)
                    ) : (
                      <span>&mdash;</span>
                    )
                  }
                />
              </Box>
              <Box my={1} />
              <Box>
                {activeCase?.levels ? (
                  <InterbodyLevelsView
                    levels={activeCase.levels}
                    spineProfile={activeCase.spineProfile}
                  />
                ) : (
                  <>&mdash;</>
                )}
              </Box>
              <Box my={1} />
              <Box display={'flex'} justifyContent={'center'} width={'100%'}>
                <Button
                  variant={'text'}
                  onClick={() => {
                    navigate(`/app/cases/${activeCase?.caseId}`);
                  }}
                  label={'View Case'}
                />
              </Box>
            </div>
          </Box>
        </Typography>
      </Popover>
    </Box>
  );
};

export default CalendarGridEventView;
