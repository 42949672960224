import React, { Suspense, Fragment } from 'react';
import DashboardLayout from './layouts/DashboardLayout';
import LoginView from './views/auth/LoginView';
import CodeView from './views/auth/CodeView';
import LoadingScreen from './components/LoadingScreen';
import GuestGuard from './components/GuestGuard';
import DashboardView from './views/DashboardView';
import ListCasesReadyForReview from './views/quality/ListCasesReadyForReview';
import AuthGuard from './components/AuthGuard';
import ListUsersView from './views/users/ListUsersView/ListUsers.view';
import ListSurgeonsView from './views/users/ListSurgeonsView/ListSurgeons.view';
import ListOrganizationsView from './views/organizations/ListOrganizationsView/ListOrganizations.view';
import CaseView from './views/cases/CaseView/CaseView';
import OrganizationView from './views/organizations/OrganizationView/OrganizationView.view';
import UserView from './views/users/UserView/UserView.view';
import SurgeonView from './views/users/SurgeonView/SurgeonView.view';
import ListCaseTriageView from './views/cases/ListCaseTriageView/ListCaseTriage.view';
import CaseCalendarView from './views/cases/CaseCalendarView/CaseCalendar.view';
import ListTasksView from './views/tasks/ListTasksView';
import ListPostOpCasesView from './views/post-op-cases/ListPostOpCases/ListPostOpCases.view';
import PostOpCaseView from './views/post-op-cases/PostOpCaseView/PostOpCase.view';
import { SettingsView } from './views/SettingsView';
import { Routes, Route, Navigate } from 'react-router-dom';
import ListCasesView from './views/cases/ListCasesView/ListCases.view';
import ListCaseEventsView from './views/cases/ListCasesView/ListCasesViewProductionFocused/ListCaseEvents.view';
import TOTPView from './views/auth/TOTPView';

export const renderRoutes = (routes: any[] = []) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route: any, i: number) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route key={i} path={route.path} element={Component ? <Component /> : <Layout />}>
              {route?.routes?.map((childRoute: any, j: number) => {
                const Component = childRoute.component;

                return (
                  <Route
                    key={j}
                    path={childRoute.path}
                    element={
                      <Guard>
                        <Component />
                      </Guard>
                    }
                  />
                );
              })}
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
};

const routes = [
  {
    guard: GuestGuard,
    path: '/login',
    component: LoginView,
  },
  {
    guard: GuestGuard,
    path: '/code',
    component: CodeView,
  },
  {
    guard: GuestGuard,
    path: '/totp',
    component: TOTPView,
  },
  {
    path: '/app/*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'settings',
        component: SettingsView,
      },
      {
        path: 'cases',
        component: ListCasesView,
      },
      {
        path: 'case-events',
        component: ListCaseEventsView,
      },
      {
        path: 'case-triage',
        component: ListCaseTriageView,
      },
      {
        path: 'post-op-analysis',
        component: ListPostOpCasesView,
      },
      {
        path: 'post-op-analysis/:caseId',
        component: PostOpCaseView,
      },
      {
        path: 'case-calendar',
        component: CaseCalendarView,
      },
      {
        path: 'cases/:caseId',
        component: CaseView,
      },
      {
        path: 'quality',
        component: ListCasesReadyForReview,
      },
      {
        path: 'users',
        component: ListUsersView,
      },
      {
        path: 'user/:userId',
        component: UserView,
      },
      {
        path: 'surgeons',
        component: ListSurgeonsView,
      },
      {
        path: 'surgeon/:userId',
        component: SurgeonView,
      },
      {
        path: 'tasks',
        component: ListTasksView,
      },
      {
        path: 'institutions',
        component: ListOrganizationsView,
      },
      {
        path: 'institutions/:organizationId',
        component: OrganizationView,
      },
      {
        path: '*',
        component: () => <Navigate to="/app/dashboard" />,
      },
    ],
  },
  {
    path: '*',
    component: () => <Navigate to="/app/dashboard" />,
  },
];

export default routes;
