import {
  CarrierType,
  EventType,
  ICase,
  IOrganization,
  IPatient,
  IUser,
  PriceLevelType,
  TaskPriorityType,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';

export interface ICreateEventDialogFormValues {
  occurredAt?: Date | null;
  eventType?: EventType;
  carrierType?: CarrierType;
  trackingNumber?: string;
  description?: string;
  note?: string;
  asset?: File;
}

export interface ICreateEventAssetDialogFormValues {
  occurredAt?: Date | null;
  dueDate?: Date | null;
  purchaseOrderNumber?: string;
  description?: string;
  note?: string;
}

export interface IUpdatePatientRecordsFormValues {
  bmi?: number;
  odi?: number;
  boneDensity?: string;
  tScoreSpine?: number;
  tScoreHip?: number;
  vasBack?: number;
  vasLeg?: number;
  pelvicIncidence?: number;
  lumbarLordosis?: number;
  lumbarCoronalCobb?: number;
  coronalBalance?: number;
  sagittalVerticalAxis?: number;
  slopeOfLineOfSight?: number;
}

export interface IUpdatePatientDetailsFormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  birthDate: Date | null;
  mrn: string;
  email?: string;
  phoneNumber?: string;
  contactPreference?: string;
  contactCadence?: string;
  hasPermissionToContact?: string;
  hasPermissionToSchedule?: string;
  participantId?: string;
}

export interface IUserDialogFormValues {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  linkedOrganizationId: string;
  organizationId: string;
  locationId: string;
  reportsToId: string;
}

export interface ICreateOrganizationDialogFormValues {
  name: string;
  organizationType: string;
  priceLevelType: PriceLevelType;
  oneLevel: number;
  twoLevel: number;
  threeLevel: number;
  fourLevel: number;
  fiveLevel: number;
  startDate: Date;
  endDate?: Date | null;
}
export interface IUpdateOrganizationDialogFormValues {
  name: string;
  externalSyncDataSourceId: string;
  organizationType: string;
  heatTreatAvailable: boolean;
}

export interface ICreateLocationDialogFormValues {
  description: string;
  regionId?: number;
  locationType: string;
}

export interface IUpdateCaseNumberDialogFormValues {
  firstName: string;
  lastName: string;
  receivedAt: Date;
}

export interface ICreateTaskDialogFormValues {
  description: string;
  assignedId: string;
  priority?: TaskPriorityType;
  dueDate?: Date | null;
}

export interface IUpdateTaskDialogFormValues {
  statusDescription: string;
  shouldCancel: boolean;
}

export const userDialogFormValues = (user?: IUser): IUserDialogFormValues => {
  return {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    role: user?.role ?? '',
    organizationId: user?.organization?.organizationId?.toString() ?? '',
    linkedOrganizationId: user?.linkedOrganization?.organizationId?.toString() ?? '',
    locationId: user?.location?.locationId?.toString() ?? '',
    reportsToId: user?.reportsToUser?.userId?.toString() ?? '',
  };
};

export const createOrganizationDialogFormValues = (): ICreateOrganizationDialogFormValues => {
  return {
    name: '',
    organizationType: '',
    priceLevelType: PriceLevelType.BasePlusLevel,
    oneLevel: 26000,
    twoLevel: 6000,
    threeLevel: 6000,
    fourLevel: 6000,
    fiveLevel: 6000,
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: null,
  };
};

export const updateOrganizationDialogFormValues = (
  organization?: IOrganization,
): IUpdateOrganizationDialogFormValues => {
  return {
    name: organization?.name ?? '',
    externalSyncDataSourceId: organization?.externalSyncData?.sourceId ?? '',
    organizationType: organization?.organizationType ?? '',
    heatTreatAvailable: !!organization?.metadata?.heatTreatAvailable,
  };
};

export const updateCaseNumberDialogFormValues = (
  activeCase: ICase,
): IUpdateCaseNumberDialogFormValues => {
  const receivedAt = date.parseCalendarDateFromString(activeCase.receivedAt as unknown as string);

  return {
    firstName: activeCase?.patient.firstName,
    lastName: activeCase?.patient.lastName,
    receivedAt: receivedAt ?? new Date(),
  };
};

export const updatePatientDetailsFormValues = (
  activeCase: ICase,
): IUpdatePatientDetailsFormValues => {
  const patient: IPatient = activeCase?.patient || {};
  return {
    firstName: patient?.firstName || '',
    middleName: patient?.middleName || '',
    lastName: patient?.lastName || '',
    gender: patient?.gender || '',
    birthDate: date.parseCalendarDateFromString(patient.birthDate as unknown as string),
    mrn: patient?.mrn || '',
    phoneNumber: patient?.phoneNumber || '',
    email: patient?.email || '',
    contactPreference: patient?.contactPreference || '',
    contactCadence: patient?.contactCadence || '',
    hasPermissionToContact: patient?.hasPermissionToContact?.toString() || '',
    hasPermissionToSchedule: patient?.hasPermissionToSchedule?.toString() || '',
    participantId: patient?.participantId || '',
  };
};

export const updatePatientRecordsFormValues = (
  activeCase: ICase,
): IUpdatePatientRecordsFormValues => {
  const patient: IPatient = activeCase?.patient || {};
  const patientRecord = patient?.patientRecord || {};

  return {
    bmi: patientRecord.bmi,
    odi: patientRecord.odi,
    boneDensity: patientRecord.boneDensity,
    tScoreSpine: patientRecord.tScoreSpine,
    tScoreHip: patientRecord.tScoreHip,
    vasBack: patientRecord.vasBack,
    vasLeg: patientRecord.vasLeg,
    pelvicIncidence: patientRecord.pelvicIncidence,
    lumbarLordosis: patientRecord.lumbarLordosis,
    lumbarCoronalCobb: patientRecord.lumbarCoronalCobb,
    coronalBalance: patientRecord.coronalBalance,
    sagittalVerticalAxis: patientRecord.sagittalVerticalAxis,
    slopeOfLineOfSight: patientRecord.slopeOfLineOfSight,
  };
};

export const createEventDialogFormValues = (): ICreateEventDialogFormValues => {
  return {
    occurredAt: undefined,
    eventType: undefined,
    description: '',
    carrierType: CarrierType.FedEx,
    trackingNumber: '',
    note: '',
    asset: undefined,
  };
};

export const createEventsDialogFormValues = (): ICreateEventDialogFormValues => {
  return {
    occurredAt: undefined,
    eventType: undefined,
    description: '',
    carrierType: CarrierType.FedEx,
    trackingNumber: '',
    note: '',
  };
};

export const createEventAssetsDialogFormValues = (): ICreateEventAssetDialogFormValues => {
  return {
    occurredAt: new Date(),
    dueDate: undefined,
    description: '',
    purchaseOrderNumber: '',
    note: '',
  };
};

export const createTaskDialogFormValues = (): ICreateTaskDialogFormValues => {
  return {
    description: '',
    assignedId: '',
    priority: undefined,
    dueDate: null,
  };
};

export const updateTaskDialogFormValues = (): IUpdateTaskDialogFormValues => {
  return {
    statusDescription: '',
    shouldCancel: false,
  };
};
