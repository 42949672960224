import React, { Dispatch } from 'react';
import { Box, Link, TableBody, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, IOrganization, ITableHeader, UserRoleType } from '@workflow-nx/common';
import { TableCell } from '../../../components/TableCell';
import { useNavigate } from 'react-router-dom';
import { CommonTable } from '../../../components/CommonTable';
import { ListOrganizationsActionType } from './ListOrganizations.reducer';
import { OrganizationLocationsView } from './OrganizationLocationsView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { Globals } from '../../../layouts/DashboardLayout';
import useAuth from '../../../hooks/useAuth';

type OrganizationListTableProps = {
  page: number;
  rowsPerPage: number;
  totalCaseCount: number;
  organizations: IOrganization[];
  orderBy: any;
  dispatch: Dispatch<ListOrganizationsActionType>;
  loading?: boolean;
};

const useStyles = makeStyles(() => ({
  row: {
    '& :hover': {
      cursor: 'pointer',
    },
  },
}));

const OrganizationListTable = ({
  page,
  rowsPerPage,
  organizations,
  totalCaseCount,
  orderBy,
  dispatch,
  loading,
}: OrganizationListTableProps) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const { hasRole } = useAuth();

  const headers: ITableHeader[] = [
    {
      id: 'name',
      sortable: true,
      label: 'Name',
    },
    {
      id: 'organizationType',
      sortable: true,
      label: 'Type',
    },
    {
      id: 'location',
      sortable: false,
      label: 'Locations',
      align: 'center',
    },
    {
      id: 'region',
      sortable: false,
      label: 'Region',
    },
    {
      id: 'priceLevel',
      sortable: false,
      label: 'Price Level',
    },
  ];

  if (hasRole?.([UserRoleType.SiteAdministrator])) {
    headers.push({
      id: 'externalSyncData',
      sortable: false,
      label: 'Synced',
    });
  }

  return (
    <CommonTable
      orderBy={orderBy}
      headers={headers}
      onHeaderClick={(orderBy) => {
        if (dispatch) {
          dispatch({ type: 'ORDER_BY_CHANGED', data: orderBy });
        }
      }}
      rows={
        <TableBody>
          {organizations?.map((organization: IOrganization) => {
            return (
              <TableRow
                hover
                key={organization.organizationId}
                onClick={() => navigate(`/app/institutions/${organization.organizationId}`)}
                className={styles.row}
              >
                <TableCell>
                  <Box display={'flex'} alignItems={'center'}>
                    <Link
                      underline={'none'}
                      variant={'body1'}
                      href={`/app/institutions/${organization.organizationId}`}
                      onClick={(e) => e.preventDefault()}
                    >
                      {organization.name}
                    </Link>
                  </Box>
                </TableCell>
                <TableCell>
                  {format.formatOrganizationType(organization.organizationType)}
                </TableCell>
                <TableCell align={'center'}>
                  <OrganizationLocationsView
                    organizationId={organization.organizationId}
                    locations={organization.locations ?? []}
                  />
                </TableCell>
                <TableCell>{organization?.locations?.[0]?.region?.name ?? <>&mdash;</>}</TableCell>
                <TableCell>
                  {format.formatPriceLevelType(organization?.priceLevels[0]?.priceLevelType) || (
                    <span>&mdash;</span>
                  )}
                </TableCell>
                {hasRole?.([UserRoleType.SiteAdministrator]) ? (
                  <TableCell>
                    {organization?.externalSyncData?.sourceId ? (
                      <FontAwesomeIcon icon={faCircleCheck} />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                ) : null}
              </TableRow>
            );
          })}
        </TableBody>
      }
      loading={loading}
      paginationConfig={{
        totalCount: totalCaseCount || 0,
        onPaginationChange: (pagination) => {
          dispatch?.({
            type: 'UPDATE_PAGINATION',
            data: {
              pageSize: pagination.rowsPerPage,
              pageNumber: pagination.page,
            },
          });
        },
        rowsPerPage: rowsPerPage || Globals.DefaultListPageSize,
        page: page || 0,
        rowsPerPageOptions: Globals.DefaultListPageSizeOptions,
      }}
    />
  );
};

export default OrganizationListTable;
