import { format } from '@workflow-nx/common';
import { TableRow } from '@mui/material';
import { TableCell } from '../../../../components/TableCell';
import React from 'react';
import { NumberTextField } from '../../../../components/NumberTextField';

export function Form19PartAttributeTableRow(props: {
  value: any;
  valueIndex: number;
  levelIndex: number;
  disabled: boolean;
}) {
  return (
    <TableRow hover>
      <TableCell>{format.formatImplantCharacteristicType(props.value.attribute)}</TableCell>
      <TableCell>
        <NumberTextField
          allowNegative={true}
          decimalPlaces={2}
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].minus01`}
          label={''}
          disabled={props.disabled}
        />
      </TableCell>
      <TableCell>
        <NumberTextField
          allowNegative={true}
          decimalPlaces={2}
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].plan02`}
          label={''}
          disabled={props.disabled}
        />
      </TableCell>
      <TableCell>
        <NumberTextField
          allowNegative={true}
          decimalPlaces={2}
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].plus03`}
          label={''}
          disabled={props.disabled}
        />
      </TableCell>
    </TableRow>
  );
}
