import { useMutation } from '@apollo/client';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  AssetType,
  CaseSpineProfile,
  CaseSpineType,
  CaseType,
  DEFAULT_SPINE_PROFILE,
  LevelType,
  caseUtils,
  format,
} from '@workflow-nx/common';
import { ProgressButton, TextField } from '@workflow-nx/ui';
import { file } from '@workflow-nx/utils';
import { Formik, FormikHelpers } from 'formik';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import CustomDialog from '../../../../components/CustomDialog';
import { LabelledProgressBar } from '../../../../components/LabelledProgressBar';
import { TagDetailsForm } from '../../../../components/TagDetailsForm';
import { CREATE_CASE, DELETE_ASSET, EXPORT_DAISY_SEGMENTATION } from '../../../../gql';
import { canDaisySegmentCase } from '../../../../utils/case';
import { AdditionalCaseDetailsForm } from './AdditionalCaseDetailsForm';
import { BasicCaseDetailsForm } from './BasicCaseDetailsForm';
import {
  ICreateCaseDialogFormValues,
  createCaseDialogFormValues,
  createCaseDialogSchema,
} from './CreateCaseDialog.helpers';
import { InterbodyCaseDetailsForm } from './InterbodyCaseDetailsForm';
import { PatientCaseDetailsForm } from './PatientCaseDetailsForm';
import { StandingXrayCaseDetailsForm } from './StandingXrayCaseDetailsForm';

export function CreateCaseDialog(props: {
  open: boolean;
  onCreate: (caseId: string) => void;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [createCase, { loading: loadingCreateCase }] = useMutation(CREATE_CASE);
  const [exportDaisySegmentation, { loading: loadingExportDaisySegmentation }] =
    useMutation(EXPORT_DAISY_SEGMENTATION);
  const [deleteAsset] = useMutation(DELETE_ASSET);
  const [uploadingDicom, setUploadingDicom] = useState(false);
  const [uploadingLabel, setUploadingLabel] = useState('Uploading file...');
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleSubmitForm = async (
    values: ICreateCaseDialogFormValues,
    { setStatus, setSubmitting, resetForm }: FormikHelpers<ICreateCaseDialogFormValues>,
  ) => {
    try {
      const surgeryDate = values.surgeryDate
        ? DateTime.fromJSDate(values.surgeryDate).toISODate()
        : undefined;
      const receivedAt = DateTime.fromJSDate(values.receivedAt).toISODate();
      const levels = {
        [LevelType.C2C3]: values.levelC2C3 || 'NONE',
        [LevelType.C3C4]: values.levelC3C4 || 'NONE',
        [LevelType.C4C5]: values.levelC4C5 || 'NONE',
        [LevelType.C5C6]: values.levelC5C6 || 'NONE',
        [LevelType.C6C7]: values.levelC6C7 || 'NONE',
        [LevelType.C7T1]: values.levelC7T1 || 'NONE',
        [LevelType.C7C8]: values.levelC7C8 || 'NONE',
        [LevelType.C8T1]: values.levelC8T1 || 'NONE',
        [LevelType.C6T1]: values.levelC6T1 || 'NONE',
        [LevelType.L1L2]: values.levelL1L2 || 'NONE',
        [LevelType.L2L3]: values.levelL2L3 || 'NONE',
        [LevelType.L3L4]: values.levelL3L4 || 'NONE',
        [LevelType.L4L5]: values.levelL4L5 || 'NONE',
        [LevelType.L5S1]: values.levelL5S1 || 'NONE',
        [LevelType.L5L6]: values.levelL5L6 || 'NONE',
        [LevelType.L6S1]: values.levelL6S1 || 'NONE',
        [LevelType.L4S1]: values.levelL4S1 || 'NONE',
      };
      const levelsMetadata = {
        [LevelType.C2C3]: values.levelC2C3Metadata || undefined,
        [LevelType.C3C4]: values.levelC3C4Metadata || undefined,
        [LevelType.C4C5]: values.levelC4C5Metadata || undefined,
        [LevelType.C5C6]: values.levelC5C6Metadata || undefined,
        [LevelType.C6C7]: values.levelC6C7Metadata || undefined,
        [LevelType.C7T1]: values.levelC7T1Metadata || undefined,
        [LevelType.C7C8]: values.levelC7C8Metadata || undefined,
        [LevelType.C8T1]: values.levelC8T1Metadata || undefined,
        [LevelType.C6T1]: values.levelC6T1Metadata || undefined,
        [LevelType.L1L2]: values.levelL1L2Metadata || undefined,
        [LevelType.L2L3]: values.levelL2L3Metadata || undefined,
        [LevelType.L3L4]: values.levelL3L4Metadata || undefined,
        [LevelType.L4L5]: values.levelL4L5Metadata || undefined,
        [LevelType.L5S1]: values.levelL5S1Metadata || undefined,
        [LevelType.L5L6]: values.levelL5L6Metadata || undefined,
        [LevelType.L6S1]: values.levelL6S1Metadata || undefined,
        [LevelType.L4S1]: values.levelL4S1Metadata || undefined,
      };

      const dicomTypes = [
        {
          file: values.dicomXray,
          metadata: values.dicomXrayMetadata,
          assetType: AssetType.DicomXray,
        },
        { file: values.dicomCt, metadata: values.dicomCtMetadata, assetType: AssetType.DicomCt },
        {
          file: values.dicomMri,
          metadata: values.dicomMriMetadata,
          assetType: AssetType.DicomMri,
        },
      ];
      const assets: { fileName: string; size: number; assetType: AssetType; metadata: any }[] = [];
      dicomTypes.forEach((dicom) => {
        if (dicom.file && dicom.metadata) {
          assets.push({
            fileName: dicom.file.name,
            size: dicom.file.size,
            assetType: dicom.assetType,
            metadata: dicom.metadata,
          });
        }
      });

      const patientBirthDate = DateTime.fromJSDate(values.birthDate as Date).toISODate();

      const caseTags = values.tags ?? [];

      const containsCtDicom = assets.some((dicom) => dicom.assetType === AssetType.DicomCt);
      if (values.caseType === CaseType.Live) {
        let missingFields = false;
        if (!values.surgeonId) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases require a surgeon
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }
        if (!values.assignedId) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases require a designer
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }
        if (!values.locationId) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases require a location
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }

        const validLevels = Object.values(levels)?.find((level) =>
          caseUtils.isValidLevelPartType(level),
        );
        if (!validLevels) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases require at least one valid level part type
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }

        if (!containsCtDicom) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases need a DICOM CT - otherwise set the case type to <b>DRAFT</b>
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }
        if (missingFields) {
          return;
        }
      }
      const { data: createCaseData } = await createCase({
        variables: {
          approach: values.approach === '' ? null : values.approach,
          assignedId: values.assignedId ? Number(values.assignedId) : undefined,
          fieldRepId: values.fieldRepId ? Number(values.fieldRepId) : undefined,
          levels,
          levelsMetadata,
          caseType: values.caseType ?? CaseType.Live,
          receivedAt,
          spineType: values.spineType || CaseSpineType.Lumbar,
          spineProfile: values.spineProfile || DEFAULT_SPINE_PROFILE,
          surgeryDate: surgeryDate || null,
          isSurgeryDateTentative: values.isSurgeryDateTentative,
          surgeonId: values.surgeonId ? Number(values.surgeonId) : undefined,
          locationId: values.locationId ? Number(values.locationId) : undefined,
          pelvicIncidence: values.pelvicIncidence ? Number(values.pelvicIncidence) : undefined,
          slopeOfLineOfSight: values.slopeOfLineOfSight
            ? Number(values.slopeOfLineOfSight)
            : undefined,
          lumbarLordosis: values.lumbarLordosis ? Number(values.lumbarLordosis) : undefined,
          lumbarCoronalCobb: values.lumbarCoronalCobb
            ? Number(values.lumbarCoronalCobb)
            : undefined,
          sagittalVerticalAxis: values.sagittalVerticalAxis
            ? Number(values.sagittalVerticalAxis)
            : undefined,
          coronalBalance: values.coronalBalance ? Number(values.coronalBalance) : undefined,
          pso: values.pso ?? undefined,
          pco: values.pco ?? undefined,
          uiv: values.uiv,
          liv: values.liv,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          gender: values?.gender && values?.gender?.length > 0 ? values.gender : undefined,
          birthDate: patientBirthDate,
          mrn: values.mrn,
          assets,
          comment: values.comment,
          tags: caseTags.map((tag) => tag.key),
          excludedInstruments: values.excludedInstruments,
        },
      });

      const { case: activeCase, signedUrls } = createCaseData.createCase;

      if (signedUrls) {
        for (const signedUrl of signedUrls) {
          setUploadProgress(0);
          setUploadingDicom(true);
          setUploadingLabel(`Uploading ${format.formatAssetType(signedUrl.assetType)} file...`);

          let data;
          switch (signedUrl.assetType) {
            case AssetType.DicomCt:
              data = values.dicomCt;
              break;
            case AssetType.DicomXray:
              data = values.dicomXray;
              break;
            case AssetType.DicomMri:
              data = values.dicomMri;
              break;
            default:
              throw new Error(`Invalid signed URL asset Type ${signedUrl.assetType}`);
          }
          try {
            await file.uploadFile(signedUrl.signedUrl, data, (percentComplete: number) => {
              setUploadProgress(percentComplete);
            });
          } catch (e) {
            await deleteAsset({
              variables: {
                assetId: signedUrl?.assetId,
              },
            });
            enqueueSnackbar(`Upload failed for asset: ${signedUrl.assetType}`, {
              variant: 'error',
            });
            console.error(e);
          }

          // if the DICOM CT has been uploaded, also send it off for segmentation
          if (signedUrl.assetType === AssetType.DicomCt) {
            try {
              const daisySegmentationAllowed = canDaisySegmentCase(caseTags);

              if (daisySegmentationAllowed)
                await exportDaisySegmentation({
                  variables: {
                    caseId: activeCase.caseId,
                  },
                });
            } catch (e) {
              console.error('An error occurred exporting the case to DaiSy', e);
            }
          }
        }
      }

      setStatus({ success: true });
      enqueueSnackbar(`Case ${activeCase.number} created`, {
        variant: 'success',
      });

      props.onCreate(activeCase.caseId);
      resetForm();
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred creating the case', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
      setUploadProgress(0);
      setUploadingDicom(false);
    }
  };

  return (
    <Formik
      initialValues={createCaseDialogFormValues()}
      validationSchema={createCaseDialogSchema}
      onSubmit={handleSubmitForm}
    >
      {({ resetForm, submitForm, isSubmitting, values }) => {
        const loading = loadingCreateCase || uploadingDicom || loadingExportDaisySegmentation;
        const disabled = isSubmitting || loading;

        return (
          <CustomDialog
            maxWidth={'lg'}
            open={props.open}
            title={'Create Case'}
            onClose={() => {
              resetForm();
              props.onClose();
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={disabled}
                onClick={() => submitForm()}
                label={'Create'}
                loading={disabled}
              />,
            ]}
          >
            <form>
              {uploadingDicom ? (
                <Box mb={2}>
                  <LabelledProgressBar
                    label={uploadingLabel}
                    variant={'determinate'}
                    value={uploadProgress}
                    fullWidth={true}
                  />
                </Box>
              ) : null}
              <BasicCaseDetailsForm
                loading={loading}
                disabled={disabled}
                onSurgeonChange={(surgeon) => {
                  // const approach = surgeon?.preferencesOld?.mismatchCorrectionGoalType;
                  // setFieldValue('approach', approach);
                }}
              />
              <Box my={2}>
                <TagDetailsForm />
              </Box>
              <Box my={2}>
                <Typography variant={'h5'}>Patient Details</Typography>
              </Box>
              <PatientCaseDetailsForm loading={disabled} disabled={loading} />
              <Box my={2}>
                <Typography variant={'h5'}>X-Ray Case Details</Typography>
              </Box>
              <StandingXrayCaseDetailsForm
                caseSpineType={values?.spineType ?? CaseSpineType.Lumbar}
                disabled={loading}
              />

              <Box my={2}>
                <Typography variant={'h5'}>Case Details</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Box display={'flex'}>
                    <InterbodyCaseDetailsForm
                      caseSpineProfile={values.spineProfile || DEFAULT_SPINE_PROFILE}
                      disabled={disabled}
                    />
                    <Box>
                      <Divider orientation={'vertical'} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <AdditionalCaseDetailsForm
                    caseSpineType={values.spineType ?? CaseSpineType.Lumbar}
                    caseSpineProfile={values.spineProfile ?? CaseSpineProfile.LumbarStandard}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name={'comment'}
                    label={'Case Notes'}
                    shrink
                    multiline={true}
                    multiLineRows={12}
                    fullHeight={true}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </form>
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
