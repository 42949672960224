import React, { useEffect, useReducer, useState } from 'react';
import {
  CloudDesignStatus,
  format,
  IAsset,
  LEVEL_CONFIG_MAP,
  ValidFileExtensions,
  LevelType,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation } from '@apollo/client';
import { EXPORT_CLOUD_DESIGN, FIND_ASSETS } from '../../../../gql';
import { ProgressButton } from '@workflow-nx/ui';
import CustomDialog from '../../../../components/CustomDialog';
import { AssetGridTable } from '../../../../components/AssetGridTable/AssetGridTable';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { CloudDesignStatusView } from './CloudDesignStatusView';

function getValidNTopCliAssets(levelType: LevelType, excludePlusSize: boolean) {
  let validNTopCliAssets = LEVEL_CONFIG_MAP[levelType]
    ? Object.values(LEVEL_CONFIG_MAP[levelType].nTopAssets)
    : [];
  if (excludePlusSize) {
    validNTopCliAssets = validNTopCliAssets.filter((asset) => !asset.includes('_PLUS'));
  }
  return validNTopCliAssets;
}

export function ExportCloudDesignAssetsButton(props: {
  caseId: number;
  planId: number;
  levelType: LevelType;
  disabled: boolean;
  isPlusSizeExcluded: boolean;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [exportCloudDesign, { loading: loadingExportCloudDesign }] =
    useMutation(EXPORT_CLOUD_DESIGN);
  const validCliAssets = getValidNTopCliAssets(props.levelType, props.isPlusSizeExcluded);
  const [findAssets, { loading: loadingAssets }] = useLazyQuery(FIND_ASSETS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAssets(data.assets);
      const requiredAssets = data.assets.filter((asset: IAsset) =>
        validCliAssets.includes(asset.assetType),
      ).length;
      setHasAllRequiredAssets(requiredAssets > 0);
    },
  });
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [hasAllRequiredAssets, setHasAllRequiredAssets] = useState(false);
  const [showExportCloudDesignDialog, setShowExportCloudDesignDialog] = useState(false);
  const [cloudDesignStatus, setCloudDesignStatus] = useState<CloudDesignStatus>(
    CloudDesignStatus.None,
  );

  const handleExportAssetsClick = async () => {
    try {
      await exportCloudDesign({
        variables: {
          caseId: props.caseId,
          input: {
            level: props.levelType,
            planId: props.planId,
          },
        },
      });

      enqueueSnackbar(`The level has been sent for cloud processing`, { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred exporting the assets', { variant: 'error' });
    }
  };

  const [, dispatch] = useReducer((state: any, action: any): any => {
    let updatedState = state;

    switch (action.type) {
      case 'ASSET_DELETED':
      case 'ASSET_UPLOADED': {
        findAssets({
          variables: {
            caseId: props.caseId,
            planId: props.planId,
            assetTypeFilter: validCliAssets,
          },
        });
      }
    }
    return updatedState;
  }, {});

  useEffect(() => {
    if (showExportCloudDesignDialog) {
      findAssets({
        variables: {
          caseId: props.caseId,
          planId: props.planId,
          assetTypeFilter: validCliAssets,
        },
      });
    }
  }, [showExportCloudDesignDialog]);

  return (
    <>
      <ProgressButton
        variant={'outlined'}
        onClick={() => setShowExportCloudDesignDialog(true)}
        disabled={props.disabled}
        label={'Send To Cloud Design'}
        loading={loadingExportCloudDesign}
      />
      <CustomDialog
        maxWidth={'md'}
        title={`Exporting Cloud Design Assets for ${format.formatLevelType(props.levelType)}`}
        open={showExportCloudDesignDialog}
        onClose={() => {
          setShowExportCloudDesignDialog(false);
        }}
        positiveActionButtons={[
          <Button
            disabled={loadingExportCloudDesign}
            onClick={() => {
              setShowExportCloudDesignDialog(false);
            }}
          >
            Close
          </Button>,
          <ProgressButton
            disabled={!hasAllRequiredAssets || loadingAssets || loadingExportCloudDesign}
            onClick={handleExportAssetsClick}
            loading={false}
            label={'Export Cloud Design Assets'}
          />,
        ]}
      >
        {loadingExportCloudDesign ? (
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
            <CircularProgress size={50} variant={'indeterminate'} />
            <Box mt={1} />
            <Typography variant={'body1'}>Exporting Cloud Design Assets...</Typography>
          </Box>
        ) : null}
        {!loadingExportCloudDesign ? (
          <>
            <AssetGridTable
              dispatch={dispatch}
              caseId={props.caseId}
              planId={props.planId}
              assets={assets || []}
              validFileExtensions={[ValidFileExtensions.TXT]}
              validAssets={validCliAssets}
              readOnly={cloudDesignStatus === CloudDesignStatus.Processing || props.disabled}
            />

            <CloudDesignStatusView
              caseId={props.caseId}
              planId={props.planId}
              levelType={props.levelType}
              disabled={!props.disabled}
              onStatusChange={(status) => setCloudDesignStatus(status)}
            />
          </>
        ) : null}
      </CustomDialog>
    </>
  );
}
