import { ImplantType, InterbodyScrewLengthTypes, LevelSize, LevelType, PartType } from '../enums';
import {
  ILevels,
  IPlan,
  IPlanExcludedImplantSizes,
  ISurgeonPreferenceImplant,
} from '../interfaces';

import { caseUtils, format } from '@workflow-nx/common';

export const getScrewLength = (screwLength: InterbodyScrewLengthTypes): number | undefined => {
  switch (screwLength) {
    case InterbodyScrewLengthTypes.AlifXScrewLength20mm:
      return 20;
    case InterbodyScrewLengthTypes.AlifXScrewLength25mm:
      return 25;
    case InterbodyScrewLengthTypes.AlifXScrewLength30mm:
      return 30;
    default:
      return undefined;
  }
};

export const getLevelImplant = (level: LevelType, partType: PartType, plan: IPlan) => {
  const planImplant = plan?.implants?.find((implant) => implant.level === level);

  return {
    part: planImplant?.partType,
    ap: planImplant?.ap,
    ml: planImplant?.ml,
    screwLength: [PartType.ALIF_X_TWO_UP, PartType.ALIF_X_TWO_DOWN].includes(
      planImplant?.partType as PartType,
    )
      ? planImplant?.screwLength
      : undefined,
    excludedImplantSizes: planImplant?.excludedImplantSizes,
  };
};

export const getExcludedLevelsImplants = (
  caseLevels: ILevels,
  plan: IPlan,
): IPlanExcludedImplantSizes[] => {
  const excludedImplantSizes: IPlanExcludedImplantSizes[] = [];
  const validCaseLevels = caseUtils.getValidCaseLevels(caseLevels) as LevelType[];

  for (const level of validCaseLevels) {
    const partType = caseLevels[level] as PartType;
    const ImplantType = caseUtils.getImplantType(partType);
    const levelImplant = getLevelImplant(level, partType, plan);
    excludedImplantSizes.push({
      implantType: ImplantType,
      level: level,
      partType: partType,
      plusSizeExcluded: levelImplant?.excludedImplantSizes?.includes(LevelSize.Plus) ?? false,
      minusSizeExcluded: levelImplant?.excludedImplantSizes?.includes(LevelSize.Minus) ?? false,
    });
  }

  return excludedImplantSizes;
};

export const getFormattedExcludedImplants = (
  excludedImplantSizes: IPlanExcludedImplantSizes[],
): string => {
  const formattedExcludedImplants: string[] = [];

  for (const excludedImplant of excludedImplantSizes) {
    if (excludedImplant.plusSizeExcluded) {
      const formattedImplant = `${format.formatLevelType(
        excludedImplant.level,
      )} - ${format.formatPartType(excludedImplant.partType)}`;
      formattedExcludedImplants.push(formattedImplant);
    }
  }
  let finalString = '';
  if (formattedExcludedImplants.length > 0) {
    finalString = formattedExcludedImplants.join(', ');

    if (formattedExcludedImplants.length > 1) {
      finalString = finalString.replace(/,([^,]*)$/, ', and$1');
    }
    finalString = `${finalString} plus-size implants were excluded.`;
  }

  return finalString;
};

export const extractAllowedPlusSizeExcluded = (
  surgeonPreferenceImplants: ISurgeonPreferenceImplant[],
  foundCaseImplantTypes: ImplantType[],
): ImplantType[] => {
  return surgeonPreferenceImplants
    .filter(
      (surgeonPreferenceImplant) =>
        foundCaseImplantTypes.includes(surgeonPreferenceImplant.implantType) &&
        surgeonPreferenceImplant.excludedImplantSizes?.includes(LevelSize.Plus),
    )
    .map((surgeonPreferenceImplant) => surgeonPreferenceImplant.implantType);
};

export function checkPlusSizeExcluded(
  levelType: LevelType,
  excludedLevelsImplants: IPlanExcludedImplantSizes[],
): boolean {
  const excludedImplant = excludedLevelsImplants.find(
    (implant) => implant.level === levelType && implant.plusSizeExcluded,
  );

  return !!excludedImplant;
}

export function getValidCaseExcludedImplants(
  allowedPlusSizeExcludedImplantTypes: ImplantType[],
  excludedLevelsImplants: IPlanExcludedImplantSizes[],
): IPlanExcludedImplantSizes[] {
  return excludedLevelsImplants.filter((implant) => {
    const isImplantTypeAllowed = allowedPlusSizeExcludedImplantTypes.includes(implant.implantType);
    const hasImplantExclusion = implant.plusSizeExcluded || implant.minusSizeExcluded;
    return isImplantTypeAllowed && hasImplantExclusion;
  });
}

export function determineImplantExcludeLoopBounds(
  caseExcludedImplant?: IPlanExcludedImplantSizes,
): [number, number] {
  let start = 1;
  let end = 3;

  if (caseExcludedImplant) {
    if (caseExcludedImplant.plusSizeExcluded && caseExcludedImplant.minusSizeExcluded) {
      start = 2;
      end = 2;
    } else if (caseExcludedImplant.plusSizeExcluded) {
      end = 2;
    } else if (caseExcludedImplant.minusSizeExcluded) {
      start = 2;
    }
  }

  return [start, end];
}

export function checkPlusSizeImplantExcludedForLevel(
  caseLevel: LevelType,
  validCaseExcludedImplants?: IPlanExcludedImplantSizes[],
): boolean {
  if (!validCaseExcludedImplants) {
    return false;
  }

  return !!validCaseExcludedImplants?.some(
    (implant) => implant.level === caseLevel && implant.plusSizeExcluded,
  );
}
