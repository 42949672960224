import { caseUtils, format, ICaseStudyDate } from '@workflow-nx/common';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { AlertColor } from '@mui/material/Alert/Alert';

export function CaseStudyDateExpirationView(props: { studyDate?: ICaseStudyDate }) {
  if (!props.studyDate) return;

  const expiryStatus = caseUtils.getStudyDateExpiryStatus(props.studyDate);

  let severity: AlertColor = 'info';
  let studyDateExpiryTitle = 'Case Study Date';
  let studyDateExpiryMessage = `The study date expiry is ${
    props.studyDate.expiryDate
  }, based on the ${format.formatAssetType(props.studyDate.assetType)}'s study date of ${
    props.studyDate.studyDate
  } (${props.studyDate.daysOld} days old).`;

  switch (expiryStatus) {
    case 'EXPIRED':
      studyDateExpiryMessage = `WARNING: The ${
        props.studyDate.isExpiryDateSetManually
          ? 'manually edited study date'
          : format.formatAssetType(props.studyDate.assetType)
      }, with a study date of ${props.studyDate.studyDate}, expired on ${
        props.studyDate.expiryDate
      }.`;
      severity = 'error';
      studyDateExpiryTitle = 'Case Study Has Expired!';

      break;
    case 'NEAR_EXPIRY':
      studyDateExpiryMessage = `WARNING: The study date expiry is ${
        props.studyDate.expiryDate
      }, based on the ${format.formatAssetType(props.studyDate.assetType)}'s study date of ${
        props.studyDate.studyDate
      } (${props.studyDate.daysOld} days old).`;
      severity = 'warning';
      studyDateExpiryTitle = 'Case Study Is About To Expire!';
      break;
  }

  return (
    <Alert severity={severity}>
      <AlertTitle>{studyDateExpiryTitle}</AlertTitle>
      {studyDateExpiryMessage}
    </Alert>
  );
}
