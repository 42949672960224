import React from 'react';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import { planUtils, IPlan, ILevels } from '@workflow-nx/common';

export function PlusSizeExclusionAlert(props: {
  levels: ILevels;
  plan: IPlan | undefined;
  isAllowedPlusSizeExcludedImplantTypes: boolean;
}) {
  const excludedLevelsImplants = planUtils.getExcludedLevelsImplants(
    props.levels,
    props.plan as IPlan,
  );
  const formattedExcludedImplants = planUtils.getFormattedExcludedImplants(excludedLevelsImplants);
  const shouldRenderPlusSizeExclusionAlert = !!(
    props.isAllowedPlusSizeExcludedImplantTypes && formattedExcludedImplants
  );

  return shouldRenderPlusSizeExclusionAlert ? (
    <Box my={1}>
      <Alert severity="warning">{formattedExcludedImplants}</Alert>
    </Box>
  ) : null;
}
