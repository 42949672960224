import {
  AssetType,
  caseUtils,
  planUtils,
  Form19Angles,
  Form19Error,
  Form19ErrorCodeType,
  Form19ManualAttributes,
  Form19PartAttributes,
  format,
  ICase,
  ICaseImplantSpecification,
  ILevels,
  ImplantCharacteristicType,
  ImplantDirection,
  ImplantOrientation,
  ImplantType,
  LEVEL_CONFIG_MAP,
  LevelNotes,
  LevelAdditionalImages,
  LevelSize,
  LevelType,
  PartType,
  Result,
  IAsset,
  EncodedAdditionalImageType,
  CaseSpineProfile,
  AlifXLevelScrewLengthType,
  CaseRiskAssessmentType,
  LevelRevision,
  IForm20Product,
  KitCartonRuleType,
  IKitCarton,
  IKitItem,
  IKit,
  Form20ProductStatusType,
  IPlanExcludedImplantSizes,
  IKitBom,
} from '@workflow-nx/common';
import * as math from '@workflow-nx/math';
import config from '../extras/config';
import { round, toNumber, sortBy } from 'lodash';
import { date } from '@workflow-nx/utils';
import { isTlifCOrientationAvailable } from './featureFlags';

const formatSettingDataKey = (name: ImplantType): string => {
  return name.toLowerCase().replace('_', '');
};

export interface ILimits {
  max: number;
  min: number;
  fixedValue?: number;
}

export interface ISettingsForm19ImplantAttributes {
  ap: ILimits;
  ml: ILimits;
  cageHeight: ILimits;
  cageTaper: ILimits;
  bulletAngle: ILimits;
  coronalAngle: ILimits;
  lordoticAngle: ILimits;
  bulletHeight: ILimits;
  bulletUpperHeight: ILimits;
  bulletTaperHeight: ILimits;
  patientContactLowerHeight: ILimits;
  patientContactUpperHeight: ILimits;
  patientContactLength: ILimits;
}

export interface ISettingsForm19ImplantHeader {
  version: string;
  drawingNumber: string;
}

export interface ISettingsForm19InterbodyMeasurementsAttributes {
  lordoticAngle: ILimits;
  coronalAngle: ILimits;
  anteriorHeight: ILimits;
  posteriorHeight: ILimits;
  anteriorHeightPreop: ILimits;
}

export interface ISettingsForm19Implant {
  header: ISettingsForm19ImplantHeader;
  attributes: ISettingsForm19ImplantAttributes;
}

export interface ISettingsForm19InterbodyMeasurements {
  attributes: ISettingsForm19InterbodyMeasurementsAttributes;
}

export interface ISettingsForm19 {
  acdf: ISettingsForm19Implant;
  acdfx: ISettingsForm19Implant;
  alif: ISettingsForm19Implant;
  alifx: ISettingsForm19Implant;
  llif: ISettingsForm19Implant;
  tlifo: ISettingsForm19Implant;
  tlifc: ISettingsForm19Implant;
  tlifca: ISettingsForm19Implant;
  interbodyMeasurements: ISettingsForm19InterbodyMeasurements;
}

export interface ILevelsHeaderData {
  levelType: LevelType;
  partType: PartType;
  levelCode: string;
  implantLevelType: ImplantType;
  direction: string;
  implantNameDimFile: string;
  implantNamePart: string;
  orientation?: ImplantOrientation | null;
}
export type LevelDataRow = {
  levelCode?: string;
  level?: LevelType;
  characteristic: ImplantCharacteristicType;
  minus01?: number | string;
  plan02?: number | string;
  plus03?: number | string;
  min?: number | string;
  max?: number | string;
  result?: string | undefined;
};

interface AttributeMinMaxValues {
  min: number | undefined;
  max: number | undefined;
  fixedValue?: number | undefined;
}

export type Form19RevisionHistory = {
  revision?: number | string;
  lot?: string;
  description?: string;
};

export type LevelFileRevisionHistory = {
  partNumber?: string;
  fileName?: string;
  revision?: number | string;
  description?: string;
};

export type ImplantsData = {
  form19SettingsData: any;
  caseLevel: any;
  partAttributes?: Form19PartAttributes[];
  angles?: Form19Angles[];
  levelRevisions?: LevelRevision[];
  spineProfile?: CaseSpineProfile;
};

export enum ImplantCombinationType {
  None = 0,
  AlifOrLlif = 1,
  TlifC = 2,
  TlifO = 4,
  AllThree = 7,
  TlifCTlifO = 6,
  TlifCWithAlifOrLlif = 3,
  TlifOWithAlifOrLlif = 5,
}

export type InstrumentsByKit = {
  kit1: string[];
  kit2: string[];
  kit3: string[];
};

export type ImplantTypeCountByKit = {
  kit1: number;
  kit2: number;
  kit3: number;
};

export type CountLevelsByImplant = {
  alifLevelCount: number;
  alifXLevelCount: number;
  llifLevelCount: number;
  tlifCLevelCount: number;
  tlifOLevelCount: number;
  nonAlifXLevelCount: number;
  totalLevelCount: number;
};

export type ProductDates = {
  kitCartonStartDate?: Date | string | null;
  m4lStartDate?: Date | string | null;
  tlifCM4lStartDate?: Date | string | null;
  tlifCOrientationStartDate?: Date | string | null;
  alifXRotationLockStartDate?: Date | string | null;
};

const ROUND_TO_DECIMALS = 1;
const ROUND_DIFFERENCE_TO_DECIMALS = 1;
const MAX_HEIGHT_C_MINUS_UPPER_DIFF = 1.3;
const MAX_HEIGHT_C_MINUS_LOWER_DIFF = 0.7;
const MAX_HEIGHT_C_PLUS_UPPER_DIFF_SIZE_1 = 1.3;
const MAX_HEIGHT_C_PLUS_LOWER_DIFF_SIZE_1 = 0.7;
const MAX_HEIGHT_C_PLUS_UPPER_DIFF_SIZE_2 = 2.3;
const MAX_HEIGHT_C_PLUS_LOWER_DIFF_SIZE_2 = 1.7;
let MAX_HEIGHT_C_PLUS_UPPER_DIFF = 2.3;
let MAX_HEIGHT_C_PLUS_LOWER_DIFF = 1.7;
const PRESENT = 'Present';
const KIT_NAME = 'Kit Carton';
const KIT_WEIGHT = 200;
const INSTRUMENT_1034 = '1034';
const INSTRUMENT_1045 = '1045';
const INSTRUMENT_1050 = '1050';
const INSTRUMENT_1060 = '1060';
const INSTRUMENT_1080 = '1080';
const INSTRUMENT_1090 = '1090';

const CASE_RISK_ASSESSMENT_MATRIX = [
  PartType.ALIF,
  PartType.LLIF_LEFT,
  PartType.LLIF_RIGHT,
  PartType.TLIFO_LEFT,
  PartType.TLIFO_RIGHT,
];

export function findCaseRiskAssessment(levels: ILevels): CaseRiskAssessmentType {
  const caseLevelsTypes = caseUtils.getValidCaseLevelsTypes(levels);
  let caseRiskAssessment = CaseRiskAssessmentType.CaseReviewQaReview;

  if (caseLevelsTypes?.length === 1) {
    const partType = levels[caseLevelsTypes[0] as string] as PartType;
    if (CASE_RISK_ASSESSMENT_MATRIX.includes(partType)) {
      caseRiskAssessment = CaseRiskAssessmentType.QaReviewOnly;
    }
  }
  return caseRiskAssessment;
}

export function getValidDimAssets(levelTypes: LevelType[]) {
  let txtLevelsAssetTypes: AssetType[] = [];

  for (const levelType of levelTypes) {
    const levelConfig = LEVEL_CONFIG_MAP[levelType];
    const txtAssetTypes: AssetType[] = levelConfig
      ? Object.values(levelConfig.implantAssets.dimensions)
      : [];
    txtLevelsAssetTypes = [...txtLevelsAssetTypes, ...txtAssetTypes];
  }
  return txtLevelsAssetTypes;
}

export function getValidImplantAssets(levelTypes: LevelType[]) {
  let stlLevelsAssetTypes: AssetType[] = [];

  for (const levelType of levelTypes) {
    const levelConfig = LEVEL_CONFIG_MAP[levelType];
    const stlAssetTypes: AssetType[] = levelConfig
      ? Object.values(levelConfig.implantAssets.stl)
      : [];
    stlLevelsAssetTypes = [...stlLevelsAssetTypes, ...stlAssetTypes];
  }
  return stlLevelsAssetTypes;
}

export function getValidForm19AdditionalImageAssets(levelTypes: LevelType[]) {
  let additionalImageLevelsAssetTypes: AssetType[] = [];

  for (const levelType of levelTypes) {
    const levelConfig = LEVEL_CONFIG_MAP[levelType];
    if (levelConfig?.form19AdditionalImageAsset) {
      const additionalImageAssetTypes: AssetType = levelConfig?.form19AdditionalImageAsset;
      additionalImageLevelsAssetTypes = [
        ...additionalImageLevelsAssetTypes,
        additionalImageAssetTypes,
      ];
    }
  }

  return additionalImageLevelsAssetTypes;
}

export const readDimTextFile = async (blob: Blob) => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsText(blob);
  });
};

export const convertKeyValuePairToJson = (textData: any) => {
  const textLines = (textData as string).split(/\r?\n/);
  const textToJson: any = {};
  if (textLines.length) {
    for (const textLine of textLines) {
      if (textLine.includes('=')) {
        const [key, val] = textLine.split('=');
        textToJson[key] = toNumber(val);
      }
    }
  }
  return textToJson;
};

export function getAlifPartAttributeMinMaxValues(
  attribute: ImplantCharacteristicType,
  alif: any,
): AttributeMinMaxValues {
  const attributeMinMaxValues: AttributeMinMaxValues = {
    min: undefined,
    max: undefined,
    fixedValue: undefined,
  };

  switch (attribute) {
    case ImplantCharacteristicType.ApDepthB:
      attributeMinMaxValues.min = alif?.attributes?.ap?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = alif?.attributes?.ap?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MlWidthA:
      attributeMinMaxValues.min = alif?.attributes?.ml?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = alif?.attributes?.ml?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MaxHeightC:
      attributeMinMaxValues.min = alif?.attributes?.cageHeight?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = alif?.attributes?.cageHeight?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.BulletAngle:
      attributeMinMaxValues.min = alif?.attributes?.bulletAngle?.min;
      attributeMinMaxValues.max = alif?.attributes?.bulletAngle?.max;
      break;
    case ImplantCharacteristicType.BulletHeight:
      attributeMinMaxValues.min = alif?.attributes?.bulletHeight?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = alif?.attributes?.bulletHeight?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.LordoticAngle:
      attributeMinMaxValues.min = alif?.attributes?.lordoticAngle?.min;
      attributeMinMaxValues.max = alif?.attributes?.lordoticAngle?.max;
      break;
    case ImplantCharacteristicType.CoronalAngle:
      attributeMinMaxValues.min = alif?.attributes?.coronalAngle?.min;
      attributeMinMaxValues.max = alif?.attributes?.coronalAngle?.max;
      break;
  }

  return attributeMinMaxValues;
}

export function getAlifXPartAttributeMinMaxValues(
  attribute: ImplantCharacteristicType,
  alifx: any,
): AttributeMinMaxValues {
  const attributeMinMaxValues: AttributeMinMaxValues = {
    min: undefined,
    max: undefined,
    fixedValue: undefined,
  };

  switch (attribute) {
    case ImplantCharacteristicType.ApDepthB:
      attributeMinMaxValues.min = alifx?.attributes?.ap?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = alifx?.attributes?.ap?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MlWidthA:
      attributeMinMaxValues.min = alifx?.attributes?.ml?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = alifx?.attributes?.ml?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MaxHeightC:
      attributeMinMaxValues.min = alifx?.attributes?.cageHeight?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = alifx?.attributes?.cageHeight?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.BulletAngle:
      attributeMinMaxValues.min = alifx?.attributes?.bulletAngle?.min;
      attributeMinMaxValues.max = alifx?.attributes?.bulletAngle?.max;
      break;
    case ImplantCharacteristicType.BulletHeight:
      attributeMinMaxValues.min = alifx?.attributes?.bulletHeight?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = alifx?.attributes?.bulletHeight?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.LordoticAngle:
      attributeMinMaxValues.min = alifx?.attributes?.lordoticAngle?.min;
      attributeMinMaxValues.max = alifx?.attributes?.lordoticAngle?.max;
      break;
    case ImplantCharacteristicType.CoronalAngle:
      attributeMinMaxValues.min = alifx?.attributes?.coronalAngle?.min;
      attributeMinMaxValues.max = alifx?.attributes?.coronalAngle?.max;
      break;
  }

  return attributeMinMaxValues;
}

export function getLlifPartAttributeMinMaxValues(
  attribute: ImplantCharacteristicType,
  llif: any,
): AttributeMinMaxValues {
  const attributeMinMaxValues: AttributeMinMaxValues = {
    min: undefined,
    max: undefined,
    fixedValue: undefined,
  };

  switch (attribute) {
    case ImplantCharacteristicType.ApDepthB:
      attributeMinMaxValues.min = llif?.attributes?.ap?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = llif?.attributes?.ap?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MlWidthA:
      attributeMinMaxValues.min = llif?.attributes?.ml?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = llif?.attributes?.ml?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MaxHeightC:
      attributeMinMaxValues.min = llif?.attributes?.cageHeight?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = llif?.attributes?.cageHeight?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.BulletAngle:
    case ImplantCharacteristicType.RightBulletAngle:
    case ImplantCharacteristicType.LeftBulletAngle:
      attributeMinMaxValues.min = llif?.attributes?.bulletAngle?.min;
      attributeMinMaxValues.max = llif?.attributes?.bulletAngle?.max;
      break;
    case ImplantCharacteristicType.BulletHeight:
    case ImplantCharacteristicType.RightBulletHeight:
    case ImplantCharacteristicType.LeftBulletHeight:
      attributeMinMaxValues.min = llif?.attributes?.bulletHeight?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = llif?.attributes?.bulletHeight?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.LordoticAngle:
      attributeMinMaxValues.min = llif?.attributes?.lordoticAngle?.min;
      attributeMinMaxValues.max = llif?.attributes?.lordoticAngle?.max;
      break;
    case ImplantCharacteristicType.CoronalAngle:
      attributeMinMaxValues.min = llif?.attributes?.coronalAngle?.min;
      attributeMinMaxValues.max = llif?.attributes?.coronalAngle?.max;
      break;
  }
  return attributeMinMaxValues;
}

export function getTlifOPartAttributeMinMaxValues(
  attribute: ImplantCharacteristicType,
  tlifo: any,
): AttributeMinMaxValues {
  const attributeMinMaxValues: AttributeMinMaxValues = {
    min: undefined,
    max: undefined,
    fixedValue: undefined,
  };

  switch (attribute) {
    case ImplantCharacteristicType.ApDepthB:
      attributeMinMaxValues.min = tlifo?.attributes?.ap?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = tlifo?.attributes?.ap?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MlWidthA:
      attributeMinMaxValues.min = tlifo?.attributes?.ml?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = tlifo?.attributes?.ml?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MaxHeightC:
      attributeMinMaxValues.min = tlifo?.attributes?.cageHeight?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = tlifo?.attributes?.cageHeight?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.BulletAngle:
    case ImplantCharacteristicType.RightBulletAngle:
    case ImplantCharacteristicType.LeftBulletAngle:
      attributeMinMaxValues.min = tlifo?.attributes?.bulletAngle?.min;
      attributeMinMaxValues.max = tlifo?.attributes?.bulletAngle?.max;
      attributeMinMaxValues.fixedValue = tlifo?.attributes?.bulletAngle?.fixedValue;
      break;
    case ImplantCharacteristicType.LordoticAngle:
      attributeMinMaxValues.min = tlifo?.attributes?.lordoticAngle?.min;
      attributeMinMaxValues.max = tlifo?.attributes?.lordoticAngle?.max;
      break;
    case ImplantCharacteristicType.CoronalAngle:
      attributeMinMaxValues.min = tlifo?.attributes?.coronalAngle?.min;
      attributeMinMaxValues.max = tlifo?.attributes?.coronalAngle?.max;
      break;
  }

  return attributeMinMaxValues;
}

export function getTlifCPartAttributeMinMaxValues(
  attribute: ImplantCharacteristicType,
  tlifc: any,
): AttributeMinMaxValues {
  const attributeMinMaxValues: AttributeMinMaxValues = {
    min: undefined,
    max: undefined,
    fixedValue: undefined,
  };

  switch (attribute) {
    case ImplantCharacteristicType.ApDepthB:
      attributeMinMaxValues.min = tlifc?.attributes?.ap?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = tlifc?.attributes?.ap?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MlWidthA:
      attributeMinMaxValues.min = tlifc?.attributes?.ml?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = tlifc?.attributes?.ml?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MaxHeightC:
      attributeMinMaxValues.min = tlifc?.attributes?.cageHeight?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = tlifc?.attributes?.cageHeight?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.BulletAngle:
    case ImplantCharacteristicType.RightBulletAngle:
    case ImplantCharacteristicType.LeftBulletAngle:
      attributeMinMaxValues.min = tlifc?.attributes?.bulletAngle?.min;
      attributeMinMaxValues.max = tlifc?.attributes?.bulletAngle?.max;
      attributeMinMaxValues.fixedValue = tlifc?.attributes?.bulletAngle?.fixedValue;
      break;
    case ImplantCharacteristicType.LordoticAngle:
      attributeMinMaxValues.min = tlifc?.attributes?.lordoticAngle?.min;
      attributeMinMaxValues.max = tlifc?.attributes?.lordoticAngle?.max;
      break;
    case ImplantCharacteristicType.CoronalAngle:
      attributeMinMaxValues.min = tlifc?.attributes?.coronalAngle?.min;
      attributeMinMaxValues.max = tlifc?.attributes?.coronalAngle?.max;
      break;
  }

  return attributeMinMaxValues;
}

export function getTlifCAPartAttributeMinMaxValues(
  attribute: ImplantCharacteristicType,
  tlifca: any,
): AttributeMinMaxValues {
  const attributeMinMaxValues: AttributeMinMaxValues = {
    min: undefined,
    max: undefined,
    fixedValue: undefined,
  };

  switch (attribute) {
    case ImplantCharacteristicType.ApDepthB:
      attributeMinMaxValues.min = tlifca?.attributes?.ap?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = tlifca?.attributes?.ap?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MlWidthA:
      attributeMinMaxValues.min = tlifca?.attributes?.ml?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = tlifca?.attributes?.ml?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.MaxHeightC:
      attributeMinMaxValues.min = tlifca?.attributes?.cageHeight?.min.toFixed(ROUND_TO_DECIMALS);
      attributeMinMaxValues.max = tlifca?.attributes?.cageHeight?.max.toFixed(ROUND_TO_DECIMALS);
      break;
    case ImplantCharacteristicType.BulletAngle:
    case ImplantCharacteristicType.RightBulletAngle:
    case ImplantCharacteristicType.LeftBulletAngle:
      attributeMinMaxValues.min = tlifca?.attributes?.bulletAngle?.min;
      attributeMinMaxValues.max = tlifca?.attributes?.bulletAngle?.max;
      attributeMinMaxValues.fixedValue = tlifca?.attributes?.bulletAngle?.fixedValue;
      break;
    case ImplantCharacteristicType.LordoticAngle:
      attributeMinMaxValues.min = tlifca?.attributes?.lordoticAngle?.min;
      attributeMinMaxValues.max = tlifca?.attributes?.lordoticAngle?.max;
      break;
    case ImplantCharacteristicType.CoronalAngle:
      attributeMinMaxValues.min = tlifca?.attributes?.coronalAngle?.min;
      attributeMinMaxValues.max = tlifca?.attributes?.coronalAngle?.max;
      break;
  }

  return attributeMinMaxValues;
}

export const getCaseLevelData = (
  caseLevel: any,
  implantsData: ImplantsData,
  manualAttributes: Form19ManualAttributes[],
): { levelHeader: any; levelDataRows: LevelDataRow[] } => {
  let levelDataRows: LevelDataRow[] = [];
  let implantLevelType = caseLevel?.implantLevelType?.toUpperCase();

  const { levelRevisions } = implantsData;

  const levelRevision = levelRevisions?.find((element) => caseLevel.levelType === element.level);

  const direction = [
    ImplantType.LLIF,
    ImplantType.TLIFC,
    ImplantType.TLIFCA,
    ImplantType.TLIFO,
  ].includes(implantLevelType)
    ? caseLevel?.direction
    : ImplantDirection.None;

  const orientation = [ImplantType.ALIFX, ImplantType.TLIFC, ImplantType.TLIFCA].includes(
    implantLevelType,
  )
    ? caseLevel?.orientation
    : ImplantOrientation.None;

  const levelHeader = {
    levelCode: caseLevel?.levelCode,
    levelType: caseLevel?.levelType,
    direction: direction,
    implantLevelType: caseLevel?.implantLevelType,
    partType: caseLevel?.partType,
    implantNamePart: caseLevel?.implantNamePart,
    revision: levelRevision ? levelRevision?.revision : undefined,
    description: levelRevision ? levelRevision?.description : undefined,
    orientation: orientation,
  };

  if (implantLevelType === ImplantType.ALIF) {
    levelDataRows = createAlifDataRows(implantsData);
  } else if (implantLevelType === ImplantType.ALIFX) {
    levelDataRows = createAlifXDataRows(implantsData);
  } else if (implantLevelType === ImplantType.LLIF) {
    levelDataRows = createLlifDataRows(implantsData);
  } else if (implantLevelType === ImplantType.TLIFO) {
    levelDataRows = createTlifoDataRows(implantsData);
  } else if (implantLevelType === ImplantType.TLIFC) {
    levelDataRows = createTlifcDataRows(implantsData);
  } else if (implantLevelType === ImplantType.TLIFCA) {
    levelDataRows = createTlifcaDataRows(implantsData);
  }

  levelDataRows = levelDataRows.concat(createManualLevelDataRows(caseLevel, manualAttributes));

  return { levelHeader, levelDataRows };
};

function getPartAttributesData(
  implantNames: string[],
  level: LevelType,
  partAttributes: Form19PartAttributes[],
): string[] {
  let implantMinus = '';
  let implantNormal = '';
  let implantPlus = '';

  const [implantNameMinus01, implantNamPlans02, implantNamePlus03] = implantNames;
  const partAttribute = partAttributes.filter((element) => element.level === level);

  if (partAttribute.length) {
    implantMinus = `IMPLANT_NAME=${implantNameMinus01}\r\n`;
    implantNormal = `IMPLANT_NAME=${implantNamPlans02}\r\n`;
    implantPlus = `IMPLANT_NAME=${implantNamePlus03}\r\n`;
    partAttribute.forEach((element) => {
      implantMinus += element.attribute + '=' + element.minus01 + '\r\n';
      implantNormal += element.attribute + '=' + element.plan02 + '\r\n';
      implantPlus += element.attribute + '=' + element.plus03 + '\r\n';
    });
  }
  return [implantMinus, implantNormal, implantPlus];
}

export function generateForm19CaseData(
  activeCase: ICase,
  form19SettingsData?: any,
  form19ManualData?: any,
  caseLevelsData?: any,
  encodedApprovalImage?: string,
  encodedAdditionalImage: EncodedAdditionalImageType[] = [],
  form20Products: IForm20Product[] = [],
  planApprovedAt?: Date | string | null,
  productDates?: ProductDates,
  isImplantSizeExclusionEnabled?: boolean,
  planKitBom?: IKitBom,
  validCaseExcludedImplants?: IPlanExcludedImplantSizes[],
) {
  const caselevels = caseUtils.getValidCaseLevels(activeCase?.levels);
  const approvedAt = date.parseCalendarDateFromString(planApprovedAt as string);
  const kitCartonStartDate = productDates?.kitCartonStartDate as Date;
  const tlifCOrientationStartDate = productDates?.tlifCOrientationStartDate as Date;

  const lotNumber = activeCase.number;
  const form19Data: any = {};
  const { form19, alif, alifx, llif, tlifo, tlifc, tlifca } = form19SettingsData;
  const isNonConformance = !!form19ManualData?.isNonConformance;
  const nonConformanceReason = form19ManualData?.nonConformanceReason
    ? form19ManualData?.nonConformanceReason
    : '';
  const manualAttributes: Form19ManualAttributes[] = form19ManualData?.manualAttributes
    ? form19ManualData?.manualAttributes
    : [];

  const partAttributes: Form19PartAttributes[] = form19ManualData?.partAttributes
    ? form19ManualData?.partAttributes
    : [];

  const angles: Form19Angles[] = form19ManualData?.angles ? form19ManualData?.angles : [];

  const levelNotes: LevelNotes[] = form19ManualData?.levelNotes ? form19ManualData?.levelNotes : [];
  const levelRevisions: LevelRevision[] = form19ManualData?.levelRevisions
    ? form19ManualData?.levelRevisions
    : [];
  const alifXLevelScrewLengthTypes: AlifXLevelScrewLengthType[] =
    form19ManualData?.alifXLevelScrewLengthTypes
      ? form19ManualData?.alifXLevelScrewLengthTypes
      : [];

  const is11453ScrewAvailable = check11453ScrewAvailable(
    approvedAt,
    productDates?.alifXRotationLockStartDate as Date,
  );

  const tableHeaderRow = {
    characteristic: 'Characteristic',
    minus01: 'Minus 01',
    plan02: 'Plan 02',
    plus03: 'Plus 03',
    min: 'Min. Req.',
    max: 'Max. Req.',
    result: 'PASS/FAIL',
    qaReview: 'QA Review',
  };
  form19Data.isReady = false;
  form19Data.approvals = {
    engineeringApprovedBy: '',
    engineeringApprovedAt: '',
    peerReviewer: '',
    qualityApprovedBy: '',
    qualityApprovedAt: '',
  };
  form19Data.header = {
    name: form19.name,
    documentDescription: form19.documentDescription,
    version: form19.version,
    sop: form19.sop,
    wi: form19.wi,
    lotNumber: activeCase.number,
    tableHeaderRow,
  };

  /**
   * If surgery date is TBD, do not create error
   */
  let validStudyDate = true;
  if (!activeCase?.studyDate?.expiryDate) {
    validStudyDate = false;
  } else if (activeCase?.surgeryDate) {
    validStudyDate = activeCase?.studyDate?.expiryDate >= activeCase?.surgeryDate;
  }

  const hasImplantSizeExclusion = isImplantSizeExclusionEnabled
    ? validCaseExcludedImplants?.length
      ? 'Yes'
      : 'No'
    : 'N/A';
  const implantSizeExclusionList = getImplantSizeExclusionList(
    validCaseExcludedImplants,
    isImplantSizeExclusionEnabled,
  );

  form19Data.step1 = {
    imagesSliceSpacing: form19ManualData?.isImageSliceValid,
    imageContainEntireLumbar: true,
    validStudyDate,
    caseRiskAssessment: form19ManualData?.caseRiskAssessment,
    plusLevelSize: form19ManualData?.plusLevelSize,
    hasImplantSizeExclusion: hasImplantSizeExclusion,
    implantSizeExclusionList: implantSizeExclusionList,
  };

  form19Data.step2 = {
    numberOfLevels: caseLevelsData.length ? caseLevelsData.length : 0,
    verifyImplantCharacteristics: true,
    isMaxHeightCageValid: true,
  };

  const isPlusSizeImplantExcludedForAllLevels = checkPlusSizeImplantExcludedForAllLevels(
    caselevels,
    validCaseExcludedImplants,
    isImplantSizeExclusionEnabled,
  );

  form19Data.step3 = {
    verifyImplantCharacteristics: true,
    isMaxHeightCageValid: true,
    isNonConformance,
    nonConformanceReason,
    isPlusSizeImplantExcludedForAllLevels,
  };

  form19Data.step4 = {
    surgeonApprovalStoredInDb: !!encodedApprovalImage,
    surgeonApprovalAttached: !!encodedApprovalImage,
    dataUrl: encodedApprovalImage ? encodedApprovalImage : '',
  };

  const form19HistoryRevisions = form19ManualData?.form19History ?? [];
  const currentRevision = form19ManualData?.revision;
  const currentDescription = form19ManualData?.description;

  const form19RevisionsHistory = createForm19RevisionHistory(
    form19HistoryRevisions,
    lotNumber,
    currentRevision,
    currentDescription,
  );
  const levelsFilesRevisionsHistory = createLevelFileHistory(
    caseLevelsData,
    lotNumber,
    levelRevisions,
    alifXLevelScrewLengthTypes,
    is11453ScrewAvailable,
    validCaseExcludedImplants,
  );

  form19Data.form19RevisionsHistory = form19RevisionsHistory;
  form19Data.levelsFilesRevisionsHistory = levelsFilesRevisionsHistory;

  form19Data.showCageOrientation = config.featureFlags.alifXOrientation;
  form19Data.showTlifCOrientation = isTlifCOrientationAvailable(tlifCOrientationStartDate);
  form19Data.caseKitCartons = [];

  form19Data.excludedItems = planKitBom?.excludedItems ?? [];
  form19Data.isImplantSizeExclusionEnabled = isImplantSizeExclusionEnabled;

  if (approvedAt && kitCartonStartDate && approvedAt >= kitCartonStartDate) {
    const excludedInstruments = activeCase?.excludedInstruments ?? [];
    let caseKitCartons: IKitCarton[];
    if (isImplantSizeExclusionEnabled) {
      caseKitCartons = planKitBom?.kitCartons ?? [];
    } else {
      caseKitCartons = createCaseKitCartons(
        caseLevelsData,
        form20Products,
        alifXLevelScrewLengthTypes,
        approvedAt,
        excludedInstruments,
        productDates as unknown as ProductDates,
      );
    }

    form19Data.caseKitCartons = caseKitCartons?.length ? caseKitCartons : [];
    form19Data.excludedInstruments = excludedInstruments;
    form19Data.validCaseExcludedImplants = validCaseExcludedImplants?.length
      ? validCaseExcludedImplants
      : [];
  }

  const levelsData: any[] = [];
  for (const caseLevel of caseLevelsData) {
    let drawingNumber = '';
    let version = '';

    const levelNote = levelNotes.find((element) => caseLevel?.levelType === element?.level);
    const alifXLevelScrewLengthType = alifXLevelScrewLengthTypes.find(
      (element) => caseLevel?.levelType === element?.level,
    );

    const levelAdditionalImageDataUrl = encodedAdditionalImage.find(
      (element) => caseLevel?.levelType === element?.levelType,
    );
    const implantName = `${activeCase?.number}_${caseLevel?.implantNameDimFile}`;
    const implantNameMinus01 = `${implantName}.01`;
    const implantNamPlans02 = `${implantName}.02`;
    const implantNamePlus03 = `${implantName}.03`;

    const implantsNames = [implantNameMinus01, implantNamPlans02, implantNamePlus03];
    const [implantMinus, implantNormal, implantPlus] = getPartAttributesData(
      implantsNames,
      caseLevel.levelType,
      partAttributes,
    );

    const implantsData: ImplantsData = {
      form19SettingsData,
      caseLevel,
      partAttributes,
      angles: undefined,
      levelRevisions,
      spineProfile: activeCase?.spineProfile ?? CaseSpineProfile.LumbarStandard,
    };

    if (caseLevel?.implantLevelType.toUpperCase() === ImplantType.ALIF) {
      drawingNumber = alif?.header?.drawingNumber;
      version = alif?.header?.version;
      implantsData.angles = angles ?? [];
    } else if (caseLevel?.implantLevelType.toUpperCase() === ImplantType.ALIFX) {
      drawingNumber = alifx?.header?.drawingNumber;
      version = alifx?.header?.version;
      implantsData.angles = angles ?? [];
    } else if (caseLevel?.implantLevelType.toUpperCase() === ImplantType.LLIF) {
      drawingNumber = llif?.header?.drawingNumber;
      version = llif?.header?.version;
      implantsData.angles = angles ?? [];
    } else if (caseLevel?.implantLevelType.toUpperCase() === ImplantType.TLIFO) {
      drawingNumber = tlifo?.header?.drawingNumber;
      version = tlifo?.header?.version;
      implantsData.angles = angles ?? [];
    } else if (caseLevel?.implantLevelType.toUpperCase() === ImplantType.TLIFC) {
      drawingNumber = tlifc?.header?.drawingNumber;
      version = tlifc?.header?.version;
      implantsData.angles = angles ?? [];
    } else if (caseLevel?.implantLevelType.toUpperCase() === ImplantType.TLIFCA) {
      drawingNumber = tlifca?.header?.drawingNumber;
      version = tlifca?.header?.version;
      implantsData.angles = angles ?? [];
    }

    const levelDataRows = getCaseLevelData(caseLevel, implantsData, manualAttributes);
    levelDataRows.levelHeader = {
      ...levelDataRows.levelHeader,
      levelNotes: levelNote?.notes,
      levelAdditionalImageDataUrl: levelAdditionalImageDataUrl?.encodedAdditionalImage
        ? levelAdditionalImageDataUrl?.encodedAdditionalImage
        : '',
      drawingNumber,
      version,
      minus01File: implantMinus ?? '',
      plan02File: implantNormal ?? '',
      plus03File: implantPlus ?? '',
      alifXLevelScrewLengthType: alifXLevelScrewLengthType?.screwLength ?? '',
    };

    levelsData.push(levelDataRows);
  }

  form19Data.levelsData = levelsData;
  form19Data.isReady = true;

  return form19Data;
}

function createForm19RevisionHistory(
  form19HistoryRevisions: any,
  lotNumber: string,
  currentRevision?: number | string,
  currentDescription?: string,
) {
  const form19RevisionsHistory: Form19RevisionHistory[] = [];

  for (const historyRevision of form19HistoryRevisions) {
    form19RevisionsHistory.push({
      revision: historyRevision?.revision,
      lot: lotNumber,
      description: historyRevision?.description,
    });
  }

  form19RevisionsHistory.push({
    revision: currentRevision,
    lot: lotNumber,
    description: currentDescription,
  });
  return form19RevisionsHistory;
}

function createLevelFileHistory(
  caseLevelsData: any,
  lotNumber: string,
  levelRevisions: LevelRevision[],
  alifXLevelScrewLengthTypes: AlifXLevelScrewLengthType[],
  is11453ScrewAvailable?: boolean,
  validCaseExcludedImplants?: IPlanExcludedImplantSizes[],
): LevelFileRevisionHistory[] {
  const levelFileRevisionHistory: LevelFileRevisionHistory[] = [];

  for (const caseLevel of caseLevelsData) {
    const levelRevision = levelRevisions.find((element) => caseLevel?.levelType === element?.level);

    const caseExcludedImplant = validCaseExcludedImplants?.find(
      (element) => element.level === caseLevel.levelType,
    );

    const [start, end] = planUtils.determineImplantExcludeLoopBounds(caseExcludedImplant);
    for (let i = start; i <= end; i++) {
      const implantPartNumber = `${caseLevel?.implantNamePart}.0${i}`;
      const implantFileName = `${lotNumber}_${caseLevel?.implantNamePart}.0${i}_Rev${levelRevision?.revision} `;
      const row: LevelFileRevisionHistory = {
        partNumber: implantPartNumber,
        fileName: implantFileName,
        revision: levelRevision?.revision,
        description: levelRevision?.description,
      };

      levelFileRevisionHistory.push(row);
    }

    if ([PartType.ALIF_X_TWO_UP, PartType.ALIF_X_TWO_DOWN].includes(caseLevel?.partType)) {
      const alifXLevelScrewLengthType = alifXLevelScrewLengthTypes.find(
        (element) => caseLevel?.levelType === element?.level,
      );

      const screwPartNumber = caseUtils.getScrewLengthSku(
        alifXLevelScrewLengthType?.screwLength,
        is11453ScrewAvailable,
      );
      const row: LevelFileRevisionHistory = {
        partNumber: screwPartNumber,
        fileName: `N/A`,
        revision: `N/A`,
        description: `Screws for ${caseLevel?.implantNamePart.slice(2)}`,
      };

      levelFileRevisionHistory.push(row);
    }
  }

  return levelFileRevisionHistory;
}

function createAlifDataRows(implantsData: ImplantsData) {
  const { form19SettingsData, caseLevel, angles, partAttributes } = implantsData;

  const levelDataRows = [];
  let minus01 = undefined;
  let plan02 = undefined;
  let plus03 = undefined;
  const implantCharacteristicType: ImplantCharacteristicType[] = [
    ImplantCharacteristicType.ApDepthB,
    ImplantCharacteristicType.MlWidthA,
    ImplantCharacteristicType.MaxHeightC,
    ImplantCharacteristicType.BulletAngle,
    ImplantCharacteristicType.BulletHeight,
    ImplantCharacteristicType.LordoticAngle,
    ImplantCharacteristicType.CoronalAngle,
  ];

  const lordoticAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.LordoticAngle,
  );
  const coronalAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.CoronalAngle,
  );

  for (const attribute of implantCharacteristicType) {
    const { min, max } = getAlifPartAttributeMinMaxValues(attribute, form19SettingsData.alif);
    if (attribute === ImplantCharacteristicType.LordoticAngle) {
      minus01 = lordoticAngle ? Number(lordoticAngle?.minus01).toFixed(0) : undefined;
      plan02 = lordoticAngle ? Number(lordoticAngle?.plan02).toFixed(0) : undefined;
      plus03 = lordoticAngle ? Number(lordoticAngle?.plus03).toFixed(0) : undefined;
    } else if (attribute === ImplantCharacteristicType.CoronalAngle) {
      minus01 = coronalAngle ? Number(coronalAngle?.minus01).toFixed(0) : undefined;
      plan02 = coronalAngle ? Number(coronalAngle?.plan02).toFixed(0) : undefined;
      plus03 = coronalAngle ? Number(coronalAngle?.plus03).toFixed(0) : undefined;
    } else if (attribute === ImplantCharacteristicType.BulletAngle) {
      const partAttribute = partAttributes?.find(
        (element: Form19PartAttributes) =>
          caseLevel.levelType === element.level &&
          (element.attribute as ImplantCharacteristicType) === attribute,
      );
      minus01 = partAttribute?.minus01 ? Number(partAttribute?.minus01) : undefined;
      plan02 = partAttribute?.plan02 ? Number(partAttribute?.plan02) : undefined;
      plus03 = partAttribute?.plus03 ? Number(partAttribute?.plus03) : undefined;
    } else {
      const partAttribute = partAttributes?.find(
        (element: Form19PartAttributes) =>
          caseLevel.levelType === element.level &&
          (element.attribute as ImplantCharacteristicType) === attribute,
      );
      minus01 = partAttribute?.minus01
        ? Number(partAttribute?.minus01).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plan02 = partAttribute?.plan02
        ? Number(partAttribute?.plan02).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plus03 = partAttribute?.plus03
        ? Number(partAttribute?.plus03).toFixed(ROUND_TO_DECIMALS)
        : undefined;
    }
    const levelDataRow = {
      levelCode: caseLevel?.levelCode,
      characteristic: attribute,
      minus01,
      plan02,
      plus03,
      min,
      max,
      result: Result.Pass,
    };
    levelDataRows.push(levelDataRow);
  }
  return levelDataRows;
}

function createAlifXDataRows(implantsData: ImplantsData) {
  const { form19SettingsData, caseLevel, angles, partAttributes } = implantsData;
  const levelDataRows = [];
  let minus01 = undefined;
  let plan02 = undefined;
  let plus03 = undefined;
  const implantCharacteristicType: ImplantCharacteristicType[] = [
    ImplantCharacteristicType.ApDepthB,
    ImplantCharacteristicType.MlWidthA,
    ImplantCharacteristicType.MaxHeightC,
    ImplantCharacteristicType.BulletAngle,
    ImplantCharacteristicType.BulletHeight,
    ImplantCharacteristicType.LordoticAngle,
    ImplantCharacteristicType.CoronalAngle,
  ];

  const lordoticAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.LordoticAngle,
  );
  const coronalAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.CoronalAngle,
  );

  for (const attribute of implantCharacteristicType) {
    const { min, max } = getAlifXPartAttributeMinMaxValues(attribute, form19SettingsData.alifx);
    if (attribute === ImplantCharacteristicType.LordoticAngle) {
      minus01 = lordoticAngle ? Number(lordoticAngle?.minus01).toFixed(0) : undefined;
      plan02 = lordoticAngle ? Number(lordoticAngle?.plan02).toFixed(0) : undefined;
      plus03 = lordoticAngle ? Number(lordoticAngle?.plus03).toFixed(0) : undefined;
    } else if (attribute === ImplantCharacteristicType.CoronalAngle) {
      minus01 = coronalAngle ? Number(coronalAngle?.minus01).toFixed(0) : undefined;
      plan02 = coronalAngle ? Number(coronalAngle?.plan02).toFixed(0) : undefined;
      plus03 = coronalAngle ? Number(coronalAngle?.plus03).toFixed(0) : undefined;
    } else if (attribute === ImplantCharacteristicType.BulletAngle) {
      const partAttribute = partAttributes?.find(
        (element: Form19PartAttributes) =>
          caseLevel.levelType === element.level &&
          (element.attribute as ImplantCharacteristicType) === attribute,
      );
      minus01 = partAttribute?.minus01 ? Number(partAttribute?.minus01) : undefined;
      plan02 = partAttribute?.plan02 ? Number(partAttribute?.plan02) : undefined;
      plus03 = partAttribute?.plus03 ? Number(partAttribute?.plus03) : undefined;
    } else {
      const partAttribute = partAttributes?.find(
        (element: Form19PartAttributes) =>
          caseLevel.levelType === element.level &&
          (element.attribute as ImplantCharacteristicType) === attribute,
      );
      minus01 = partAttribute?.minus01
        ? Number(partAttribute?.minus01).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plan02 = partAttribute?.plan02
        ? Number(partAttribute?.plan02).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plus03 = partAttribute?.plus03
        ? Number(partAttribute?.plus03).toFixed(ROUND_TO_DECIMALS)
        : undefined;
    }
    const levelDataRow = {
      levelCode: caseLevel?.levelCode,
      characteristic: attribute,
      minus01,
      plan02,
      plus03,
      min,
      max,
      result: Result.Pass,
    };
    levelDataRows.push(levelDataRow);
  }
  return levelDataRows;
}

function createLlifDataRows(implantsData: ImplantsData): LevelDataRow[] {
  const { form19SettingsData, caseLevel, angles, partAttributes } = implantsData;
  const levelDataRows: LevelDataRow[] = [];
  let minus01 = undefined;
  let plan02 = undefined;
  let plus03 = undefined;
  const implantCharacteristicType: ImplantCharacteristicType[] = [
    ImplantCharacteristicType.ApDepthB,
    ImplantCharacteristicType.MlWidthA,
    ImplantCharacteristicType.MaxHeightC,
  ];
  if (caseLevel?.direction === ImplantDirection.Left) {
    implantCharacteristicType.push(ImplantCharacteristicType.RightBulletAngle);
    implantCharacteristicType.push(ImplantCharacteristicType.RightBulletHeight);
  } else if (caseLevel?.direction === ImplantDirection.Right) {
    implantCharacteristicType.push(ImplantCharacteristicType.LeftBulletAngle);
    implantCharacteristicType.push(ImplantCharacteristicType.LeftBulletHeight);
  }
  implantCharacteristicType.push(ImplantCharacteristicType.LordoticAngle);
  implantCharacteristicType.push(ImplantCharacteristicType.CoronalAngle);

  const lordoticAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.LordoticAngle,
  );
  const coronalAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.CoronalAngle,
  );

  for (const attribute of implantCharacteristicType) {
    const { min, max } = getLlifPartAttributeMinMaxValues(attribute, form19SettingsData.llif);
    if (attribute === ImplantCharacteristicType.LordoticAngle) {
      minus01 = lordoticAngle ? Number(lordoticAngle?.minus01).toFixed(0) : undefined;
      plan02 = lordoticAngle ? Number(lordoticAngle?.plan02).toFixed(0) : undefined;
      plus03 = lordoticAngle ? Number(lordoticAngle?.plus03).toFixed(0) : undefined;
    } else if (attribute === ImplantCharacteristicType.CoronalAngle) {
      minus01 = coronalAngle ? Number(coronalAngle?.minus01).toFixed(0) : undefined;
      plan02 = coronalAngle ? Number(coronalAngle?.plan02).toFixed(0) : undefined;
      plus03 = coronalAngle ? Number(coronalAngle?.plus03).toFixed(0) : undefined;
    } else if (
      attribute === ImplantCharacteristicType.LeftBulletAngle ||
      attribute === ImplantCharacteristicType.RightBulletAngle
    ) {
      const partAttribute = partAttributes?.find(
        (element: Form19PartAttributes) =>
          caseLevel.levelType === element.level &&
          (element.attribute as ImplantCharacteristicType) === attribute,
      );
      minus01 = partAttribute?.minus01 ? Number(partAttribute?.minus01) : undefined;
      plan02 = partAttribute?.plan02 ? Number(partAttribute?.plan02) : undefined;
      plus03 = partAttribute?.plus03 ? Number(partAttribute?.plus03) : undefined;
    } else {
      const partAttribute = partAttributes?.find(
        (element: Form19PartAttributes) =>
          caseLevel.levelType === element.level &&
          (element.attribute as ImplantCharacteristicType) === attribute,
      );
      minus01 = partAttribute?.minus01
        ? Number(partAttribute?.minus01).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plan02 = partAttribute?.plan02
        ? Number(partAttribute?.plan02).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plus03 = partAttribute?.plus03
        ? Number(partAttribute?.plus03).toFixed(ROUND_TO_DECIMALS)
        : undefined;
    }
    const levelDataRow = {
      levelCode: caseLevel?.levelCode,
      characteristic: attribute,
      minus01,
      plan02,
      plus03,
      min,
      max,
      result: Result.Pass,
    };
    levelDataRows.push(levelDataRow);
  }

  return levelDataRows;
}

function createTlifoDataRows(implantsData: ImplantsData) {
  const { form19SettingsData, caseLevel, angles, partAttributes } = implantsData;

  const levelDataRows: LevelDataRow[] = [];
  let minus01 = undefined;
  let plan02 = undefined;
  let plus03 = undefined;
  const implantCharacteristicType: ImplantCharacteristicType[] = [
    ImplantCharacteristicType.ApDepthB,
    ImplantCharacteristicType.MlWidthA,
    ImplantCharacteristicType.MaxHeightC,
    ImplantCharacteristicType.BulletAngle,
    ImplantCharacteristicType.LordoticAngle,
    ImplantCharacteristicType.CoronalAngle,
  ];
  const lordoticAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.LordoticAngle,
  );
  const coronalAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.CoronalAngle,
  );
  for (const attribute of implantCharacteristicType) {
    const { min, max, fixedValue } = getTlifOPartAttributeMinMaxValues(
      attribute,
      form19SettingsData.tlifo,
    );
    if (attribute === ImplantCharacteristicType.BulletAngle) {
      minus01 = fixedValue ? fixedValue : undefined;
      plan02 = fixedValue ? fixedValue : undefined;
      plus03 = fixedValue ? fixedValue : undefined;
    } else if (attribute === ImplantCharacteristicType.LordoticAngle) {
      minus01 = lordoticAngle ? Number(lordoticAngle?.minus01).toFixed(0) : undefined;
      plan02 = lordoticAngle ? Number(lordoticAngle?.plan02).toFixed(0) : undefined;
      plus03 = lordoticAngle ? Number(lordoticAngle?.plus03).toFixed(0) : undefined;
    } else if (attribute === ImplantCharacteristicType.CoronalAngle) {
      minus01 = coronalAngle ? Number(coronalAngle?.minus01).toFixed(0) : undefined;
      plan02 = coronalAngle ? Number(coronalAngle?.plan02).toFixed(0) : undefined;
      plus03 = coronalAngle ? Number(coronalAngle?.plus03).toFixed(0) : undefined;
    } else {
      const partAttribute = partAttributes?.find(
        (element) =>
          caseLevel.levelType === element.level &&
          (element.attribute as ImplantCharacteristicType) === attribute,
      );
      minus01 = partAttribute?.minus01
        ? Number(partAttribute?.minus01).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plan02 = partAttribute?.plan02
        ? Number(partAttribute?.plan02).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plus03 = partAttribute?.plus03
        ? Number(partAttribute?.plus03).toFixed(ROUND_TO_DECIMALS)
        : undefined;
    }
    const levelDataRow = {
      levelCode: caseLevel?.levelCode,
      level: caseLevel?.levelType,
      characteristic: attribute,
      minus01,
      plan02,
      plus03,
      min,
      max,
      result: Result.Pass,
    };
    levelDataRows.push(levelDataRow);
  }

  return levelDataRows;
}

function createTlifcDataRows(implantsData: ImplantsData) {
  const { form19SettingsData, caseLevel, angles, partAttributes } = implantsData;

  const levelDataRows: LevelDataRow[] = [];
  let minus01 = undefined;
  let plan02 = undefined;
  let plus03 = undefined;
  const implantCharacteristicType: ImplantCharacteristicType[] = [
    ImplantCharacteristicType.ApDepthB,
    ImplantCharacteristicType.MlWidthA,
    ImplantCharacteristicType.MaxHeightC,
    ImplantCharacteristicType.BulletAngle,
    ImplantCharacteristicType.LordoticAngle,
    ImplantCharacteristicType.CoronalAngle,
  ];
  const lordoticAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.LordoticAngle,
  );
  const coronalAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.CoronalAngle,
  );
  for (const attribute of implantCharacteristicType) {
    const { min, max, fixedValue } = getTlifCPartAttributeMinMaxValues(
      attribute,
      form19SettingsData.tlifc,
    );
    if (attribute === ImplantCharacteristicType.BulletAngle) {
      minus01 = fixedValue ? fixedValue : undefined;
      plan02 = fixedValue ? fixedValue : undefined;
      plus03 = fixedValue ? fixedValue : undefined;
    } else if (attribute === ImplantCharacteristicType.LordoticAngle) {
      minus01 = lordoticAngle ? Number(lordoticAngle?.minus01).toFixed(0) : undefined;
      plan02 = lordoticAngle ? Number(lordoticAngle?.plan02).toFixed(0) : undefined;
      plus03 = lordoticAngle ? Number(lordoticAngle?.plus03).toFixed(0) : undefined;
    } else if (attribute === ImplantCharacteristicType.CoronalAngle) {
      minus01 = coronalAngle ? Number(coronalAngle?.minus01).toFixed(0) : undefined;
      plan02 = coronalAngle ? Number(coronalAngle?.plan02).toFixed(0) : undefined;
      plus03 = coronalAngle ? Number(coronalAngle?.plus03).toFixed(0) : undefined;
    } else {
      const partAttribute = partAttributes?.find(
        (element) =>
          caseLevel.levelType === element.level &&
          (element.attribute as ImplantCharacteristicType) === attribute,
      );
      minus01 = partAttribute?.minus01
        ? Number(partAttribute?.minus01).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plan02 = partAttribute?.plan02
        ? Number(partAttribute?.plan02).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plus03 = partAttribute?.plus03
        ? Number(partAttribute?.plus03).toFixed(ROUND_TO_DECIMALS)
        : undefined;
    }
    const levelDataRow = {
      levelCode: caseLevel?.levelCode,
      level: caseLevel?.levelType,
      characteristic: attribute,
      minus01,
      plan02,
      plus03,
      min,
      max,
      result: Result.Pass,
    };
    levelDataRows.push(levelDataRow);
  }

  return levelDataRows;
}

function createTlifcaDataRows(implantsData: ImplantsData) {
  const { form19SettingsData, caseLevel, angles, partAttributes } = implantsData;
  const levelDataRows: LevelDataRow[] = [];
  let minus01 = undefined;
  let plan02 = undefined;
  let plus03 = undefined;
  const implantCharacteristicType: ImplantCharacteristicType[] = [
    ImplantCharacteristicType.ApDepthB,
    ImplantCharacteristicType.MlWidthA,
    ImplantCharacteristicType.MaxHeightC,
    ImplantCharacteristicType.BulletAngle,
    ImplantCharacteristicType.LordoticAngle,
    ImplantCharacteristicType.CoronalAngle,
  ];
  const lordoticAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.LordoticAngle,
  );
  const coronalAngle = angles?.find(
    (element) =>
      caseLevel.levelType === element.level &&
      element.attribute === ImplantCharacteristicType.CoronalAngle,
  );
  for (const attribute of implantCharacteristicType) {
    const { min, max, fixedValue } = getTlifCAPartAttributeMinMaxValues(
      attribute,
      form19SettingsData.tlifca,
    );

    if (attribute === ImplantCharacteristicType.BulletAngle) {
      minus01 = fixedValue ? fixedValue : undefined;
      plan02 = fixedValue ? fixedValue : undefined;
      plus03 = fixedValue ? fixedValue : undefined;
    } else if (attribute === ImplantCharacteristicType.LordoticAngle) {
      minus01 = lordoticAngle ? Number(lordoticAngle?.minus01).toFixed(0) : undefined;
      plan02 = lordoticAngle ? Number(lordoticAngle?.plan02).toFixed(0) : undefined;
      plus03 = lordoticAngle ? Number(lordoticAngle?.plus03).toFixed(0) : undefined;
    } else if (attribute === ImplantCharacteristicType.CoronalAngle) {
      minus01 = coronalAngle ? Number(coronalAngle?.minus01).toFixed(0) : undefined;
      plan02 = coronalAngle ? Number(coronalAngle?.plan02).toFixed(0) : undefined;
      plus03 = coronalAngle ? Number(coronalAngle?.plus03).toFixed(0) : undefined;
    } else {
      const partAttribute = partAttributes?.find(
        (element) =>
          caseLevel.levelType === element.level &&
          (element.attribute as ImplantCharacteristicType) === attribute,
      );
      minus01 = partAttribute?.minus01
        ? Number(partAttribute?.minus01).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plan02 = partAttribute?.plan02
        ? Number(partAttribute?.plan02).toFixed(ROUND_TO_DECIMALS)
        : undefined;
      plus03 = partAttribute?.plus03
        ? Number(partAttribute?.plus03).toFixed(ROUND_TO_DECIMALS)
        : undefined;
    }
    const levelDataRow = {
      levelCode: caseLevel?.levelCode,
      level: caseLevel?.levelType,
      characteristic: attribute,
      minus01,
      plan02,
      plus03,
      min,
      max,
      result: Result.Pass,
    };
    levelDataRows.push(levelDataRow);
  }

  return levelDataRows;
}

function createManualLevelDataRows(caseLevel: any, manualAttributes: Form19ManualAttributes[]) {
  const levelDataRows: LevelDataRow[] = [];
  const implantCharacteristicType: ImplantCharacteristicType[] = [
    ImplantCharacteristicType.BulletMinDistance,
    ImplantCharacteristicType.CosmeticCheck,
    ImplantCharacteristicType.PartMarkings,
  ];
  if (
    caseLevel?.implantLevelType?.toUpperCase() === ImplantType.TLIFO ||
    caseLevel?.implantLevelType?.toUpperCase() === ImplantType.TLIFC ||
    caseLevel?.implantLevelType?.toUpperCase() === ImplantType.TLIFCA
  ) {
    implantCharacteristicType.unshift(ImplantCharacteristicType.BulletMinHeight);
  }

  for (const attribute of implantCharacteristicType) {
    const manualAttribute = manualAttributes?.find(
      (element) => caseLevel.levelType === element.level && element.attribute === attribute,
    );
    let result = Result.Fail;

    if (manualAttribute?.minus01 && manualAttribute?.plan02 && manualAttribute?.plus03) {
      result = Result.Pass;
    }
    const levelDataRow = {
      levelCode: caseLevel?.levelCode,
      level: caseLevel?.levelType,
      characteristic: attribute,
      minus01: manualAttribute?.minus01 ? PRESENT : '',
      plan02: manualAttribute?.plan02 ? PRESENT : '',
      plus03: manualAttribute?.plus03 ? PRESENT : '',
      min: PRESENT,
      max: PRESENT,
      result,
    };
    levelDataRows.push(levelDataRow);
  }

  return levelDataRows;
}

export function checkForm19Validity(
  caseLevelsData: any[],
  activeCase: ICase,
  form19UserData: any,
  isPhysicianApprovalAvailable?: boolean,
  validCaseExcludedImplants?: IPlanExcludedImplantSizes[],
): Form19Error[] {
  const form19DataErrors: Form19Error[] = [];
  let isRowDataValid = true;

  if (form19UserData?.plusLevelSize === 1) {
    MAX_HEIGHT_C_PLUS_UPPER_DIFF = MAX_HEIGHT_C_PLUS_UPPER_DIFF_SIZE_1;
    MAX_HEIGHT_C_PLUS_LOWER_DIFF = MAX_HEIGHT_C_PLUS_LOWER_DIFF_SIZE_1;
  } else {
    MAX_HEIGHT_C_PLUS_UPPER_DIFF = MAX_HEIGHT_C_PLUS_UPPER_DIFF_SIZE_2;
    MAX_HEIGHT_C_PLUS_LOWER_DIFF = MAX_HEIGHT_C_PLUS_LOWER_DIFF_SIZE_2;
  }

  const alifXLevelScrewLengthTypes: AlifXLevelScrewLengthType[] = getAttributes(
    form19UserData.levels,
    'SCREW_LENGTH',
  );

  /**
   * Form19 general errors validation
   */
  if (!form19UserData?.isImageSliceValid) {
    form19DataErrors.push({
      type: 'STEP_1',
      code: Form19ErrorCodeType.InvalidImagingSlice,
    });
  }

  if (!isPhysicianApprovalAvailable) {
    form19DataErrors.push({
      type: 'STEP_4',
      code: Form19ErrorCodeType.PhysicianApprovalMissing,
    });
  }

  if (
    !form19UserData?.peerReviewerId &&
    form19UserData?.caseRiskAssessment === CaseRiskAssessmentType.CaseReviewQaReview
  ) {
    form19DataErrors.push({
      type: 'APPROVAL',
      code: Form19ErrorCodeType.PeerApprovalMissing,
    });
  }
  /**
   * If surgery date is TBD, do not create error
   */
  if (
    activeCase?.surgeryDate &&
    (!activeCase?.studyDate?.expiryDate ||
      activeCase?.studyDate?.expiryDate < activeCase?.surgeryDate)
  ) {
    form19DataErrors.push({
      type: 'STEP_1',
      code: Form19ErrorCodeType.ImagingExpired,
    });
  }

  /**
   * Form19 revision validation errors
   */

  if (!form19UserData?.revision && form19UserData?.revision !== 0) {
    form19DataErrors.push({
      type: 'FORM_REVISION',
      code: Form19ErrorCodeType.Form19RevisionNoMissing,
    });
  }

  if (!form19UserData?.description?.trim()) {
    form19DataErrors.push({
      type: 'FORM_REVISION',
      code: Form19ErrorCodeType.Form19RevisionDescriptionMissing,
    });
  }

  /**
   * Form19 row level out of range errors validation
   */
  for (const levelData of caseLevelsData) {
    const { levelHeader, levelDataRows } = levelData;

    const isPlusSizeExcluded =
      validCaseExcludedImplants?.some(
        (implant) => implant.plusSizeExcluded && implant.level === levelHeader.levelType,
      ) || false;

    checkAlifXScrewLengthMissing(levelHeader, alifXLevelScrewLengthTypes, form19DataErrors);

    for (const rowData of levelDataRows) {
      if (
        rowData.characteristic === ImplantCharacteristicType.BulletMinHeight ||
        rowData.characteristic === ImplantCharacteristicType.BulletMinDistance ||
        rowData.characteristic === ImplantCharacteristicType.CosmeticCheck ||
        rowData.characteristic === ImplantCharacteristicType.PartMarkings
      ) {
        if (rowData.result === Result.Fail) {
          form19DataErrors.push({
            type: levelHeader.levelCode,
            code: Form19ErrorCodeType.VisuallyNotInspected,
            characteristic: rowData.characteristic,
            levelType: levelHeader.levelType,
            partType: levelHeader.partType,
            size: 'PLAN',
          });
        }
      } else {
        isRowDataValid = math.inRangeCheck(
          Number(rowData?.minus01),
          Number(rowData?.min),
          Number(rowData?.max),
        );
        if (!isRowDataValid) {
          form19DataErrors.push({
            type: levelHeader.levelCode,
            code: Form19ErrorCodeType.OutOfRange,
            characteristic: rowData.characteristic,
            levelType: levelHeader.levelType,
            partType: levelHeader.partType,
            size: 'MINUS',
            value: rowData.minus01,
            range: [rowData?.min, rowData?.max],
          });
        }

        isRowDataValid = math.inRangeCheck(
          Number(rowData?.plan02),
          Number(rowData?.min),
          Number(rowData?.max),
        );
        if (!isRowDataValid) {
          form19DataErrors.push({
            type: levelHeader.levelCode,
            code: Form19ErrorCodeType.OutOfRange,
            characteristic: rowData.characteristic,
            levelType: levelHeader.levelType,
            partType: levelHeader.partType,
            size: 'PLAN',
            value: rowData.plan02,
            range: [rowData?.min, rowData?.max],
          });
        }

        if (!isPlusSizeExcluded) {
          isRowDataValid = math.inRangeCheck(
            Number(rowData?.plus03),
            Number(rowData?.min),
            Number(rowData?.max),
          );
          if (!isRowDataValid) {
            form19DataErrors.push({
              type: levelHeader.levelCode,
              code: Form19ErrorCodeType.OutOfRange,
              characteristic: rowData.characteristic,
              levelType: levelHeader.levelType,
              partType: levelHeader.partType,
              size: 'PLUS',
              value: rowData.plus03,
              range: [rowData?.min, rowData?.max],
            });
          }
        }
        /**
         * Form19 Max Height Cage errors validation
         */
        if (rowData.characteristic === ImplantCharacteristicType.MaxHeightC) {
          let diff: number | undefined = undefined;

          diff = round(
            math.difference(Number(rowData?.plan02), Number(rowData?.minus01)),
            ROUND_DIFFERENCE_TO_DECIMALS,
          );
          if (diff > MAX_HEIGHT_C_MINUS_UPPER_DIFF) {
            form19DataErrors.push({
              type: levelHeader.levelCode,
              code: Form19ErrorCodeType.Difference,
              characteristic: rowData.characteristic,
              levelType: levelHeader.levelType,
              partType: levelHeader.partType,
              size: 'MINUS',
              value: diff,
              range: [MAX_HEIGHT_C_MINUS_LOWER_DIFF, MAX_HEIGHT_C_MINUS_UPPER_DIFF],
            });
          } else if (diff < MAX_HEIGHT_C_MINUS_LOWER_DIFF) {
            form19DataErrors.push({
              type: levelHeader.levelCode,
              code: Form19ErrorCodeType.Difference,
              characteristic: rowData.characteristic,
              levelType: levelHeader.levelType,
              partType: levelHeader.partType,
              size: 'MINUS',
              value: diff,
              range: [MAX_HEIGHT_C_MINUS_LOWER_DIFF, MAX_HEIGHT_C_MINUS_UPPER_DIFF],
            });
          }

          if (!isPlusSizeExcluded) {
            diff = round(
              math.difference(Number(rowData?.plus03), Number(rowData?.plan02)),
              ROUND_DIFFERENCE_TO_DECIMALS,
            );
            if (diff > MAX_HEIGHT_C_PLUS_UPPER_DIFF) {
              form19DataErrors.push({
                type: levelHeader.levelCode,
                code: Form19ErrorCodeType.Difference,
                characteristic: rowData.characteristic,
                levelType: levelHeader.levelType,
                partType: levelHeader.partType,
                size: 'PLUS',
                value: diff,
                range: [MAX_HEIGHT_C_PLUS_LOWER_DIFF, MAX_HEIGHT_C_PLUS_UPPER_DIFF],
              });
            } else if (diff < MAX_HEIGHT_C_PLUS_LOWER_DIFF) {
              form19DataErrors.push({
                type: levelHeader.levelCode,
                code: Form19ErrorCodeType.Difference,
                characteristic: rowData.characteristic,
                levelType: levelHeader.levelType,
                partType: levelHeader.partType,
                size: 'PLUS',
                value: diff,
                range: [MAX_HEIGHT_C_PLUS_LOWER_DIFF, MAX_HEIGHT_C_PLUS_UPPER_DIFF],
              });
            }
          }
        }
      }
    }

    if (!levelHeader?.revision && levelHeader?.revision !== 0) {
      form19DataErrors.push({
        type: 'LEVEL_REVISION',
        code: Form19ErrorCodeType.LevelRevisionNoMissing,
        levelType: levelHeader.levelType,
        partType: levelHeader.partType,
      });
    }

    if (!levelHeader?.description?.trim()) {
      form19DataErrors.push({
        type: 'LEVEL_REVISION',
        code: Form19ErrorCodeType.LevelRevisionDescriptionMissing,
        levelType: levelHeader.levelType,
        partType: levelHeader.partType,
      });
    }
  }
  return form19DataErrors;
}

export const getForm19ErrorDescription = (form19Error: Form19Error): string => {
  const messagePrefix = `${format.formatLevelType(
    form19Error.levelType as LevelType,
  )} - ${format.formatPartType(form19Error.partType as PartType)} - ${form19Error.size} - `;

  const messageVisualCheckPrefix = `${format.formatLevelType(
    form19Error.levelType as LevelType,
  )} - ${format.formatPartType(form19Error.partType as PartType)} - `;

  const characteristic = format.formatImplantCharacteristicType(
    form19Error.characteristic,
    form19Error.partType as PartType,
  );

  const levelPartTypePrefix = `${format.formatLevelType(
    form19Error.levelType as LevelType,
  )} - ${format.formatPartType(form19Error.partType as PartType)} - `;

  let error: string;

  switch (form19Error.code) {
    case Form19ErrorCodeType.PhysicianApprovalMissing:
      error = 'Physician approval is missing';
      break;
    case Form19ErrorCodeType.PeerApprovalMissing:
      error = 'Peer reviewer is missing';
      break;
    case Form19ErrorCodeType.ImagingExpired:
      error = 'Imaging has expired';
      break;
    case Form19ErrorCodeType.InvalidImagingSlice:
      error = 'Missing answer to imaging requirement confirmation';
      break;
    case Form19ErrorCodeType.Form19RevisionNoMissing:
      error = 'Form-19 revision# is missing';
      break;
    case Form19ErrorCodeType.Form19RevisionDescriptionMissing:
      error = 'Form-19 revision description is missing';
      break;
    case Form19ErrorCodeType.LevelRevisionNoMissing:
      error = `${levelPartTypePrefix} level revision# is missing`;
      break;
    case Form19ErrorCodeType.LevelRevisionDescriptionMissing:
      error = `${levelPartTypePrefix} level revision description is missing`;
      break;
    case Form19ErrorCodeType.OutOfRange:
      error = `${messagePrefix} ${characteristic} of ${form19Error.value} is out of range (min: ${form19Error?.range?.[0]}, max: ${form19Error?.range?.[1]})`;
      break;
    case Form19ErrorCodeType.Difference:
      error = `${messagePrefix} ${characteristic} difference between Plan is out of range by ${form19Error.value}mm  (min: ${form19Error?.range?.[0]}, max: ${form19Error?.range?.[1]})`;
      break;
    case Form19ErrorCodeType.VisuallyNotInspected:
      error = `${messageVisualCheckPrefix} ${characteristic} not inspected`;
      break;
    case Form19ErrorCodeType.AlifXScrewLengthMissing:
      error = `${levelPartTypePrefix} screw length is missing`;
      break;
    default:
      error = form19Error.code;
  }

  return error;
};

export const getAttributes = (
  levels: any,
  type:
    | 'ANGLE'
    | 'NOTE'
    | 'MANUAL_ATTRIBUTE'
    | 'PART_ATTRIBUTE'
    | 'ADDITIONAL_IMAGE'
    | 'SCREW_LENGTH'
    | 'REVISION',
) => levels?.flatMap((level: any) => level.values.filter((value: any) => value.type === type));

export const getForm19Errors = (
  activeCase: ICase,
  form19Data: any,
  form19Settings: any,
  hasApproval: boolean,
  validCaseExcludedImplants?: IPlanExcludedImplantSizes[],
) => {
  const validCaseLevels = caseUtils.getValidCaseLevelsWithPartTypes(activeCase.levels);
  const manualAttributes = getAttributes(form19Data.levels, 'MANUAL_ATTRIBUTE');
  const partAttributes = getAttributes(form19Data.levels, 'PART_ATTRIBUTE');
  const angles = getAttributes(form19Data.levels, 'ANGLE');
  const levelRevisions = getAttributes(form19Data.levels, 'REVISION');

  let caseLevelsData: any[] = [];

  validCaseLevels.forEach((level) => {
    const implantData = {
      form19SettingsData: form19Settings,
      caseLevel: level,
      angles: angles,
      partAttributes: partAttributes,
      levelRevisions: levelRevisions,
      spineProfile: activeCase?.spineProfile ?? CaseSpineProfile.LumbarStandard,
    };
    const caseLevelData = getCaseLevelData(level, implantData, manualAttributes);
    caseLevelsData.push(caseLevelData);
  });
  return checkForm19Validity(
    caseLevelsData,
    activeCase,
    form19Data,
    hasApproval,
    validCaseExcludedImplants,
  );
};

export const getForm19FormData = (
  form19Data: any,
  caseImplantSpecifications: ICaseImplantSpecification[],
  caseAssets: IAsset[],
  activeCase: ICase,
) => {
  function getLevelNotes(activeCase: ICase): LevelNotes[] {
    const validCaseLevels = caseUtils.getValidCaseLevelsTypes(activeCase.levels);

    return validCaseLevels.map((level) => {
      return { level, notes: '' };
    });
  }

  function getLevelRevision(
    levels: ILevels,
    caseImplantSpecifications: ICaseImplantSpecification[],
  ): LevelRevision[] {
    const validCaseLevels = caseUtils.getValidCaseLevelsTypes(levels);
    const levelRevisions: LevelRevision[] = [];

    validCaseLevels.forEach((level) => {
      const caseImplantSpecificationsLevels = caseImplantSpecifications.filter(
        (caseImplantSpecification) => caseImplantSpecification.level === level,
      );
      const plan = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Normal,
      );
      levelRevisions.push({
        level,
        revision: plan?.revision ?? 0,
        description: plan?.description ?? undefined,
      });
    });

    return levelRevisions;
  }

  function getAlifXLevelScrewLengthType(
    levels: ILevels,
    caseImplantSpecifications: ICaseImplantSpecification[],
  ): AlifXLevelScrewLengthType[] {
    const validCaseLevels = caseUtils.getValidCaseAlifXLevels(levels);
    const alifXLevelScrewLengthTypes: AlifXLevelScrewLengthType[] = [];

    validCaseLevels.forEach((level) => {
      const caseImplantSpecificationsLevels = caseImplantSpecifications.filter(
        (caseImplantSpecification) => caseImplantSpecification.level === level,
      );
      const plan = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Normal,
      );
      alifXLevelScrewLengthTypes.push({
        level,
        screwLength: plan?.screwLength ?? undefined,
      });
    });

    return alifXLevelScrewLengthTypes;
  }

  function getLevelAdditionalImages(
    levels: ILevels,
    caseAssets: IAsset[],
  ): LevelAdditionalImages[] {
    const validCaseLevels = caseUtils.getValidCaseLevelsTypes(levels);
    const validForm19AdditionalImageAssets = getValidForm19AdditionalImageAssets(validCaseLevels);

    return validCaseLevels.map((level) => {
      const additionalImageAssetType = validForm19AdditionalImageAssets.find((assetType) =>
        assetType.includes(level),
      );
      const asset = caseAssets.find(
        (asset: IAsset) => asset.assetType === additionalImageAssetType,
      );
      return {
        level,
        additionalImage: undefined,
        assetType: additionalImageAssetType ? additionalImageAssetType : undefined,
        asset: asset ?? undefined,
        deletedAssetId: 0,
        encodedImage: '',
      };
    });
  }

  function getAngles(
    levels: ILevels,
    caseImplantSpecifications: ICaseImplantSpecification[],
  ): Form19Angles[] {
    const validCaseLevels = caseUtils.getValidCaseLevels(levels);
    let angles: Form19Angles[] = [];

    validCaseLevels.forEach((level) => {
      const caseImplantSpecificationsLevels = caseImplantSpecifications.filter(
        (caseImplantSpecification) => caseImplantSpecification.level === level,
      );

      const minus = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Minus,
      );
      const plan = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Normal,
      );
      const plus = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Plus,
      );

      angles.push({
        level,
        attribute: ImplantCharacteristicType.CoronalAngle,
        minus01: minus?.coronalAngle ?? '',
        plan02: plan?.coronalAngle ?? '',
        plus03: plus?.coronalAngle ?? '',
      });

      angles.push({
        level,
        attribute: ImplantCharacteristicType.LordoticAngle,
        minus01: minus?.lordoticAngle ?? '',
        plan02: plan?.lordoticAngle ?? '',
        plus03: plus?.lordoticAngle ?? '',
      });
    });

    return angles;
  }

  function getManualAttributes(
    activeCase: ICase,
    caseImplantSpecifications: ICaseImplantSpecification[],
  ): Form19ManualAttributes[] {
    const validCaseLevels = caseUtils.getValidCaseLevelsTypes(activeCase.levels);

    let manualAttributes: Form19ManualAttributes[] = [];

    validCaseLevels.forEach((level) => {
      const caseImplantSpecificationsLevels = caseImplantSpecifications.filter(
        (caseImplantSpecification) => caseImplantSpecification.level === level,
      );

      const minus = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Minus,
      );
      const plan = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Normal,
      );
      const plus = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Plus,
      );

      const partType = activeCase.levels[level] as PartType;
      if (
        [
          PartType.TLIFO_LEFT,
          PartType.TLIFO_RIGHT,
          PartType.TLIFC_OFFSET_LEFT,
          PartType.TLIFC_OFFSET_RIGHT,
          PartType.TLIFC_INLINE_LEFT,
          PartType.TLIFC_INLINE_RIGHT,
          PartType.TLIFCA_OFFSET_LEFT,
          PartType.TLIFCA_OFFSET_RIGHT,
          PartType.TLIFCA_INLINE_LEFT,
          PartType.TLIFCA_INLINE_RIGHT,
        ].includes(partType)
      ) {
        manualAttributes.push({
          level,
          attribute: ImplantCharacteristicType.BulletMinHeight,
          minus01: minus?.isBulletMinHeightValid ?? false,
          plan02: plan?.isBulletMinHeightValid ?? false,
          plus03: plus?.isBulletMinHeightValid ?? false,
        });
      }

      manualAttributes.push({
        level,
        attribute: ImplantCharacteristicType.BulletMinDistance,
        minus01: minus?.isBulletMinDistanceValid ?? false,
        plan02: plan?.isBulletMinDistanceValid ?? false,
        plus03: plus?.isBulletMinDistanceValid ?? false,
      });

      manualAttributes.push({
        level,
        attribute: ImplantCharacteristicType.CosmeticCheck,
        minus01: minus?.isCosmeticCheckValid ?? false,
        plan02: plan?.isCosmeticCheckValid ?? false,
        plus03: plus?.isCosmeticCheckValid ?? false,
      });

      manualAttributes.push({
        level,
        attribute: ImplantCharacteristicType.PartMarkings,
        minus01: minus?.isPartMarkingsValid ?? false,
        plan02: plan?.isPartMarkingsValid ?? false,
        plus03: plus?.isPartMarkingsValid ?? false,
      });
    });

    return manualAttributes;
  }

  function getPartAttributes(
    activeCase: ICase,
    caseImplantSpecifications: ICaseImplantSpecification[],
  ): Form19PartAttributes[] {
    const validCaseLevels = caseUtils.getValidCaseLevelsWithPartTypes(activeCase.levels);

    let partAttributes: Form19PartAttributes[] = [];

    validCaseLevels.forEach((level) => {
      const caseImplantSpecificationsLevels = caseImplantSpecifications.filter(
        (caseImplantSpecification) => caseImplantSpecification.level === level.levelType,
      );

      const minus = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Minus,
      );
      const plan = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Normal,
      );
      const plus = caseImplantSpecificationsLevels.find(
        (caseImplantSpecification) => caseImplantSpecification.size === LevelSize.Plus,
      );

      const partType = activeCase.levels[level.levelType] as PartType;

      partAttributes.push({
        level: level.levelType,
        attribute: ImplantCharacteristicType.ApDepthB,
        minus01: minus?.apDepth,
        plan02: plan?.apDepth,
        plus03: plus?.apDepth,
      });

      partAttributes.push({
        level: level.levelType,
        attribute: ImplantCharacteristicType.MlWidthA,
        minus01: minus?.mlWidth,
        plan02: plan?.mlWidth,
        plus03: plus?.mlWidth,
      });

      partAttributes.push({
        level: level.levelType,
        attribute: ImplantCharacteristicType.MaxHeightC,
        minus01: minus?.maxHeight,
        plan02: plan?.maxHeight,
        plus03: plus?.maxHeight,
      });

      if ([PartType.ALIF, PartType.ALIF_X_TWO_UP, PartType.ALIF_X_TWO_DOWN].includes(partType)) {
        partAttributes.push({
          level: level.levelType,
          attribute: ImplantCharacteristicType.BulletAngle,
          minus01: minus?.bulletAngle,
          plan02: plan?.bulletAngle,
          plus03: plus?.bulletAngle,
        });

        partAttributes.push({
          level: level.levelType,
          attribute: ImplantCharacteristicType.BulletHeight,
          minus01: minus?.bulletHeight,
          plan02: plan?.bulletHeight,
          plus03: plus?.bulletHeight,
        });
      } else if ([PartType.LLIF_LEFT, PartType.LLIF_RIGHT].includes(partType)) {
        partAttributes.push({
          level: level.levelType,
          attribute: ImplantCharacteristicType.RightBulletAngle,
          minus01: minus?.rightBulletAngle,
          plan02: plan?.rightBulletAngle,
          plus03: plus?.rightBulletAngle,
        });

        partAttributes.push({
          level: level.levelType,
          attribute: ImplantCharacteristicType.RightBulletHeight,
          minus01: minus?.rightBulletHeight,
          plan02: plan?.rightBulletHeight,
          plus03: plus?.rightBulletHeight,
        });

        partAttributes.push({
          level: level.levelType,
          attribute: ImplantCharacteristicType.LeftBulletAngle,
          minus01: minus?.leftBulletAngle,
          plan02: plan?.leftBulletAngle,
          plus03: plus?.leftBulletAngle,
        });

        partAttributes.push({
          level: level.levelType,
          attribute: ImplantCharacteristicType.LeftBulletHeight,
          minus01: minus?.leftBulletHeight,
          plan02: plan?.leftBulletHeight,
          plus03: plus?.leftBulletHeight,
        });
      }
    });

    return partAttributes;
  }

  const caseLevels = activeCase.levels;

  let angles = getAngles(caseLevels, caseImplantSpecifications);
  let manualAttributes = getManualAttributes(activeCase, caseImplantSpecifications);
  let partAttributes = getPartAttributes(activeCase, caseImplantSpecifications);
  let levelNotes = form19Data?.levelNotes ?? getLevelNotes(activeCase);
  let levelAdditionalImages = getLevelAdditionalImages(caseLevels, caseAssets);
  let alifXLevelScrewLengthTypes = getAlifXLevelScrewLengthType(
    caseLevels,
    caseImplantSpecifications,
  );
  let levelRevisions = getLevelRevision(caseLevels, caseImplantSpecifications);
  const caseLevelsWithPartTypes = caseUtils.getValidCaseLevelsWithPartTypes(activeCase.levels);

  const levels: { level: any; values: any[] }[] = [];
  caseLevelsWithPartTypes.forEach((level) => {
    let values = [
      ...partAttributes
        .filter((partAttribute: Form19PartAttributes) => partAttribute.level === level.levelType)
        .map((result: any) => ({ ...result, type: 'PART_ATTRIBUTE' })),
      ...angles
        .filter((angle: Form19Angles) => angle.level === level.levelType)
        .map((result: any) => ({ ...result, type: 'ANGLE' })),
      ...manualAttributes
        .filter(
          (manualAttribute: Form19ManualAttributes) => manualAttribute.level === level.levelType,
        )
        .map((result: any) => ({ ...result, type: 'MANUAL_ATTRIBUTE' })),
      ...levelNotes
        .filter((levelNote: LevelNotes) => levelNote.level === level.levelType)
        .map((result: any) => ({ ...result, type: 'NOTE' })),
      ...levelRevisions
        .filter((levelRevisions: LevelRevision) => levelRevisions.level === level.levelType)
        .map((result: any) => ({ ...result, type: 'REVISION' })),
      ...levelAdditionalImages
        .filter(
          (levelAdditionalImage: LevelAdditionalImages) =>
            levelAdditionalImage.level === level.levelType,
        )
        .map((result: any) => ({ ...result, type: 'ADDITIONAL_IMAGE' })),
      ...alifXLevelScrewLengthTypes
        .filter(
          (alifXLevelScrewLengthType: AlifXLevelScrewLengthType) =>
            alifXLevelScrewLengthType.level === level.levelType,
        )
        .map((result: any) => ({ ...result, type: 'SCREW_LENGTH' })),
    ];

    levels.push({
      level: level,
      values,
    });
  });
  return levels;
};

export const typeOfSettingsForm19Key = (
  attributes: ISettingsForm19ImplantAttributes | ISettingsForm19InterbodyMeasurementsAttributes,
  form19Key: keyof ISettingsForm19,
): attributes is ISettingsForm19InterbodyMeasurementsAttributes => {
  return form19Key in attributes;
};

export function checkAlifXScrewLengthMissing(
  levelHeader: any,
  alifXLevelScrewLengthTypes: AlifXLevelScrewLengthType[],
  form19DataErrors: Form19Error[],
) {
  if (
    [PartType.ALIF_X_TWO_UP, PartType.ALIF_X_TWO_DOWN].includes(levelHeader.partType as PartType)
  ) {
    const alifXLevelScrewLengthType = alifXLevelScrewLengthTypes?.find(
      (x) => x.level === (levelHeader.levelType as LevelType),
    );
    if (!alifXLevelScrewLengthType || !alifXLevelScrewLengthType?.screwLength) {
      form19DataErrors.push({
        type: 'SCREW_LENGTH',
        code: Form19ErrorCodeType.AlifXScrewLengthMissing,
        levelType: levelHeader.levelType,
        partType: levelHeader.partType,
      });
    }
  }
}

export function getForm19ImplantAttribute(
  form19: ISettingsForm19,
  partType: PartType,
): ISettingsForm19ImplantAttributes | undefined {
  if (!form19 || !partType) return;

  const key = partType
    .replace('_NO_CAM', '')
    .replace('_LEFT', '')
    .replace('_RIGHT', '')
    .replace('_OFFSET', '')
    .replace('_INLINE', '')
    .replace('_TWO_DOWN', '')
    .replace('_TWO_UP', '');
  const form19Key = formatSettingDataKey(key as ImplantType) as keyof ISettingsForm19;

  const targetAttribute:
    | ISettingsForm19ImplantAttributes
    | ISettingsForm19InterbodyMeasurementsAttributes
    | null = form19[form19Key] ? form19[form19Key].attributes : null;

  if (!targetAttribute || typeOfSettingsForm19Key(targetAttribute, form19Key)) return;

  return targetAttribute;
}

export function createCaseKitCartons(
  caseLevelsData: ILevelsHeaderData[],
  form20Products: IForm20Product[],
  alifXLevelScrewLengthTypes: AlifXLevelScrewLengthType[],
  planApprovedAt: Date,
  excludedInstruments: string[],
  productDates: ProductDates,
): IKitCarton[] {
  let kitCartons: IKitCarton[] = [];

  const levelsCount = countLevelsByImplantType(caseLevelsData);
  const kitCartonRuleType = getKitCartonRuleType(
    levelsCount.nonAlifXLevelCount,
    levelsCount.alifXLevelCount,
  );

  const isM4lExpandedAvailable = checkM4lExpandedAvailability(
    planApprovedAt,
    productDates?.tlifCM4lStartDate as Date,
  );
  const is11453ScrewAvailable = check11453ScrewAvailable(
    planApprovedAt,
    productDates?.alifXRotationLockStartDate as Date,
  );

  switch (kitCartonRuleType) {
    case KitCartonRuleType.NonAlifXCase:
      kitCartons = generateNonAlifXKitCartons(
        caseLevelsData,
        form20Products,
        planApprovedAt,
        excludedInstruments,
        levelsCount,
        isM4lExpandedAvailable,
        productDates,
      );
      break;
    case KitCartonRuleType.AlifXCase:
      kitCartons = generateAlifXKitCartons(
        caseLevelsData,
        form20Products,
        alifXLevelScrewLengthTypes,
        excludedInstruments,
        levelsCount,
        isM4lExpandedAvailable,
        is11453ScrewAvailable,
        productDates,
      );
      break;
    case KitCartonRuleType.NonAlifXAndAlifXCase:
      kitCartons = generateNonAlifXAndAlifXKitCartons(
        caseLevelsData,
        form20Products,
        alifXLevelScrewLengthTypes,
        planApprovedAt,
        excludedInstruments,
        levelsCount,
        isM4lExpandedAvailable,
        is11453ScrewAvailable,
        productDates,
      );
      break;
  }
  return kitCartons;
}

export function checkM4lExpandedAvailability(
  planApprovedAt: Date,
  tlifCM4lStartDate: Date | null,
): boolean {
  return !!(
    config.featureFlags.m4lExpandedInventoryAvailable &&
    tlifCM4lStartDate &&
    planApprovedAt >= tlifCM4lStartDate
  );
}

export function check11453ScrewAvailable(
  planApprovedAt: Date | null,
  alifXRotationLockStartDate: Date | null,
): boolean {
  return !!(
    planApprovedAt &&
    alifXRotationLockStartDate &&
    planApprovedAt >= alifXRotationLockStartDate
  );
}

// export function countAlifXAndNonAlifXLevels(caseLevelsData: ILevelsHeaderData[]) {
//   let nonAlifXLevelCount = 0;
//   let alifXLevelCount = 0;

//   for (const caseLevel of caseLevelsData) {
//     if (caseLevel.implantLevelType === ImplantType.ALIFX) {
//       alifXLevelCount++;
//     } else {
//       nonAlifXLevelCount++;
//     }
//   }

//   return { nonAlifXLevelCount, alifXLevelCount };
// }

export function countLevelsByImplantType(caseLevelsData: ILevelsHeaderData[]) {
  const countLevelsByImplant: CountLevelsByImplant = {
    alifLevelCount: 0,
    alifXLevelCount: 0,
    llifLevelCount: 0,
    tlifCLevelCount: 0,
    tlifOLevelCount: 0,
    nonAlifXLevelCount: 0,
    totalLevelCount: caseLevelsData?.length || 0,
  };

  for (const caseLevel of caseLevelsData) {
    switch (caseLevel.implantLevelType) {
      case ImplantType.ALIF:
        countLevelsByImplant.alifLevelCount++;
        break;
      case ImplantType.ALIFX:
        countLevelsByImplant.alifXLevelCount++;
        break;
      case ImplantType.LLIF:
        countLevelsByImplant.llifLevelCount++;
        break;
      case ImplantType.TLIFC:
        countLevelsByImplant.tlifCLevelCount++;
        break;
      case ImplantType.TLIFCA:
        countLevelsByImplant.tlifCLevelCount++;
        break;
      case ImplantType.TLIFO:
        countLevelsByImplant.tlifOLevelCount++;
        break;
      default:
        break;
    }
  }

  countLevelsByImplant.nonAlifXLevelCount =
    countLevelsByImplant.alifLevelCount +
    countLevelsByImplant.llifLevelCount +
    countLevelsByImplant.tlifCLevelCount +
    countLevelsByImplant.tlifOLevelCount;

  return countLevelsByImplant;
}

export function getKitCartonRuleType(nonAlifXLevelCount: number, alifXLevelCount: number) {
  let kitCartonRuleType: KitCartonRuleType;

  if (alifXLevelCount === 0 && nonAlifXLevelCount > 0) {
    kitCartonRuleType = KitCartonRuleType.NonAlifXCase;
  } else if (nonAlifXLevelCount === 0 && alifXLevelCount > 0) {
    kitCartonRuleType = KitCartonRuleType.AlifXCase;
  } else {
    kitCartonRuleType = KitCartonRuleType.NonAlifXAndAlifXCase;
  }

  return kitCartonRuleType;
}

function generateKitCarton(kitName: string, kitItems: IKitItem[], totalWeight: number): IKitCarton {
  return {
    kitName,
    kitItems: [...kitItems],
    itemsCount: kitItems?.length ?? 0,
    totalWeight,
  };
}

export function generateKitItems(
  form20Products: IForm20Product[],
  itemSkuNumbers: string[],
  excludedInstruments: string[],
): IKit {
  const kitItems: IKitItem[] = [];
  let kitItemsCount = 0;
  let kitTotalWeight = KIT_WEIGHT;

  const finalItemSkuNumbers = itemSkuNumbers.filter(
    (element) => !excludedInstruments?.includes(element),
  );

  for (const sku of finalItemSkuNumbers) {
    const element = form20Products.find(
      (element) => element.sku === sku && element?.status === Form20ProductStatusType.InUse,
    );
    if (element) {
      const description =
        element.sku === INSTRUMENT_1050 ? 'aprevo® M5 Inserter' : element?.description;
      kitItemsCount++;
      kitTotalWeight += element?.weight ? Number(element?.weight) : 0;
      kitItems.push({
        itemNumber: kitItemsCount,
        partNumber: element?.sku,
        description: description,
        itemType: element?.type,
        weight: element?.weight ?? '',
      });
    }
  }
  return {
    kitItems,
    itemsCount: kitItemsCount,
    totalWeight: kitTotalWeight,
  };
}

function generateItemSkuNumbers(LevelsData: ILevelsHeaderData[]): string[] {
  const itemSkuNumbers: string[] = [];

  for (const caseLevel of LevelsData) {
    for (let i = 1; i <= 3; i++) {
      const sku: string = caseLevel.implantNamePart + '.0' + i;
      itemSkuNumbers.push(sku);
    }
  }

  return itemSkuNumbers;
}

export function findImplantsCombination(
  caseLevelsData: ILevelsHeaderData[],
): ImplantCombinationType {
  const tlifCFound = caseLevelsData.some((element) =>
    [ImplantType.TLIFC, ImplantType.TLIFCA].includes(element.implantLevelType),
  );
  const tlifOFound = caseLevelsData.some(
    (element) => element.implantLevelType === ImplantType.TLIFO,
  );

  const alifOrLlifFound = caseLevelsData.some(
    (element) =>
      element.implantLevelType === ImplantType.ALIF ||
      element.implantLevelType === ImplantType.LLIF,
  );

  if (tlifCFound && tlifOFound && alifOrLlifFound) {
    return ImplantCombinationType.AllThree;
  } else if (tlifCFound && tlifOFound) {
    return ImplantCombinationType.TlifCTlifO;
  } else if (tlifOFound && alifOrLlifFound) {
    return ImplantCombinationType.TlifOWithAlifOrLlif;
  } else if (tlifCFound && alifOrLlifFound) {
    return ImplantCombinationType.TlifCWithAlifOrLlif;
  } else if (tlifOFound) {
    return ImplantCombinationType.TlifO;
  } else if (tlifCFound) {
    return ImplantCombinationType.TlifC;
  } else if (alifOrLlifFound) {
    return ImplantCombinationType.AlifOrLlif;
  } else {
    return ImplantCombinationType.None;
  }
}

export function findInstrumentsByKit(
  planApprovedAt: Date,
  implantsCombination: ImplantCombinationType,
  kitCartonRuleType: KitCartonRuleType,
  levelsCount: CountLevelsByImplant,
  isM4lExpandedAvailable: boolean,
  productDates: ProductDates,
): InstrumentsByKit {
  let mLInserterSku: string[] = [];
  const instrumentsByKit: InstrumentsByKit = {
    kit1: [],
    kit2: [],
    kit3: [],
  };

  if (
    implantsCombination === ImplantCombinationType.None &&
    kitCartonRuleType !== KitCartonRuleType.AlifXCase
  )
    return instrumentsByKit;

  const m4lStartDate = productDates?.m4lStartDate as Date;
  const kitCartonStartDate = productDates?.kitCartonStartDate as Date;

  const is1090InserterAvailable = m4lStartDate && planApprovedAt >= m4lStartDate;
  const is1045InserterAvailable = kitCartonStartDate && planApprovedAt >= kitCartonStartDate;

  if (isM4lExpandedAvailable) {
    mLInserterSku = [INSTRUMENT_1090];
  } else {
    mLInserterSku = is1090InserterAvailable ? [INSTRUMENT_1090] : [INSTRUMENT_1050];
  }

  if (
    !config.featureFlags.m4InserterInventoryManagement &&
    kitCartonRuleType !== KitCartonRuleType.AlifXCase
  ) {
    instrumentsByKit.kit1 = mLInserterSku;
    return instrumentsByKit;
  }

  switch (kitCartonRuleType) {
    case KitCartonRuleType.NonAlifXCase:
      if (isM4lExpandedAvailable) {
        instrumentsByKit.kit1 = [INSTRUMENT_1090];
      } else {
        switch (implantsCombination) {
          case ImplantCombinationType.AlifOrLlif:
            instrumentsByKit.kit1 = is1045InserterAvailable ? [INSTRUMENT_1045] : [INSTRUMENT_1050];
            break;
          case ImplantCombinationType.TlifC:
            instrumentsByKit.kit1 = [INSTRUMENT_1050];
            break;
          case ImplantCombinationType.TlifO:
          case ImplantCombinationType.TlifOWithAlifOrLlif:
            instrumentsByKit.kit1 = is1090InserterAvailable ? [INSTRUMENT_1090] : [INSTRUMENT_1050];
            break;
          case ImplantCombinationType.TlifCWithAlifOrLlif:
            if (levelsCount?.totalLevelCount <= 4) {
              instrumentsByKit.kit1 = is1045InserterAvailable
                ? [INSTRUMENT_1050, INSTRUMENT_1045]
                : [INSTRUMENT_1050];
            } else if (levelsCount.totalLevelCount > 4 && levelsCount.totalLevelCount <= 6) {
              instrumentsByKit.kit1 = [INSTRUMENT_1050];
              instrumentsByKit.kit2 = is1045InserterAvailable ? [INSTRUMENT_1045] : [];
            }
            break;
          case ImplantCombinationType.TlifCTlifO:
          case ImplantCombinationType.AllThree:
            if (levelsCount?.totalLevelCount <= 4) {
              instrumentsByKit.kit1 = is1090InserterAvailable
                ? [INSTRUMENT_1050, INSTRUMENT_1090]
                : [INSTRUMENT_1050];
            } else if (levelsCount.totalLevelCount > 4 && levelsCount.totalLevelCount <= 6) {
              instrumentsByKit.kit1 = [INSTRUMENT_1050];
              instrumentsByKit.kit2 = is1090InserterAvailable ? [INSTRUMENT_1090] : [];
            }
            break;
        }
      }
      break;
    case KitCartonRuleType.AlifXCase:
      instrumentsByKit.kit1 = [INSTRUMENT_1045, INSTRUMENT_1034];
      switch (levelsCount?.alifXLevelCount) {
        case 1:
        case 2:
        case 3:
        case 4:
          instrumentsByKit.kit2 = [INSTRUMENT_1060, INSTRUMENT_1080];
          break;
        case 5:
        case 6:
          instrumentsByKit.kit2 = [INSTRUMENT_1060];
          instrumentsByKit.kit3 = [INSTRUMENT_1080];
          break;
        default:
          instrumentsByKit.kit1 = [];
          break;
      }
      break;
    case KitCartonRuleType.NonAlifXAndAlifXCase:
      instrumentsByKit.kit3 = [INSTRUMENT_1060, INSTRUMENT_1080];
      if (isM4lExpandedAvailable) {
        instrumentsByKit.kit1 = [INSTRUMENT_1090];
        instrumentsByKit.kit2 = [INSTRUMENT_1045, INSTRUMENT_1034];
      } else {
        switch (implantsCombination) {
          case ImplantCombinationType.AlifOrLlif:
            instrumentsByKit.kit1 = [INSTRUMENT_1045];
            instrumentsByKit.kit2 = [INSTRUMENT_1034];
            break;
          case ImplantCombinationType.TlifC:
            instrumentsByKit.kit1 = [INSTRUMENT_1050];
            instrumentsByKit.kit2 = [INSTRUMENT_1045, INSTRUMENT_1034];
            break;
          case ImplantCombinationType.TlifO:
          case ImplantCombinationType.TlifOWithAlifOrLlif:
            instrumentsByKit.kit1 = is1090InserterAvailable ? [INSTRUMENT_1090] : [INSTRUMENT_1050];
            instrumentsByKit.kit2 = [INSTRUMENT_1045, INSTRUMENT_1034];
            break;
          case ImplantCombinationType.TlifCWithAlifOrLlif:
            instrumentsByKit.kit1 = [INSTRUMENT_1045, INSTRUMENT_1050];
            instrumentsByKit.kit2 = [INSTRUMENT_1034];
            break;
          case ImplantCombinationType.TlifCTlifO:
          case ImplantCombinationType.AllThree:
            instrumentsByKit.kit1 = is1090InserterAvailable
              ? [INSTRUMENT_1050, INSTRUMENT_1090]
              : [INSTRUMENT_1050];
            instrumentsByKit.kit2 = [INSTRUMENT_1045, INSTRUMENT_1034];
            break;
          default:
            instrumentsByKit.kit3 = [];
            break;
        }
      }
      break;
  }

  instrumentsByKit.kit1 = sortBy(instrumentsByKit.kit1);
  instrumentsByKit.kit2 = sortBy(instrumentsByKit.kit2);
  instrumentsByKit.kit3 = sortBy(instrumentsByKit.kit3);

  return instrumentsByKit;
}

export function generateNonAlifXKitCartons(
  caseLevelsData: ILevelsHeaderData[],
  form20Products: IForm20Product[],
  planApprovedAt: Date,
  excludedInstruments: string[],
  levelsCount: CountLevelsByImplant,
  isM4lExpandedAvailable: boolean,
  productDates: ProductDates,
): IKitCarton[] {
  const kitCartons: IKitCarton[] = [];
  const itemSkuNumbers: string[] = [];

  const implantsCombination = findImplantsCombination(caseLevelsData);
  const instrumentsByKit = findInstrumentsByKit(
    planApprovedAt,
    implantsCombination,
    KitCartonRuleType.NonAlifXCase,
    levelsCount,
    isM4lExpandedAvailable,
    productDates,
  );

  const implantTypeCountByKit = configureNonAlifxOnlyRuleKits(
    implantsCombination,
    levelsCount,
    isM4lExpandedAvailable,
  );

  if (implantTypeCountByKit.kit1 > 0 && implantTypeCountByKit.kit2 === 0) {
    //Create kit1
    const kit1ImplantPartNumbers = generateItemSkuNumbers(caseLevelsData);
    itemSkuNumbers.push(...kit1ImplantPartNumbers);
    itemSkuNumbers.push(...instrumentsByKit.kit1);
    const kit1 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 1`, kit1.kitItems, kit1.totalWeight));
  } else if (implantTypeCountByKit.kit1 > 0 && implantTypeCountByKit.kit2 > 0) {
    let kit1Levels: ILevelsHeaderData[];
    let kit2Levels: ILevelsHeaderData[];

    kit1Levels = caseLevelsData.slice(0, implantTypeCountByKit.kit1);
    kit2Levels = caseLevelsData.slice(implantTypeCountByKit.kit1);

    //Create kit1
    const kit1ImplantPartNumbers = generateItemSkuNumbers(kit1Levels);
    itemSkuNumbers.push(...kit1ImplantPartNumbers);
    itemSkuNumbers.push(...instrumentsByKit.kit1);

    const kit1 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 1`, kit1.kitItems, kit1.totalWeight));

    // Create kit2
    itemSkuNumbers.length = 0;

    const kit2ImplantPartNumbers = generateItemSkuNumbers(kit2Levels);
    itemSkuNumbers.push(...kit2ImplantPartNumbers);
    itemSkuNumbers.push(...instrumentsByKit.kit2);

    const kit2 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 2`, kit2.kitItems, kit2.totalWeight));
  }

  return kitCartons;
}

export function generateAlifXKitCartons(
  caseLevelsData: ILevelsHeaderData[],
  form20Products: IForm20Product[],
  alifXLevelScrewLengthTypes: AlifXLevelScrewLengthType[],
  excludedInstruments: string[],
  levelsCount: CountLevelsByImplant,
  isM4lExpandedAvailable: boolean,
  is11453ScrewAvailable: boolean,
  productDates: ProductDates,
): IKitCarton[] {
  const kitCartons: IKitCarton[] = [];
  const itemSkuNumbers: string[] = [];

  const planApprovedAt = new Date();
  const instrumentsByKit = findInstrumentsByKit(
    planApprovedAt,
    ImplantCombinationType.None,
    KitCartonRuleType.AlifXCase,
    levelsCount,
    isM4lExpandedAvailable,
    productDates,
  );

  let kit1Levels: ILevelsHeaderData[] = [];
  let kit2Levels: ILevelsHeaderData[] = [];
  let kit3Levels: ILevelsHeaderData[] = [];

  const implantTypeCountByKit = configureAlifxOnlyRuleKits(levelsCount);

  if (levelsCount.alifXLevelCount > 0 && levelsCount.alifXLevelCount <= 2) {
    //Create kit1
    const kit1ImplantPartNumbers = generateItemSkuNumbers(caseLevelsData);
    itemSkuNumbers.push(...kit1ImplantPartNumbers);

    const screwPartNumbers: string[] = getScrewPartNumbers(
      caseLevelsData,
      alifXLevelScrewLengthTypes,
      is11453ScrewAvailable,
    );
    itemSkuNumbers.push(...screwPartNumbers);
    itemSkuNumbers.push(...instrumentsByKit.kit1);

    const kit1 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 1`, kit1.kitItems, kit1.totalWeight));

    // Create kit2
    itemSkuNumbers.length = 0;
    itemSkuNumbers.push(...instrumentsByKit.kit2);
    const kit2 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 2`, kit2.kitItems, kit2.totalWeight));
  } else if (levelsCount.alifXLevelCount > 2 && levelsCount.alifXLevelCount <= 4) {
    kit1Levels = caseLevelsData.slice(0, implantTypeCountByKit.kit1);
    kit2Levels = caseLevelsData.slice(implantTypeCountByKit.kit1);

    //Create kit1
    const kit1ImplantPartNumbers = generateItemSkuNumbers(kit1Levels);
    itemSkuNumbers.push(...kit1ImplantPartNumbers);
    const kit1ScrewPartNumbers: string[] = getScrewPartNumbers(
      kit1Levels,
      alifXLevelScrewLengthTypes,
      is11453ScrewAvailable,
    );
    itemSkuNumbers.push(...kit1ScrewPartNumbers);
    itemSkuNumbers.push(...instrumentsByKit.kit1);

    const kit1 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 1`, kit1.kitItems, kit1.totalWeight));

    // Create kit2
    itemSkuNumbers.length = 0;

    const kit2ImplantPartNumbers = generateItemSkuNumbers(kit2Levels);
    itemSkuNumbers.push(...kit2ImplantPartNumbers);
    const kit2ScrewPartNumbers: string[] = getScrewPartNumbers(
      kit2Levels,
      alifXLevelScrewLengthTypes,
      is11453ScrewAvailable,
    );
    itemSkuNumbers.push(...kit2ScrewPartNumbers);
    itemSkuNumbers.push(...instrumentsByKit.kit2);

    const kit2 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 2`, kit2.kitItems, kit2.totalWeight));
  } else if (levelsCount.alifXLevelCount > 4 && levelsCount.alifXLevelCount <= 6) {
    //Create kit1
    kit1Levels = caseLevelsData.slice(0, implantTypeCountByKit.kit1);
    kit2Levels = caseLevelsData.slice(
      implantTypeCountByKit.kit1,
      implantTypeCountByKit.kit1 + implantTypeCountByKit.kit2,
    );
    kit3Levels = caseLevelsData.slice(implantTypeCountByKit.kit1 + implantTypeCountByKit.kit2);

    const kit1ImplantPartNumbers = generateItemSkuNumbers(kit1Levels);
    itemSkuNumbers.push(...kit1ImplantPartNumbers);
    const kit1ScrewPartNumbers: string[] = getScrewPartNumbers(
      kit1Levels,
      alifXLevelScrewLengthTypes,
      is11453ScrewAvailable,
    );
    itemSkuNumbers.push(...kit1ScrewPartNumbers);
    itemSkuNumbers.push(...instrumentsByKit.kit1);

    const kit1 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 1`, kit1.kitItems, kit1.totalWeight));

    // Create kit2
    itemSkuNumbers.length = 0;

    const kit2ImplantPartNumbers = generateItemSkuNumbers(kit2Levels);
    itemSkuNumbers.push(...kit2ImplantPartNumbers);
    const kit2ScrewPartNumbers: string[] = getScrewPartNumbers(
      kit2Levels,
      alifXLevelScrewLengthTypes,
      is11453ScrewAvailable,
    );
    itemSkuNumbers.push(...kit2ScrewPartNumbers);
    itemSkuNumbers.push(...instrumentsByKit.kit2);

    const kit2 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 2`, kit2.kitItems, kit2.totalWeight));

    // Create kit3
    itemSkuNumbers.length = 0;

    const kit3ImplantPartNumbers = generateItemSkuNumbers(kit3Levels);
    itemSkuNumbers.push(...kit3ImplantPartNumbers);
    const kit3ScrewPartNumbers: string[] = getScrewPartNumbers(
      kit3Levels,
      alifXLevelScrewLengthTypes,
      is11453ScrewAvailable,
    );
    itemSkuNumbers.push(...kit3ScrewPartNumbers);
    itemSkuNumbers.push(...instrumentsByKit.kit3);

    const kit3 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
    kitCartons.push(generateKitCarton(`${KIT_NAME} 3`, kit3.kitItems, kit3.totalWeight));
  }

  return kitCartons;
}

function getScrewPartNumbers(
  caseLevelsData: ILevelsHeaderData[],
  alifXLevelScrewLengthTypes: AlifXLevelScrewLengthType[],
  is11453ScrewAvailable?: boolean,
) {
  const screwPartNumbers: string[] = [];
  for (const caseLevel of caseLevelsData) {
    const alifXLevelScrewLengthType = alifXLevelScrewLengthTypes.find(
      (element) =>
        caseLevel?.levelType === element?.level &&
        caseLevel?.implantLevelType === ImplantType.ALIFX,
    );

    if (alifXLevelScrewLengthType) {
      screwPartNumbers.push(
        caseUtils.getScrewLengthSku(alifXLevelScrewLengthType?.screwLength, is11453ScrewAvailable),
      );
    }
  }
  return screwPartNumbers;
}

export function generateNonAlifXAndAlifXKitCartons(
  caseLevelsData: ILevelsHeaderData[],
  form20Products: IForm20Product[],
  alifXLevelScrewLengthTypes: AlifXLevelScrewLengthType[],
  planApprovedAt: Date,
  excludedInstruments: string[],
  levelsCount: CountLevelsByImplant,
  isM4lExpandedAvailable: boolean,
  is11453ScrewAvailable: boolean,
  productDates: ProductDates,
): IKitCarton[] {
  const kitCartons: IKitCarton[] = [];
  const itemSkuNumbers: string[] = [];

  const implantsCombination = findImplantsCombination(caseLevelsData);
  const instrumentsByKit = findInstrumentsByKit(
    planApprovedAt,
    implantsCombination,
    KitCartonRuleType.NonAlifXAndAlifXCase,
    levelsCount,
    isM4lExpandedAvailable,
    productDates,
  );

  const kit1Levels: ILevelsHeaderData[] = [];
  const kit2Levels: ILevelsHeaderData[] = [];
  const kit3Levels: ILevelsHeaderData[] = [];

  const alifxCaseLevelsData = caseLevelsData.filter(
    (caseLevel) => caseLevel.implantLevelType === ImplantType.ALIFX,
  );

  const nonAlifxCaseLevelsData = caseLevelsData.filter(
    (caseLevel) => caseLevel.implantLevelType !== ImplantType.ALIFX,
  );

  const alifxImplantTypeCountByKit = configureMixedRuleKitsForAlifx(levelsCount);
  const nonAlifxImplantTypeCountByKit = configureMixedRuleKitsForNonAlifx(
    levelsCount,
    implantsCombination,
    isM4lExpandedAvailable,
  );

  // Create kit1
  kit1Levels.push(...nonAlifxCaseLevelsData.slice(0, nonAlifxImplantTypeCountByKit.kit1));
  if (alifxImplantTypeCountByKit.kit1 === 1) {
    kit1Levels.push(...alifxCaseLevelsData.slice(0, alifxImplantTypeCountByKit.kit1));
  }

  const kit1ImplantPartNumbers = generateItemSkuNumbers(kit1Levels);
  itemSkuNumbers.push(...kit1ImplantPartNumbers);

  if (alifxImplantTypeCountByKit.kit1 === 1) {
    const screwPartNumbers: string[] = getScrewPartNumbers(
      kit1Levels,
      alifXLevelScrewLengthTypes,
      is11453ScrewAvailable,
    );
    itemSkuNumbers.push(...screwPartNumbers);
  }
  itemSkuNumbers.push(...instrumentsByKit.kit1);

  const kit1 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
  kitCartons.push(generateKitCarton(`${KIT_NAME} 1`, kit1.kitItems, kit1.totalWeight));

  //Create kit2
  itemSkuNumbers.length = 0;
  kit2Levels.push(
    ...alifxCaseLevelsData.slice(
      alifxImplantTypeCountByKit.kit1,
      alifxImplantTypeCountByKit.kit1 + alifxImplantTypeCountByKit.kit2,
    ),
  );
  const kit2ImplantPartNumbers = generateItemSkuNumbers(kit2Levels);
  itemSkuNumbers.push(...kit2ImplantPartNumbers);
  const kit2ScrewPartNumbers: string[] = getScrewPartNumbers(
    kit2Levels,
    alifXLevelScrewLengthTypes,
    is11453ScrewAvailable,
  );
  itemSkuNumbers.push(...kit2ScrewPartNumbers);
  itemSkuNumbers.push(...instrumentsByKit.kit2);

  const kit2 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
  kitCartons.push(generateKitCarton(`${KIT_NAME} 2`, kit2.kitItems, kit2.totalWeight));

  // Create kit3
  itemSkuNumbers.length = 0;
  if (nonAlifxImplantTypeCountByKit.kit3 === 2) {
    kit3Levels.push(
      ...nonAlifxCaseLevelsData.slice(
        nonAlifxImplantTypeCountByKit.kit1,
        nonAlifxImplantTypeCountByKit.kit1 + nonAlifxImplantTypeCountByKit.kit3,
      ),
    );
  }
  kit3Levels.push(
    ...alifxCaseLevelsData.slice(
      alifxImplantTypeCountByKit.kit1 + alifxImplantTypeCountByKit.kit2,
      alifxImplantTypeCountByKit.kit1 +
        alifxImplantTypeCountByKit.kit2 +
        alifxImplantTypeCountByKit.kit3,
    ),
  );

  const kit3ImplantPartNumbers = generateItemSkuNumbers(kit3Levels);
  itemSkuNumbers.push(...kit3ImplantPartNumbers);
  const kit3ScrewPartNumbers: string[] = getScrewPartNumbers(
    kit3Levels,
    alifXLevelScrewLengthTypes,
    is11453ScrewAvailable,
  );
  itemSkuNumbers.push(...kit3ScrewPartNumbers);
  itemSkuNumbers.push(...instrumentsByKit.kit3);

  const kit3 = generateKitItems(form20Products, itemSkuNumbers, excludedInstruments);
  kitCartons.push(generateKitCarton(`${KIT_NAME} 3`, kit3.kitItems, kit3.totalWeight));

  return kitCartons;
}

function configureNonAlifxOnlyRuleKits(
  implantsCombination: ImplantCombinationType,
  levelsCount: CountLevelsByImplant,
  isM4lExpandedAvailable: boolean,
): ImplantTypeCountByKit {
  const implantTypeCountByKit: ImplantTypeCountByKit = {
    kit1: 0,
    kit2: 0,
    kit3: 0,
  };

  if (isM4lExpandedAvailable) {
    if (levelsCount.totalLevelCount <= 5) {
      implantTypeCountByKit.kit1 = levelsCount?.totalLevelCount;
    } else if (levelsCount.totalLevelCount === 6) {
      implantTypeCountByKit.kit1 = 3;
      implantTypeCountByKit.kit2 = 3;
    }
  } else {
    switch (implantsCombination) {
      case ImplantCombinationType.AlifOrLlif:
      case ImplantCombinationType.TlifC:
      case ImplantCombinationType.TlifO:
      case ImplantCombinationType.TlifOWithAlifOrLlif:
        if (levelsCount.totalLevelCount <= 5) {
          implantTypeCountByKit.kit1 = levelsCount?.totalLevelCount;
        } else if (levelsCount.totalLevelCount === 6) {
          implantTypeCountByKit.kit1 = 3;
          implantTypeCountByKit.kit2 = 3;
        }
        break;
      case ImplantCombinationType.TlifCWithAlifOrLlif:
      case ImplantCombinationType.TlifCTlifO:
      case ImplantCombinationType.AllThree:
        if (levelsCount.totalLevelCount <= 4) {
          implantTypeCountByKit.kit1 = levelsCount?.totalLevelCount;
        } else if (levelsCount.totalLevelCount === 5) {
          implantTypeCountByKit.kit1 = 3;
          implantTypeCountByKit.kit2 = 2;
        } else if (levelsCount.totalLevelCount === 6) {
          implantTypeCountByKit.kit1 = 3;
          implantTypeCountByKit.kit2 = 3;
        }
        break;
    }
  }
  return implantTypeCountByKit;
}

function configureAlifxOnlyRuleKits(levelsCount: CountLevelsByImplant): ImplantTypeCountByKit {
  const implantTypeCountByKit: ImplantTypeCountByKit = {
    kit1: 0,
    kit2: 0,
    kit3: 0,
  };

  switch (levelsCount.alifXLevelCount) {
    case 1:
      implantTypeCountByKit.kit1 = 1;
      break;
    case 2:
      implantTypeCountByKit.kit1 = 2;
      break;
    case 3:
      implantTypeCountByKit.kit1 = 2;
      implantTypeCountByKit.kit2 = 1;
      break;
    case 4:
      implantTypeCountByKit.kit1 = 2;
      implantTypeCountByKit.kit2 = 2;
      break;
    case 5:
      implantTypeCountByKit.kit1 = 1;
      implantTypeCountByKit.kit2 = 2;
      implantTypeCountByKit.kit3 = 2;
      break;
    case 6:
      implantTypeCountByKit.kit1 = 2;
      implantTypeCountByKit.kit2 = 2;
      implantTypeCountByKit.kit3 = 2;
      break;
    default:
      break;
  }
  return implantTypeCountByKit;
}

function configureMixedRuleKitsForNonAlifx(
  levelsCount: CountLevelsByImplant,
  implantsCombination: ImplantCombinationType,
  isM4lExpandedAvailable: boolean,
): ImplantTypeCountByKit {
  const nonAlifxImplantTypeCountByKit: ImplantTypeCountByKit = {
    kit1: 0,
    kit2: 0,
    kit3: 0,
  };

  if (isM4lExpandedAvailable) {
    nonAlifxImplantTypeCountByKit.kit1 = levelsCount.nonAlifXLevelCount;
  } else {
    switch (levelsCount.nonAlifXLevelCount) {
      case 1:
      case 2:
      case 3:
      case 4:
        nonAlifxImplantTypeCountByKit.kit1 = levelsCount.nonAlifXLevelCount;
        break;
      case 5:
        if (
          [
            ImplantCombinationType.TlifCWithAlifOrLlif,
            ImplantCombinationType.TlifCTlifO,
            ImplantCombinationType.AllThree,
          ].includes(implantsCombination)
        ) {
          nonAlifxImplantTypeCountByKit.kit1 = 3;
          nonAlifxImplantTypeCountByKit.kit3 = 2;
        } else {
          nonAlifxImplantTypeCountByKit.kit1 = 5;
        }
        break;
      default:
        break;
    }
  }
  return nonAlifxImplantTypeCountByKit;
}

function configureMixedRuleKitsForAlifx(levelsCount: CountLevelsByImplant): ImplantTypeCountByKit {
  const alifxImplantTypeCountByKit: ImplantTypeCountByKit = {
    kit1: 0,
    kit2: 0,
    kit3: 0,
  };

  switch (levelsCount.alifXLevelCount) {
    case 1:
      alifxImplantTypeCountByKit.kit2 = 1;
      break;
    case 2:
      alifxImplantTypeCountByKit.kit2 = 2;
      break;
    case 3:
      alifxImplantTypeCountByKit.kit2 = 2;
      alifxImplantTypeCountByKit.kit3 = 1;
      break;
    case 4:
      alifxImplantTypeCountByKit.kit2 = 2;
      alifxImplantTypeCountByKit.kit3 = 2;
      break;
    case 5:
      alifxImplantTypeCountByKit.kit1 = 1;
      alifxImplantTypeCountByKit.kit2 = 2;
      alifxImplantTypeCountByKit.kit3 = 2;
      break;
    default:
      break;
  }
  return alifxImplantTypeCountByKit;
}

export function getImplantSizeExclusionList(
  validCaseExcludedImplants?: IPlanExcludedImplantSizes[],
  isImplantSizeExclusionEnabled?: boolean,
): string {
  if (
    !isImplantSizeExclusionEnabled ||
    !validCaseExcludedImplants ||
    validCaseExcludedImplants.length === 0
  ) {
    return 'N/A';
  }
  return validCaseExcludedImplants
    .map(
      (implant) =>
        `${format.formatLevelType(implant.level)} ${format.formatPartType(implant.partType)}`,
    )
    .join(', ');
}

export function checkPlusSizeImplantExcludedForAllLevels(
  caseLevels: LevelType[],
  validCaseExcludedImplants?: IPlanExcludedImplantSizes[],
  isImplantSizeExclusionEnabled?: boolean,
): boolean {
  if (!isImplantSizeExclusionEnabled || !validCaseExcludedImplants) {
    return false;
  }

  for (const level of caseLevels) {
    const levelExcluded = validCaseExcludedImplants.some(
      (implant) => implant.level === level && implant.plusSizeExcluded,
    );

    if (!levelExcluded) {
      return false;
    }
  }

  return true;
}
