import React from 'react';
import MuiTextField from '@mui/material/TextField';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { testing } from '@workflow-nx/utils';
import { Control, FieldValues, useController } from 'react-hook-form';

type NumberFormatCustomProps = NumberFormatProps & {
  decimalPlaces?: number;
  inputRef?: any;
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props: NumberFormatCustomProps,
) {
  return (
    <NumberFormat
      value={props.value}
      className={props.className}
      disabled={props.disabled}
      onValueChange={(values) => {
        props?.name &&
          props.onChange &&
          props.onChange({
            // @ts-ignore
            target: {
              name: props.name,
              value: values.value,
            },
          });
      }}
      isAllowed={props.isAllowed}
      decimalScale={props.decimalPlaces ?? 0}
      fixedDecimalScale={true}
      allowNegative={props.allowNegative}
      thousandSeparator={false}
      isNumericString
      suffix={props.suffix}
    />
  );
});

export function NumberTextFieldRHF({
  decimalPlaces = 0,
  disabled,
  fullWidth = true,
  label,
  name,
  variant,
  isAllowed,
  allowNegative,
  shrink,
  suffix,
  onBlur,
  onChange,
  control,
  fontColor,
  fullHeight,
  hidden,
  matchHeight,
  showErrorText,
  helperText,
}: {
  name: string;
  label?: string;
  decimalPlaces?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  isAllowed?: (values: NumberFormatValues) => boolean;
  allowNegative?: boolean;
  shrink?: boolean;
  suffix?: string;
  onBlur?: any;
  onChange?: any;
  control: Control<FieldValues>;
  fontColor?: string;
  fullHeight?: boolean;
  hidden?: boolean;
  matchHeight?: boolean;
  showErrorText?: boolean;
  helperText?: string;
}) {
  const {
    field: {
      onChange: hookFormOnChange,
      onBlur: hookFormOnBlur,
      ref,
      value,
      ...hookFormRemainingProps
    },
    fieldState: { invalid, error },
  } = useController({ name, control });

  return (
    <MuiTextField
      error={Boolean(invalid)}
      data-test-id={testing.toKebabCase(label ?? name) + '-number-field'}
      fullWidth={fullWidth}
      helperText={showErrorText && error?.message ? error?.message : helperText}
      label={label}
      type="text"
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
        hookFormOnBlur();
      }}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
        hookFormOnChange(e);
      }}
      value={value}
      inputRef={ref}
      variant={variant ?? 'outlined'}
      disabled={disabled}
      size={'small'}
      InputLabelProps={{ shrink }}
      inputProps={{
        isAllowed,
        suffix,
        decimalPlaces,
        allowNegative,
      }}
      InputProps={{
        inputComponent: NumberFormatCustom as React.ElementType<InputBaseComponentProps>,
      }}
      sx={{
        input: { color: fontColor },
        '& .MuiInputBase-root': { minHeight: matchHeight ? '100%' : undefined },
      }}
      style={{
        height: fullHeight ? '100%' : undefined,
        display: hidden ? 'none' : undefined,
      }}
      {...hookFormRemainingProps}
    />
  );
}
