import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FIND_USER } from '../gql';
import {
  ILevels,
  ImplantType,
  caseUtils,
  planUtils,
  ISurgeonPreferenceImplant,
} from '@workflow-nx/common';
import useAuth from './useAuth';
import { FeatureFlag } from '../utils/featureFlags';

export const useSurgeonExcludedImplantSizes = (levels: ILevels, surgeonId?: number) => {
  const { hasFeatureFlag } = useAuth();
  const isImplantSizeExclusionEnabled = !!hasFeatureFlag?.(FeatureFlag.implantSizeExclusionEnabled);

  const [allowedPlusSizeExcludedImplantTypes, setAllowedPlusSizeExcludedImplantTypes] = useState<
    ImplantType[]
  >([]);

  const foundCaseImplantTypes = caseUtils.getValidImplantTypes(levels);

  const [findSurgeon] = useLazyQuery(FIND_USER, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: Number(surgeonId),
    },
    onCompleted: (data) => {
      const surgeonPreferenceImplants: ISurgeonPreferenceImplant[] =
        data?.user?.preferences?.surgeonPreferenceImplants || [];
      const allowedPlusSizeExcluded: ImplantType[] = planUtils.extractAllowedPlusSizeExcluded(
        surgeonPreferenceImplants,
        foundCaseImplantTypes,
      );
      setAllowedPlusSizeExcludedImplantTypes(allowedPlusSizeExcluded);
    },
  });

  useEffect(() => {
    if (surgeonId && isImplantSizeExclusionEnabled) {
      findSurgeon();
    }
  }, [findSurgeon, surgeonId, isImplantSizeExclusionEnabled]);

  return allowedPlusSizeExcludedImplantTypes;
};
