import {
  AutoCorrectionEvaluatedCorrectionType,
  MeasurementSetCount,
} from '@workflow-nx/auto-correct';
import { Axis, EndPlate, IMeasure, Position } from '@workflow-nx/common';
import { Angle, Matrix, Quaternion, Vector3 } from 'babylonjs';

export const calculateDistanceBetweenVector = (point1: Vector3, point2: Vector3) => {
  return point1.subtract(point2).length();
};

export const calculateAngleBetweenVectors = (vector1: Vector3, vector2: Vector3, axis: Axis) => {
  let normal: Vector3 = Vector3.Zero();
  switch (axis) {
    case Axis.X:
      normal = Vector3.Cross(vector1, new Vector3(1, 0, 0)).normalize();
      break;
    case Axis.Y:
      normal = Vector3.Cross(vector1, new Vector3(0, 1, 0)).normalize();
      break;
    case Axis.Z:
      normal = Vector3.Cross(vector1, new Vector3(0, 0, 1)).normalize();
      break;
  }

  return Vector3.GetAngleBetweenVectors(vector1, vector2, normal);
};

export const calculateMidpoint = (vectorA: Vector3, vectorB: Vector3): Vector3 => {
  const difference = vectorB.subtract(vectorA);
  const midpoint = difference.divide(new Vector3(2, 2, 2));

  return vectorA.add(midpoint);
};

export const calculateSingleMeasurementSet = (measurements: IMeasure[]) => {
  const targetAMeasurement: IMeasure | undefined = measurements.find(
    (measurement: IMeasure) => measurement.position === Position.Anterior,
  );
  const targetPMeasurement: IMeasure | undefined = measurements.find(
    (measurement: IMeasure) => measurement.position === Position.Posterior,
  );
  const targetPRMeasurement: IMeasure | undefined = measurements.find(
    (measurement: IMeasure) => measurement.position === Position.PatientRight,
  );
  const targetPLMeasurement: IMeasure | undefined = measurements.find(
    (measurement: IMeasure) => measurement.position === Position.PatientLeft,
  );

  if (targetAMeasurement && targetPMeasurement && targetPRMeasurement && targetPLMeasurement) {
    const targetA = new Vector3(...targetAMeasurement.point);
    const targetP = new Vector3(...targetPMeasurement.point);
    const targetPR = new Vector3(...targetPRMeasurement.point);
    const targetPL = new Vector3(...targetPLMeasurement.point);

    const midAP = calculateMidpoint(targetA, targetP);
    const midML = calculateMidpoint(targetPR, targetPL);

    const centroid = calculateMidpoint(midAP, midML);

    const ap: Vector3 = targetA.subtract(targetP);
    const ml: Vector3 = targetPL.subtract(targetPR);

    return {
      centroid,
      ap,
      ml,
    };
  } else {
    throw new Error('Measurement point not found, cannot evaluate centroid');
  }
};

export const calculateDoubleMeasurementSet = (measurements: IMeasure[]) => {
  const superiorMeasurements: IMeasure[] = measurements.filter(
    (measurement: IMeasure) => measurement.endPlate === EndPlate.Superior,
  );
  const inferiorMeasurements: IMeasure[] = measurements.filter(
    (measurement: IMeasure) => measurement.endPlate === EndPlate.Inferior,
  );

  const supAMeasurement: IMeasure | undefined = superiorMeasurements.find(
    (measurement: IMeasure) => measurement.position === Position.Anterior,
  );
  const supPMeasurement: IMeasure | undefined = superiorMeasurements.find(
    (measurement: IMeasure) => measurement.position === Position.Posterior,
  );
  const supPRMeasurement: IMeasure | undefined = superiorMeasurements.find(
    (measurement: IMeasure) => measurement.position === Position.PatientRight,
  );
  const supPLMeasurement: IMeasure | undefined = superiorMeasurements.find(
    (measurement: IMeasure) => measurement.position === Position.PatientLeft,
  );

  const infAMeasurement: IMeasure | undefined = inferiorMeasurements.find(
    (measurement: IMeasure) => measurement.position === Position.Anterior,
  );
  const infPMeasurement: IMeasure | undefined = inferiorMeasurements.find(
    (measurement: IMeasure) => measurement.position === Position.Posterior,
  );
  const infPRMeasurement: IMeasure | undefined = inferiorMeasurements.find(
    (measurement: IMeasure) => measurement.position === Position.PatientRight,
  );
  const infPLMeasurement: IMeasure | undefined = inferiorMeasurements.find(
    (measurement: IMeasure) => measurement.position === Position.PatientLeft,
  );

  if (
    supAMeasurement &&
    supPMeasurement &&
    supPRMeasurement &&
    supPLMeasurement &&
    infAMeasurement &&
    infPMeasurement &&
    infPRMeasurement &&
    infPLMeasurement
  ) {
    const supA = new Vector3(...supAMeasurement.point);
    const supP = new Vector3(...supPMeasurement.point);
    const supPR = new Vector3(...supPRMeasurement.point);
    const supPL = new Vector3(...supPLMeasurement.point);

    const infA = new Vector3(...infAMeasurement.point);
    const infP = new Vector3(...infPMeasurement.point);
    const infPR = new Vector3(...infPRMeasurement.point);
    const infPL = new Vector3(...infPLMeasurement.point);

    const divisor = new Vector3(2, 2, 2);

    //calculate the AP and ML mean between the superior and inferior bodies
    const superiorAP: Vector3 = supA.subtract(supP);
    const inferiorAP: Vector3 = infA.subtract(infP);
    const ap: Vector3 = superiorAP.add(inferiorAP).divide(divisor);
    const superiorML: Vector3 = supPL.subtract(supPR);
    const inferiorML: Vector3 = infPL.subtract(infPR);
    const ml: Vector3 = superiorML.add(inferiorML).divide(divisor);

    const midpointA = calculateMidpoint(supA, infA);
    const midpointP = calculateMidpoint(supP, infP);
    const midpointPR = calculateMidpoint(supPR, infPR);
    const midpointPL = calculateMidpoint(supPL, infPL);

    const midpointAP = calculateMidpoint(midpointA, midpointP);
    const midpointML = calculateMidpoint(midpointPR, midpointPL);
    const centroid = calculateMidpoint(midpointAP, midpointML);

    return {
      centroid,
      ap,
      ml,
    };
  } else {
    throw new Error('Measurement point not found, cannot evaluate centroid');
  }
};

export const calculateCorrectionData = (
  measurements: IMeasure[],
): AutoCorrectionEvaluatedCorrectionType => {
  let evaluatedMeasurementData;

  if (measurements.length === MeasurementSetCount.Double) {
    evaluatedMeasurementData = calculateDoubleMeasurementSet(measurements);
  } else {
    evaluatedMeasurementData = calculateSingleMeasurementSet(measurements);
  }

  if (evaluatedMeasurementData) {
    const { centroid, ap, ml } = evaluatedMeasurementData;

    //define disc coordinate system
    const yPrime: Vector3 = ml.cross(ap).normalize();
    const zPrime: Vector3 = ml.cross(yPrime).normalize();
    const xPrime: Vector3 = ap.cross(yPrime).normalize();

    //define origin for each axis
    const xOrigin = new Vector3(1, 0, 0);
    const yOrigin = new Vector3(0, 1, 0);
    const zOrigin = new Vector3(0, 0, 1);

    //define rotational matrix points
    const A = Math.cos(calculateAngleBetweenVectors(xPrime, xOrigin, Axis.X));
    const B = Math.cos(calculateAngleBetweenVectors(xPrime, yOrigin, Axis.Y));
    const C = Math.cos(calculateAngleBetweenVectors(xPrime, zOrigin, Axis.Z));
    const D = Math.cos(calculateAngleBetweenVectors(yPrime, xOrigin, Axis.X));
    const E = Math.cos(calculateAngleBetweenVectors(yPrime, yOrigin, Axis.Y));
    const F = Math.cos(calculateAngleBetweenVectors(yPrime, zOrigin, Axis.Z));
    const G = Math.cos(calculateAngleBetweenVectors(zPrime, xOrigin, Axis.X));
    const H = Math.cos(calculateAngleBetweenVectors(zPrime, yOrigin, Axis.Y));
    const I = Math.cos(calculateAngleBetweenVectors(zPrime, zOrigin, Axis.Z));

    //create rotational matrix
    const rotationalMatrix: Matrix = Matrix.FromValues(
      A,
      B,
      C,
      0,
      D,
      E,
      F,
      0,
      G,
      H,
      I,
      0,
      0,
      0,
      0,
      1,
    );

    const quaternion: Quaternion = new Quaternion();
    const rotationQuaternion: Quaternion = quaternion.fromRotationMatrix(rotationalMatrix);
    const eulerAngles: Vector3 = rotationQuaternion.toEulerAngles();

    return {
      centroid,
      yPrime,
      zPrime,
      xPrime,
      eulerAngles,
    };
  } else {
    throw new Error('Measurement data not found, cannot evaluate correction data');
  }
};

export const inRange = (
  value: number | string,
  min?: number | string | null,
  max?: number | string | null,
): boolean => {
  let result = false;

  const numValue = Number(value);

  if (min && max) {
    result = numValue < Number(min) || numValue > Number(max);
  } else if (min) {
    result = numValue < Number(min);
  } else if (max) {
    result = numValue > Number(max);
  }

  return result;
};

export const evaluateInterVertebralHeight = (
  superior: Vector3,
  inferior: Vector3,
  normal: Vector3,
  centroid: Vector3,
): number => {
  const A: number = normal.x;
  const B: number = normal.y;
  const C: number = normal.z;

  const X: number = centroid.x;
  const Y: number = centroid.y;
  const Z: number = centroid.z;

  const D: number = -(A * X + B * Y + C * Z);

  const distanceInferior: number =
    (A * inferior.x + B * inferior.y + C * inferior.z + D) / Math.sqrt(A ** 2 + B ** 2 + C ** 2);

  const distanceSuperior: number =
    (A * superior.x + B * superior.y + C * superior.z + D) / Math.sqrt(A ** 2 + B ** 2 + C ** 2);

  return distanceInferior - distanceSuperior;
};

export const evaluateMean = (numbers: number[]) => {
  return numbers.reduce((acc, val) => acc + val, 0) / numbers.length;
};

export const evaluateStandardDeviation = (numbers: number[]) => {
  const mean: number = evaluateMean(numbers);
  const squaredDiffs: number[] = numbers.map((num) => (num - mean) ** 2);
  const meanOfSquares: number =
    squaredDiffs.reduce((acc, val) => acc + val, 0) / squaredDiffs.length;

  return Math.sqrt(Math.abs(meanOfSquares));
};

export function formatNumber(
  value: number | null,
  defaultValue: any,
  digits?: number,
  isDegrees?: boolean,
): string {
  function roundToDecimals(num: number, decimals: number) {
    let factor = Math.pow(10, decimals);
    return Math.round(num * factor) / factor;
  }

  if (value !== null && isFinite(value)) {
    return `${roundToDecimals(value, digits !== undefined ? digits : 2)}${isDegrees ? '°' : ''}`;
  }

  return defaultValue ?? 0;
}

export function calculateDistanceBetweenPointsAlongAxis(
  point1: number[],
  point2: number[],
  axis: Axis,
) {
  let distance = 0;

  if (axis === Axis.X) {
    distance = point1[0] - point2[0];
  }
  if (axis === Axis.Y) {
    distance = point1[1] - point2[1];
  }
  if (axis === Axis.Z) {
    distance = point1[2] - point2[2];
  }
  return Math.abs(distance);
}

export function getMidpoint(vectorA: Vector3, vectorB: Vector3): Vector3 {
  const difference = vectorB.subtract(vectorA);
  const midpoint = difference.divide(new Vector3(2, 2, 2));

  return vectorA.add(midpoint);
}

export function calculateDistanceBetweenPoints(point1: number[], point2: number[]) {
  return vectorFromArray(point1).subtract(vectorFromArray(point2)).length();
}

/**
 * @deprecated This method has been superseded by calculateAngleBetweenPointsAlongPlane
 */
export function calculateAngleBetweenPoints(
  point1: number[],
  point2: number[],
  point3: number[],
  point4: number[],
) {
  const apVector1 = vectorFromArray(point1).subtract(vectorFromArray(point2)).normalize();
  const apVector2 = vectorFromArray(point3).subtract(vectorFromArray(point4)).normalize();

  const normal = Vector3.Cross(apVector1, apVector2);
  const abv = Vector3.GetAngleBetweenVectors(apVector1, apVector2, normal);
  return new Angle(abv).degrees();
}

export function calculateAngleBetweenPointsAlongPlane(
  line1: { point1: number[]; point2: number[] },
  line2: { point1: number[]; point2: number[] },
  axis: Axis,
) {
  const ap1 = vectorFromArray(line1.point1).subtract(vectorFromArray(line1.point2));

  const ap2 = vectorFromArray(line2.point1).subtract(vectorFromArray(line2.point2));

  // STEP 1 - figure out the normal from the plane
  let normal = Vector3.Zero();
  switch (axis) {
    case Axis.X:
      normal = Vector3.Cross(ap1, new Vector3(1, 0, 0)).normalize();
      break;
    case Axis.Y:
      normal = Vector3.Cross(ap1, new Vector3(0, 1, 0)).normalize();
      break;
    case Axis.Z:
      normal = Vector3.Cross(ap1, new Vector3(0, 0, 1)).normalize();
      break;
  }

  // STEP 2 - project ap2 against the normal
  const ap2DotProduct = Vector3.Dot(ap2, normal);
  const projAp2 = ap2.subtract(
    normal.multiplyByFloats(ap2DotProduct, ap2DotProduct, ap2DotProduct),
  );

  // STEP 3 - calculate the angle
  const radians = Vector3.GetAngleBetweenVectors(ap1, projAp2, normal);
  const angle = new Angle(radians).degrees();

  // TODO: figure out how to not do this angle hack. The issue is that all
  // vectors need to be the same direction regardless of the line1 / line2
  // order. Is there an abs function for vectors?
  return angle <= 90 ? angle : 360 - angle;
}

export function vectorFromArray(point: number[]): Vector3 {
  if (!point) {
    return Vector3.Zero();
  }
  return new Vector3(point[0], point[1], point[2]);
}

export function inRangeCheck(
  value: number | string,
  min?: number | string | null,
  max?: number | string | null,
): boolean {
  const numValue = Number(value);
  return numValue >= Number(min) && numValue <= Number(max);
}

export function difference(firstNumber: number, secondNumber: number): number {
  return firstNumber - secondNumber;
}
