import { useMutation } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import {
  AssetType,
  CaseSpineProfile,
  CaseSpineType,
  CaseStageType,
  CaseType,
  ICase,
  LevelType,
  Permission,
  caseUtils,
  format,
} from '@workflow-nx/common';
import { ProgressButton } from '@workflow-nx/ui';
import { date } from '@workflow-nx/utils';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import CustomDialog from '../../../../components/CustomDialog';
import { LabelledProgressBar } from '../../../../components/LabelledProgressBar';
import { TagDetailsForm, TagOption } from '../../../../components/TagDetailsForm';
import { UPDATE_CASE } from '../../../../gql';
import useAuth from '../../../../hooks/useAuth';
import useCreateAndUploadAsset from '../../../../hooks/useCreateAndUploadAsset';
import { AdditionalCaseDetailsForm } from '../../ListCasesView/CreateCase/AdditionalCaseDetailsForm';
import { BasicCaseDetailsForm } from '../../ListCasesView/CreateCase/BasicCaseDetailsForm';
import { InterbodyCaseDetailsForm } from '../../ListCasesView/CreateCase/InterbodyCaseDetailsForm';
import { PatientCaseDetailsForm } from '../../ListCasesView/CreateCase/PatientCaseDetailsForm';
import { StandingXrayCaseDetailsForm } from '../../ListCasesView/CreateCase/StandingXrayCaseDetailsForm';
import {
  updateCaseDetailsFormValues,
  updateCaseDetailsSchema,
} from './EditCaseDetailsDialog.helpers';

const convertNullOrNumberString = (val: any, defaultValue?: null | undefined) => {
  if (_.isNull(val)) return null;

  return val ? Number(val) : defaultValue;
};

export function EditCaseDetailsDialog({
  activeCase,
  open,
  onClose,
}: {
  open: boolean;
  activeCase: ICase;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [updateCase] = useMutation(UPDATE_CASE);
  const { createAndUploadAsset, uploadProgress } = useCreateAndUploadAsset();
  const [uploadingDicom, setUploadingDicom] = useState(false);
  const [uploadingLabel, setUploadingLabel] = useState('Uploading file...');
  const [updatedSpineProfile, setUpdatedSpineProfile] = useState<CaseSpineProfile | null>(null);

  const handleSubmitForm = async (
    values: FormikValues,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    try {
      const updatedCaseNumberPrefix = caseUtils.getCaseNumberPrefix(
        values.firstName,
        values.lastName,
        date.parseISO(activeCase.receivedAt as string),
      );

      if (!activeCase.number.startsWith(updatedCaseNumberPrefix)) {
        await confirm({
          title: `Update Case Number?`,
          description: (
            <>
              Updating this case will change the case number to{' '}
              <strong>{updatedCaseNumberPrefix}.XX</strong>. Are you sure you wish to continue?
            </>
          ),
        });
      }
    } catch {
      return;
    }

    try {
      const surgeryDate = DateTime.fromJSDate(values.surgeryDate).toISODate();
      const patientBirthDate = DateTime.fromJSDate(values.birthDate).toISODate();

      const levels = {
        [LevelType.C2C3]: values.levelC2C3,
        [LevelType.C3C4]: values.levelC3C4,
        [LevelType.C4C5]: values.levelC4C5,
        [LevelType.C5C6]: values.levelC5C6,
        [LevelType.C6C7]: values.levelC6C7,
        [LevelType.C7T1]: values.levelC7T1,
        [LevelType.C7C8]: values.levelC7C8,
        [LevelType.C8T1]: values.levelC8T1,
        [LevelType.C6T1]: values.levelC6T1,
        [LevelType.L1L2]: values.levelL1L2,
        [LevelType.L2L3]: values.levelL2L3,
        [LevelType.L3L4]: values.levelL3L4,
        [LevelType.L4L5]: values.levelL4L5,
        [LevelType.L5S1]: values.levelL5S1,
        [LevelType.L5L6]: values.levelL5L6,
        [LevelType.L6S1]: values.levelL6S1,
        [LevelType.L4S1]: values.levelL4S1,
      };
      const levelsMetadata = {
        [LevelType.C2C3]: values.levelC2C3Metadata || undefined,
        [LevelType.C3C4]: values.levelC3C4Metadata || undefined,
        [LevelType.C4C5]: values.levelC4C5Metadata || undefined,
        [LevelType.C5C6]: values.levelC5C6Metadata || undefined,
        [LevelType.C6C7]: values.levelC6C7Metadata || undefined,
        [LevelType.C7T1]: values.levelC7T1Metadata || undefined,
        [LevelType.C7C8]: values.levelC7C8Metadata || undefined,
        [LevelType.C8T1]: values.levelC8T1Metadata || undefined,
        [LevelType.C6T1]: values.levelC6T1Metadata || undefined,
        [LevelType.L1L2]: values.levelL1L2Metadata || undefined,
        [LevelType.L2L3]: values.levelL2L3Metadata || undefined,
        [LevelType.L3L4]: values.levelL3L4Metadata || undefined,
        [LevelType.L4L5]: values.levelL4L5Metadata || undefined,
        [LevelType.L5S1]: values.levelL5S1Metadata || undefined,
        [LevelType.L5L6]: values.levelL5L6Metadata || undefined,
        [LevelType.L6S1]: values.levelL6S1Metadata || undefined,
        [LevelType.L4S1]: values.levelL4S1Metadata || undefined,
      };

      const dicomTypes = [
        {
          file: values.dicomXray,
          metadata: values.dicomXrayMetadata,
          assetType: AssetType.DicomXray,
        },
        { file: values.dicomCt, metadata: values.dicomCtMetadata, assetType: AssetType.DicomCt },
        { file: values.dicomMri, metadata: values.dicomMriMetadata, assetType: AssetType.DicomMri },
      ];
      const assets: {
        fileName: string;
        size: number;
        assetType: AssetType;
        metadata: any;
        file: File;
      }[] = [];
      dicomTypes.forEach((dicom) => {
        if (dicom.file && dicom.metadata) {
          assets.push({
            fileName: dicom.file.name,
            size: dicom.file.size,
            assetType: dicom.assetType,
            metadata: dicom.metadata,
            file: dicom.file,
          });
        }
      });

      const containsCtDicom = assets.some((dicom) => dicom.assetType === AssetType.DicomCt);
      const containsPreviousCtDicom = activeCase?.assets?.some(
        (dicom) => dicom.assetType === AssetType.DicomCt,
      );
      if (values.caseType === CaseType.Live) {
        let missingFields = false;
        if (!values.surgeonId) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases require a surgeon
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }
        if (!values.assignedId) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases require a designer
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }
        if (!values.locationId) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases require a location
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }

        const validLevels = Object.values(levels)?.find((level) =>
          caseUtils.isValidLevelPartType(level),
        );
        if (!validLevels) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases require at least one valid level part type
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }

        if (!containsCtDicom && !containsPreviousCtDicom) {
          enqueueSnackbar(
            <p>
              <b>LIVE</b> cases need a DICOM CT - otherwise set the case type to <b>DRAFT</b>
            </p>,
            {
              variant: 'error',
            },
          );
          missingFields = true;
        }
        if (missingFields) {
          return;
        }
      }

      for (const asset of assets) {
        setUploadingDicom(true);
        setUploadingLabel(`Uploading ${format.formatAssetType(asset.assetType)} file...`);

        const createdAsset = await createAndUploadAsset(
          asset.file as File,
          asset.assetType,
          activeCase.caseId,
          undefined,
          asset.metadata,
          activeCase.caseType === CaseType.Live,
        );

        if (createdAsset?.error) {
          console.error(createdAsset?.error);
          enqueueSnackbar(`Upload failed for asset: ${asset.assetType}`, {
            variant: 'error',
          });
        }
      }

      await updateCase({
        variables: {
          approach: values.approach === '' ? null : values.approach,
          caseId: activeCase.caseId,
          assignedId: convertNullOrNumberString(values.assignedId),
          fieldRepId: convertNullOrNumberString(values.fieldRepId),
          isSurgeryDateTentative: !!values.isSurgeryDateTentative,
          caseType: values.caseType,
          spineProfile: updatedSpineProfile ? values.spineProfile : undefined,
          levels,
          levelsMetadata,
          surgeryDate,
          surgeonId: convertNullOrNumberString(values.surgeonId),
          locationId: convertNullOrNumberString(values.locationId),
          pso: values.pso,
          pco: values.pco,
          uiv: values.uiv,
          liv: values.liv,
          pelvicIncidence: convertNullOrNumberString(values.pelvicIncidence, null),
          slopeOfLineOfSight: convertNullOrNumberString(values.slopeOfLineOfSight, null),
          lumbarLordosis: convertNullOrNumberString(values.lumbarLordosis, null),
          lumbarCoronalCobb: convertNullOrNumberString(values.lumbarCoronalCobb, null),
          sagittalVerticalAxis: convertNullOrNumberString(values.sagittalVerticalAxis, null),
          coronalBalance: convertNullOrNumberString(values.coronalBalance, null),
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          gender: values.gender || undefined,
          birthDate: patientBirthDate,
          mrn: values.mrn,
          tags: (values.tags || []).map((tag: TagOption) => tag.key),
          excludedInstruments: values.excludedInstruments,
        },
      });

      setStatus({ success: true });
      enqueueSnackbar('Case Details Updated', {
        variant: 'success',
      });

      onClose(true);
    } catch (err: any) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      if (err.graphQLErrors?.[0]?.extensions?.code) {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred updating the case details', {
          variant: 'error',
        });
      }
    } finally {
      setSubmitting(false);
      setUploadingDicom(false);
    }
  };

  const isPreProductionEditable = caseUtils.isCaseInPreProduction(activeCase.stage);
  const isPostProductionEditable =
    auth?.hasPermission?.([Permission.ManageCase]) && activeCase.stage !== CaseStageType.Complete;

  const isCaseTypeDisabled =
    !_.includes(
      [CaseStageType.Open, CaseStageType.Segmentation, CaseStageType.Planning],
      activeCase.stage,
    ) ||
    (activeCase.caseType !== CaseType.Draft && activeCase.caseType !== CaseType.Live);

  const isSpineProfileDisabled =
    (activeCase.stage !== CaseStageType.Open && activeCase.stage !== CaseStageType.Segmentation) ||
    !auth?.hasPermission?.([Permission.ManageCase]);

  const updatedFormValues = updateCaseDetailsFormValues(activeCase);
  return (
    <Formik
      initialValues={updatedFormValues}
      validationSchema={updateCaseDetailsSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, submitForm, setFieldValue }) => {
        const loading = uploadingDicom;
        const disabled = isSubmitting || loading;

        return (
          <CustomDialog
            maxWidth={'lg'}
            open={open}
            title={`Editing Case ${activeCase.number}`}
            onClose={() => {
              onClose(false);
            }}
            positiveActionButtons={[
              <ProgressButton
                onClick={() => submitForm()}
                loading={isSubmitting}
                disabled={isSubmitting}
                label={'Save'}
              />,
            ]}
          >
            {open ? (
              <form onSubmit={handleSubmit}>
                {uploadingDicom ? (
                  <Box mb={2}>
                    <LabelledProgressBar
                      label={uploadingLabel}
                      variant={'determinate'}
                      value={uploadProgress}
                      fullWidth
                    />
                  </Box>
                ) : null}
                <BasicCaseDetailsForm
                  caseId={activeCase.caseId}
                  surgeonId={activeCase?.surgeonUser?.userId}
                  locationId={activeCase?.location?.locationId}
                  loading={loading}
                  disabled={disabled || (!isPreProductionEditable && !isPostProductionEditable)}
                  disabledFields={{
                    spineType: true,
                    spineProfile: isSpineProfileDisabled,
                    caseType: isCaseTypeDisabled,
                  }}
                  onSurgeonChange={() => {
                    // const approach = surgeon?.preferencesOld?.mismatchCorrectionGoalType;
                    // setFieldValue('approach', approach);
                  }}
                  onSpineProfileChange={(spineProfileChange: CaseSpineProfile) => {
                    setUpdatedSpineProfile(spineProfileChange);
                    for (const ele in LevelType) {
                      setFieldValue(`level${ele}`, 'NONE');
                      setFieldValue(`level${ele}Metadata.anteriorInstrumentation`, undefined);
                      setFieldValue(`level${ele}Metadata.otherVendor`, undefined);
                    }
                  }}
                />
                <Box my={2}>
                  <TagDetailsForm />
                </Box>
                <Box my={2}>
                  <Typography variant={'h5'}>Patient Details</Typography>
                </Box>
                <PatientCaseDetailsForm
                  activeCase={activeCase}
                  assets={activeCase.assets}
                  loading={disabled}
                  disabled={loading}
                />
                <Box my={2}>
                  <Typography variant={'h5'}>X-Ray Case Details</Typography>
                </Box>
                <StandingXrayCaseDetailsForm
                  caseSpineType={activeCase.spineType}
                  disabled={loading}
                />

                <Box my={2}>
                  <Typography variant={'h5'}>Case Details</Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InterbodyCaseDetailsForm
                      caseSpineProfile={updatedSpineProfile ?? activeCase.spineProfile}
                      disabled={disabled && !isPreProductionEditable && !isPostProductionEditable}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AdditionalCaseDetailsForm
                      caseSpineType={activeCase.spineType ?? CaseSpineType.Lumbar}
                      caseSpineProfile={activeCase.spineProfile ?? CaseSpineProfile.LumbarStandard}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              </form>
            ) : null}
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
