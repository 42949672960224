import { useMutation } from '@apollo/client';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@mui/material';
import { CloudDesignStatus, ICloudDesignQueue, LevelType, format } from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { LabelledProgressBar } from '../../../../../components/LabelledProgressBar';
import { DELETE_EXPORT_CLOUD_DESIGN } from '../../../../../gql';

export function ImplantListProgressView(props: {
  level: LevelType;
  onCutCancel: () => Promise<void>;
  cutCancelVisible: boolean;
  cloudDesignQueue: ICloudDesignQueue | undefined;
}) {
  const [deleteExportCloudDesign] = useMutation(DELETE_EXPORT_CLOUD_DESIGN);
  const [isCancellingCutLevel, setIsCancellingCutLevel] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  function getEstimatedTime(estimatedDuration: number, startedAt: string): string {
    const remaining = estimatedDuration - date.distanceInMinutes(startedAt);

    if (remaining > 1) {
      return `${remaining}m remaining`;
    }
    if (remaining === 0 || remaining === 1) {
      return `< 1m remaining`;
    }
    return `Overdue by ${Math.abs(remaining)} minute(s)`;
  }

  const confirm = useConfirm();

  const handleCutCancelImplantClick = async () => {
    try {
      if (!props.cloudDesignQueue) {
        return;
      }

      if (props.cloudDesignQueue?.status === CloudDesignStatus.Processing) {
        await confirm({
          title: `Cancel cutting on ${format.formatLevelType(props.level)} ?`,
          description: (
            <>
              This will cancel the cutting of the implant at level{' '}
              {format.formatLevelType(props.level)}. Are you sure you wish to continue?
            </>
          ),
        });
      }

      setIsCancellingCutLevel(true);

      deleteExportCloudDesign({
        variables: {
          cloudDesignQueueId: props.cloudDesignQueue?.cloudDesignQueueId,
        },
      }).finally(() => {
        setIsCancellingCutLevel(false);
        enqueueSnackbar(`The cloud design operation has been cancelled`, { variant: 'success' });
        props.onCutCancel();
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred cancelling the cloud design operation', {
        variant: 'error',
      });
    }
  };

  return (
    <Box display={'flex'} justifyContent={'center'} width={'100%'} alignItems={'center'}>
      {!isCancellingCutLevel && props.cloudDesignQueue ? (
        <>
          <LabelledProgressBar
            label={`Cutting - ${
              props.cloudDesignQueue?.startedAt
                ? getEstimatedTime(6, props.cloudDesignQueue?.startedAt as unknown as string)
                : ''
            }`}
            variant={'indeterminate'}
            fullWidth={true}
          />
          <Box mx={1} />
          {props.cutCancelVisible ? (
            <IconButton
              onClick={async () => {
                await handleCutCancelImplantClick();
              }}
              size="large"
            >
              <FontAwesomeIcon icon={faTimes} size={'sm'} />
            </IconButton>
          ) : null}
        </>
      ) : null}
      {isCancellingCutLevel ? <Typography>Cancelling cut...</Typography> : null}
    </Box>
  );
}
