import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';
import { UPSERT_SETTINGS } from '../../gql';
import { EmailSettings, format, IUser, UserRoleItemType, UserRoleType } from '@workflow-nx/common';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { Box, Typography } from '@mui/material';
import { Button, CalloutView, IconFontButton, UserRoleAutocomplete } from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import * as Yup from 'yup';
import { gql } from '@apollo/client/core';
import { sortBy } from 'lodash';

export function EmailSettingsForm(props: {
  emails: EmailSettings;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [upsertSettings] = useMutation(UPSERT_SETTINGS);
  const [open, setOpen] = useState(false);
  const [usersAndRoles, setUsersAndRoles] = useState<UserRoleItemType[]>([]);

  useQuery(
    gql`
      query {
        users(
          roleFilter: [
            CASE_ADMIN
            CASE_ENGINEER
            CUSTOMER_REP
            CUSTOMER_REP_MANAGER
            FINANCE
            FIELD_REP
            FIELD_REP_MANAGER
            OPERATIONS
            SITE_ADMINISTRATOR
            QUALITY_ENGINEER
            VIEWER
          ]
        ) {
          users {
            userId
            firstName
            lastName
            email
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const roles: UserRoleItemType[] = [];
        const users: UserRoleItemType[] = [];

        roles.push({
          key: UserRoleType.CaseEngineer,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.CaseEngineer),
        });
        roles.push({
          key: UserRoleType.CustomerRep,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.CustomerRep),
        });
        roles.push({
          key: UserRoleType.CustomerRepManager,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.CustomerRepManager),
        });
        roles.push({
          key: UserRoleType.FieldRep,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.FieldRep),
        });
        roles.push({
          key: UserRoleType.FieldRepManager,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.FieldRepManager),
        });
        roles.push({
          key: UserRoleType.CaseAdmin,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.CaseAdmin),
        });
        roles.push({
          key: UserRoleType.QualityEngineer,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.QualityEngineer),
        });
        roles.push({
          key: UserRoleType.Finance,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.Finance),
        });
        roles.push({
          key: UserRoleType.Operations,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.Operations),
        });
        roles.push({
          key: UserRoleType.SiteAdministrator,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.SiteAdministrator),
        });
        roles.push({
          key: UserRoleType.SupplyChain,
          type: 'ROLE',
          value: format.formatUserRole(UserRoleType.SupplyChain),
        });

        data?.users?.users.forEach((user: IUser) => {
          users.push({
            key: user.userId.toString(),
            type: 'USER',
            value: `${format.formatName(user)} <${user.email}>`,
          });
        });

        setUsersAndRoles(sortBy(roles, ['value']).concat(sortBy(users, ['value'])));
      },
    },
  );

  const handleClose = () => {
    if (open) {
      props.onClose(false);
    }
    setOpen(!open);
  };

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    try {
      await upsertSettings({
        variables: {
          emails: values.emails,
        },
      });

      setOpen(false);
      setStatus({ success: true });
      enqueueSnackbar('Notifications updated', {
        variant: 'success',
      });
      props.onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the notifications', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleFormikErrors = (values: FormikErrors<{ emails: EmailSettings }>) => {
    if (values.emails && Object.keys(values).length) {
      for (const [, value] of Object.entries(values.emails)) {
        enqueueSnackbar(`${value}`, {
          variant: 'error',
        });
      }
    }
  };

  const yupKeyValueSchema = Yup.array().of(
    Yup.object().shape({
      key: Yup.string(),
      type: Yup.string(),
      value: Yup.string(),
    }),
  );
  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Configure Notifications</Typography>
        <Box>
          <IconFontButton
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            onClick={handleClose}
          />
        </Box>
      </Box>
      {open ? (
        <Box m={1}>
          <Formik
            initialValues={{ emails: props.emails }}
            validationSchema={Yup.object().shape({
              emails: Yup.object().shape({
                draftCaseCreated: yupKeyValueSchema,
                caseCreated: yupKeyValueSchema,
                caseUpdated: yupKeyValueSchema,
                taskUpdated: yupKeyValueSchema,
                caseProposed: yupKeyValueSchema,
                peerReview: yupKeyValueSchema.required('Peer review recipients are required'),
                qaReview: yupKeyValueSchema.required('QA review recipients are required'),
                qaApproved: yupKeyValueSchema.required('QA approved recipients are required'),
                caseFinalRelease: yupKeyValueSchema,
                caseCompleted: yupKeyValueSchema,
                postOpAnalysisImagingUpdated: yupKeyValueSchema,
                caseCancelled: yupKeyValueSchema,
                surgeonCreated: yupKeyValueSchema,
                surgeryDateChanged: yupKeyValueSchema,
              }),
            })}
            onSubmit={handleSubmitForm}
            enableReinitialize={true}
          >
            {({ submitForm, errors, touched, setFieldTouched }) => (
              <Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Draft Case Created</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent whenever a new draft case is created.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Draft case created recipients'}
                    name={'emails.draftCaseCreated'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Case Created</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent whenever a new case is created.{' '}
                      <strong>{`The assigned user and ${format.formatUserRole(
                        UserRoleType.FieldRep,
                      )} will always receive this.`}</strong>
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional case created recipients'}
                    name={'emails.caseCreated'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Case Updated</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent a case is updated, the case imaging is updated
                      or the case study date has changed.{' '}
                      <strong>{`The assigned user and ${format.formatUserRole(
                        UserRoleType.FieldRep,
                      )} will always receive this.`}</strong>
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional case updated recipients'}
                    name={'emails.caseUpdated'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Surgery Date Changed</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent after a case surgery date has been changed.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional Surgery Date Changed recipients'}
                    name={'emails.surgeryDateChanged'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Case Segmented</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent after a case has completed segmentation.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional case segmentation recipients'}
                    name={'emails.caseSegmented'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Case Proposed</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent when a case moves to the PROPOSED stage.{' '}
                      <strong>{`The assigned ${format.formatUserRole(
                        UserRoleType.FieldRep,
                      )} members will always receive this.`}</strong>
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional case proposed recipients'}
                    name={'emails.caseProposed'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Case Approved</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent a case is approved.
                      <strong>{`The assigned user and ${format.formatUserRole(
                        UserRoleType.FieldRep,
                      )} members will always receive this.`}</strong>
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional case approved recipients'}
                    name={'emails.caseApproved'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  {errors.emails?.peerReview ? (
                    <>
                      <Typography color="error" variant={'h5'}>
                        Case Ready For Peer Review
                      </Typography>
                    </>
                  ) : (
                    <Typography variant={'h5'}>Case Ready For Peer Review</Typography>
                  )}
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent when a case has been approved.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Case peer review recipients'}
                    name={'emails.peerReview'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  {errors.emails?.qaReview ? (
                    <Typography variant={'h5'} color="error">
                      Case Ready For QA Review
                    </Typography>
                  ) : (
                    <Typography variant={'h5'}>Case Ready For QA Review</Typography>
                  )}
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent when a case moves to the QA REVIEW stage.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Case ready for QA review recipients'}
                    name={'emails.qaReview'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  {errors.emails?.qaApproved ? (
                    <Typography color="error" variant={'h5'}>
                      Case QA Approved
                    </Typography>
                  ) : (
                    <Typography variant={'h5'}>Case QA Approved</Typography>
                  )}
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent when a case moves to the PRODUCTION stage.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'QA Approved recipients'}
                    name={'emails.qaApproved'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Case Final Release</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent when the FINAL RELEASE production event has
                      been created.{' '}
                      <strong>{`The assigned ${format.formatUserRole(
                        UserRoleType.FieldRep,
                      )} members will always receive this.`}</strong>
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional case final release recipients'}
                    name={'emails.caseFinalRelease'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Case Completed</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent when the case results have been filled in for
                      the case.{' '}
                      <strong>{`The assigned user and ${format.formatUserRole(
                        UserRoleType.FieldRep,
                      )} members will always receive this.`}</strong>
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional case final release recipients'}
                    name={'emails.caseCompleted'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Task Updated</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent whenever a task is created, updated, completed
                      or deleted.{' '}
                      <strong>The user who created this task will always receive this.</strong>
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional task updated recipients'}
                    name={'emails.taskUpdated'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Post-Op Analysis Imaging Updated</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent when post-op analysis imaging is updated.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Post-Op Analysis Imaging Updated recipients'}
                    name={'emails.postOpAnalysisImagingUpdated'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Lost Opportunity</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent when an opportunity is lost.{' '}
                      <strong>{`The assigned user and ${format.formatUserRole(
                        UserRoleType.FieldRep,
                      )} members will always receive this.`}</strong>
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional lost opportunity recipients'}
                    name={'emails.caseCancelled'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>New Surgeon</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent when a new surgeon is added.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'New Surgeon recipients'}
                    name={'emails.surgeonCreated'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>DaiSY Auto-Segmentation</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      This notification will be sent after DaiSY has completed the segmentation
                      process.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Additional DaiSY auto-segmentation recipients'}
                    name={'emails.caseAutoSegmented'}
                    options={usersAndRoles}
                  />
                </Box>
                <Box mt={4} display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    label={'Save Notification Preferences'}
                    onClick={() => {
                      submitForm();
                      setFieldTouched('emails.peerReview', true);
                      setFieldTouched('emails.qaReview', true);
                      setFieldTouched('emails.qaApproved', true);
                      handleFormikErrors(errors);
                    }}
                    disabled={!Object.keys(touched).length}
                  />
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      ) : null}
    </>
  );
}
