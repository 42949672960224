import { Box, Button, colors, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Dispatch, useState } from 'react';
import { AssetType, IAsset, ValidFileExtensions } from '@workflow-nx/common';
import { AssetGridTableRow } from './AssetGridTableRow';
import { AssetGridDropZoneTableRow } from './AssetGridDropZoneTableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons';
import { useMutation } from '@apollo/client';
import { CREATE_ASSET_DOWNLOAD_URL } from '../../gql';
import { useSnackbar } from 'notistack';
import { ProgressDialog } from '@workflow-nx/ui';
import { file } from '@workflow-nx/utils';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'grid',
    rowGap: 1,
    gridTemplateColumns: 'max-content auto auto max-content max-content max-content',
    alignItems: 'center',
    backgroundColor: theme.palette.divider,
  },
  gridHeaderCell: {
    backgroundColor: 'white',
    height: 25,
    color: colors.grey[600],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export function AssetGridTable({
  disableDelete,
  allowReplace,
  assets,
  caseId,
  dispatch,
  onZipComplete,
  planId,
  readOnly,
  showAdd = true,
  showDownloadAll,
  showHistory,
  title,
  validAssets,
  validFileExtensions,
}: {
  assets: IAsset[];
  caseId: number;
  title?: string;
  dispatch: Dispatch<any>;
  planId?: number;
  readOnly?: boolean;
  allowReplace?: boolean;
  showAdd?: boolean;
  showHistory?: boolean;
  showDownloadAll?: boolean;
  disableDelete?: boolean;
  validAssets: AssetType[];
  validFileExtensions?: ValidFileExtensions[];
  onZipComplete?: (zipFile: Blob) => void;
}) {
  const styles = useStyles();
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const { enqueueSnackbar } = useSnackbar();
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const [progressState, setProgressState] = useState('');

  function findAsset(assetType: AssetType) {
    return assets.find((asset: IAsset) => asset.assetType === assetType);
  }

  const handleDownloadAll = async () => {
    try {
      setShowProgressDialog(true);
      const assetsToZip = [];

      for (let i = 0; i < validAssets.length; i++) {
        setProgressState(`Downloading ${i + 1} of ${validAssets.length} files`);
        const validAsset = validAssets[i];
        const asset = findAsset(validAsset);

        if (asset) {
          const { data } = await createAssetDownloadUrl({
            variables: {
              assetId: asset?.assetId,
            },
          });

          const response = await file.downloadFile(data.createAssetDownloadUrl.signedUrl);

          const extension = asset.fileName.slice(asset.fileName.lastIndexOf('.'));
          assetsToZip.push({
            blob: new Blob([response.data], { type: 'octet/stream' }),
            name: `${validAsset}.${extension}`,
          });
        }
      }

      setProgressState(`Compressing files`);

      const zipFile = await file.createZipFile(assetsToZip);

      onZipComplete?.(zipFile);
    } catch (e) {
      enqueueSnackbar('Error Downloading File', {
        variant: 'error',
      });
    } finally {
      setShowProgressDialog(false);
      setProgressState('');
    }
  };

  return (
    <Box>
      {showDownloadAll ? (
        <Box display={'flex'} p={1} alignItems={'center'}>
          {title ? (
            <Box>
              <Typography color={'textSecondary'} variant={'button'}>
                <strong>{title}</strong>
              </Typography>
            </Box>
          ) : null}
          <Box flexGrow={1} />
          <Box>
            <Button color={'primary'} variant={'outlined'} onClick={handleDownloadAll}>
              <FontAwesomeIcon icon={faCloudDownload} /> <Box ml={1} />
              Download All
            </Button>
          </Box>
        </Box>
      ) : null}
      <Box className={styles.gridContainer}>
        <Box className={styles.gridHeaderCell}>
          <Typography variant={'body1'}>Asset Type</Typography>
        </Box>
        <Box className={styles.gridHeaderCell}>
          <Typography variant={'body1'}>Filename</Typography>
        </Box>
        <Box className={styles.gridHeaderCell}>
          <Typography variant={'body1'}>Updated</Typography>
        </Box>
        <Box className={styles.gridHeaderCell}>
          <Typography variant={'body1'}>Size</Typography>
        </Box>
        <Box className={styles.gridHeaderCell}>
          <Typography variant={'body1'}>Version</Typography>
        </Box>
        <Box className={styles.gridHeaderCell}>
          <Typography variant={'body1'}>Actions</Typography>
        </Box>
        {validAssets.map((validAsset: AssetType) => {
          const foundAsset = findAsset(validAsset);

          return (
            <React.Fragment key={validAsset}>
              {foundAsset ? (
                <AssetGridTableRow
                  caseId={caseId}
                  allowReplace={!!allowReplace}
                  disableDelete={!!disableDelete}
                  asset={foundAsset}
                  readOnly={!!readOnly}
                  onReplace={(createdAsset: IAsset) =>
                    dispatch({
                      type: 'ASSET_REPLACED',
                      data: createdAsset,
                    })
                  }
                  onDelete={() => {
                    if (!readOnly) {
                      dispatch({
                        type: 'ASSET_DELETED',
                        data: foundAsset,
                      });
                    }
                  }}
                  showHistory={!!showHistory}
                  validFileExtensions={validFileExtensions || []}
                />
              ) : null}
              {!foundAsset ? (
                <AssetGridDropZoneTableRow
                  caseId={caseId}
                  planId={planId}
                  assetType={validAsset}
                  onUpload={(createdAsset: IAsset) =>
                    dispatch({
                      type: 'ASSET_UPLOADED',
                      data: createdAsset,
                    })
                  }
                  validFileExtensions={validFileExtensions || []}
                  readOnly={!!readOnly || !showAdd}
                />
              ) : null}
            </React.Fragment>
          );
        })}
      </Box>
      <ProgressDialog open={showProgressDialog} progressState={progressState} />
    </Box>
  );
}
