import { useLazyQuery, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  format,
  IApplicationSettings,
  ISettings,
  IUser,
  Permission,
  UserRoleType,
  UserStatusType,
} from '@workflow-nx/common';
import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import SplashScreen from '../components/SplashScreen';
import { FIND_USER_CURRENT, VALIDATE_TOKEN, VALIDATE_TOTP } from '../gql';
import { isValidToken } from '../utils/user';
// @ts-ignore
import { isJwtExpired } from 'jwt-check-expiration';
import useAnalytics from '../hooks/useAnalytics';
import { FeatureFlag } from '../utils/featureFlags';
import { TrackableUserEvent } from './AnalyticsContext';

const initialAuthState: AppStateType = {
  isAuthenticated: false,
  isInitialised: false,
  user: undefined,
  settings: undefined,
  externalApps: undefined,
  product: undefined,
};

const setSession = (accessToken: string) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    localStorage.removeItem('jwt_token');
  }
};

type AppActionType = {
  type: 'INITIALISE' | 'LOGIN' | 'LOGOUT';
  payload?: any;
};

type AppStateType = {
  isAuthenticated: boolean;
  isInitialised: boolean;
  user: IUser | undefined;
  settings: ISettings | undefined;
  externalApps: IApplicationSettings['externalApps'] | undefined;
  product: IApplicationSettings['product'] | undefined;
};

const reducer = (state: AppStateType, action: AppActionType) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, settings, externalApps, product } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        settings,
        externalApps,
        product,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        settings: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

type AppContextProps = {
  isDrawerOpen: boolean;
  isAuthenticated: boolean;
  isInitialised: boolean;
  user?: IUser;
  product?: IApplicationSettings['product'];
  externalApps?: IApplicationSettings['externalApps'];
  validate: (accessToken: string, idToken: string) => Promise<UserStatusType | null>;
  validateOneTime: (totp: string) => Promise<UserStatusType | null>;
  logout: () => void;
  refreshCurrentUser: () => void;
  setDrawerState: (open: boolean) => void;
  hasRole: (roles: UserRoleType[]) => boolean;
  hasFeatureFlag: (featureFlag: FeatureFlag) => boolean;
  hasPermission: (permissions: Permission[]) => boolean;
  hasTokenExpired: () => boolean;
};

const AppContext = createContext<Partial<AppContextProps>>({
  ...initialAuthState,
  isDrawerOpen: false,
  validate: (accessToken: string, idToken: string) => Promise.resolve(null),
  validateOneTime: (totp: string) => Promise.resolve(null),
  logout: () => {},
  refreshCurrentUser: () => {},
  hasRole: (roles: UserRoleType[]) => false,
  hasFeatureFlag: (featureFlag: FeatureFlag) => false,
  hasPermission: (permissions: Permission[]) => false,
  hasTokenExpired: () => false,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(
    localStorage.getItem('Dashboard.drawerOpen') === 'true' ?? false,
  );
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [validateToken] = useMutation(VALIDATE_TOKEN);
  const [validateTOTP] = useMutation(VALIDATE_TOTP);
  const [findCurrentUser] = useLazyQuery(FIND_USER_CURRENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: true,
          user: data.currentUser,
          settings: data.settings,
          externalApps: data?.settings?.application?.externalApps ?? {},
          product: data?.settings?.application?.product ?? {},
        },
      });
    },
  });
  const { trackEvent } = useAnalytics();

  const validate = async (accessToken: string, idToken: string): Promise<UserStatusType | null> => {
    try {
      const { data } = await validateToken({
        variables: { accessToken, idToken },
      });

      const { user, token } = data.validateToken;

      if (token) {
        localStorage.setItem('jwt_token', token);
        localStorage.setItem('user', JSON.stringify(user));
        setSession(token);
      }

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

      return user.status;
    } catch (error: any) {
      const userStatusFromError = error?.graphQLErrors?.[0]?.extensions?.status as UserStatusType;
      if (userStatusFromError) {
        return userStatusFromError;
      }
      return null;
    }
  };

  const validateOneTime = async (totp: string): Promise<UserStatusType | null> => {
    try {
      const { data } = await validateTOTP({
        variables: { totp },
      });

      const { user, token } = data.validateTOTP;

      if (token) {
        localStorage.setItem('jwt_token', token);
        localStorage.setItem('user', JSON.stringify(user));
        setSession(token);
      }

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

      return user.status;
    } catch (error: any) {
      const userStatusFromError = error?.graphQLErrors?.[0]?.extensions?.status as UserStatusType;
      if (userStatusFromError) {
        return userStatusFromError;
      }
      return null;
    }
  };

  const setDrawerState = (open: boolean) => {
    setIsDrawerOpen(open);
    localStorage.setItem('Dashboard.drawerOpen', String(open));
  };

  const logout = () => {
    setSession('');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    localStorage.removeItem('jwt_token');
    dispatch({ type: 'LOGOUT' });
    trackEvent(TrackableUserEvent.SignOut);
  };

  const refreshCurrentUser = async () => {
    await findCurrentUser({});
  };

  const hasRole = (roles?: UserRoleType[]) => {
    return (roles ?? []).includes(state?.user?.role);
  };

  const hasFeatureFlag = (featureFlag: FeatureFlag) => {
    return state.settings?.application?.featureFlags?.[featureFlag.toString()];
  };

  const hasPermission = (permissions?: Permission[]) => {
    const userPermissions = (state?.user as IUser)?.permissions;
    let filter = userPermissions?.filter?.((permission) => {
      return permissions?.includes?.(permission);
    });
    return (filter ?? []).length > 0;
  };

  const hasTokenExpired = () => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (!accessToken) {
      return true;
    }

    return isJwtExpired(accessToken);
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          await findCurrentUser({});
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, [findCurrentUser]);

  const currentUserId = (state.user as IUser | undefined)?.userId;

  useEffect(() => {
    const currentUser = state.user as IUser | undefined;

    if (currentUser) {
      const sentryUser: Sentry.User = {
        id: currentUser.userId.toString(),
        email: currentUser.email,
        username: format.formatName(currentUser),
      };

      Sentry.setUser(sentryUser);
    } else {
      Sentry.setUser(null);
    }
  }, [currentUserId]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AppContext.Provider
      value={{
        ...state,
        isDrawerOpen,
        validate,
        validateOneTime,
        logout,
        hasRole,
        hasFeatureFlag,
        hasPermission,
        hasTokenExpired,
        refreshCurrentUser,
        setDrawerState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
