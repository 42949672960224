import { format, ITableHeader, ITask } from '@workflow-nx/common';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { CommonTable } from '../../../components/CommonTable';
import { Globals } from '../../../layouts/DashboardLayout';

const useStyles = makeStyles(() => ({
  row: {
    '& :hover': {
      cursor: 'default',
    },
  },
  column: {
    '& :hover': {
      cursor: 'pointer',
    },
  },
}));

type TaskListTableProps = {
  orderBy: any;
  page: number;
  rowsPerPage: number;
  totalCaseCount: number;
  loading: boolean;
  tasks: ITask[];
  dispatch: any;
  onView: (taskId: number) => void;
};

export function TaskListTable({
  orderBy,
  page,
  rowsPerPage,
  totalCaseCount,
  loading,
  tasks,
  dispatch,
  onView,
}: TaskListTableProps) {
  const styles = useStyles();
  const navigate = useNavigate();
  const headers: ITableHeader[] = [
    {
      id: 'number',
      sortable: false,
      label: 'Case #',
    },
    {
      id: 'priority',
      sortable: true,
      label: 'Priority',
    },
    {
      id: 'description',
      sortable: true,
      label: 'Task',
    },
    {
      id: 'dueDate',
      sortable: true,
      label: 'Due Date',
    },
    {
      id: 'assignedId',
      sortable: true,
      label: 'Assigned To',
    },
    {
      id: 'status',
      sortable: false,
      label: 'Status',
    },
  ];

  const showPagination = Boolean(dispatch && rowsPerPage && page !== undefined);

  return (
    <CommonTable
      orderBy={orderBy}
      headers={headers}
      onHeaderClick={(orderBy) => {
        if (dispatch) {
          dispatch({ type: 'ORDER_BY_CHANGED', data: orderBy });
        }
      }}
      rows={
        <TableBody>
          {tasks?.map((task: ITask) => (
            <TableRow
              hover
              key={task.taskId}
              onClick={() => {
                onView(task.taskId);
              }}
              className={`${styles.row}`}
            >
              <TableCell
                style={{ verticalAlign: 'top' }}
                onClick={() => navigate(`/app/cases/${task?.taskCase?.caseId}#task`)}
                className={`${styles.column}`}
              >
                <Typography variant={'body1'} noWrap color={'primary'}>
                  {task?.taskCase?.number}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {format.formatTaskPriorityType(task?.priority)}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top', maxWidth: '600px' }}>
                <Typography variant={'body1'} noWrap>
                  {task?.description}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {task?.dueDate ? (
                    format.formatISODate(task?.dueDate as unknown as string)
                  ) : (
                    <span>&mdash;</span>
                  )}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {format.formatName(task.assignedUser)}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {format.formatTaskStatusType(task?.status)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      }
      loading={loading}
      paginationConfig={
        showPagination
          ? {
              totalCount: totalCaseCount || 0,
              onPaginationChange: (pagination) => {
                dispatch?.({
                  type: 'UPDATE_PAGINATION',
                  data: {
                    pageSize: pagination.rowsPerPage,
                    pageNumber: pagination.page,
                  },
                });
              },
              rowsPerPage: rowsPerPage || Globals.DefaultListPageSize,
              page: page || 0,
              rowsPerPageOptions: Globals.DefaultListPageSizeOptions,
            }
          : undefined
      }
    />
  );
}
