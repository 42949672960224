import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppBar,
  Box,
  Button,
  Card,
  Drawer,
  IconButton,
  Popover,
  Stack,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AssetType,
  CaseStageType,
  ICase,
  IPlan,
  IPlanImplant,
  LevelType,
  Nullable,
  PartType,
  caseUtils,
  format,
  ImplantType,
} from '@workflow-nx/common';
import { file } from '@workflow-nx/utils';
import { Color3, Mesh, Scene, Vector3 } from 'babylonjs';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import CustomDialog from '../../../../../components/CustomDialog';
import { CaseViewContext } from '../../CaseView.context';
import AddImplantDialog from './Dialogs/AddImplantDialog';
import EditImplantDialog from './Dialogs/EditImplantDialog';
import { ImplantEditorDialogContext } from './ImplantEditorDialog.context';
import {
  loadImplant,
  setupEditingView,
  setupInitialView,
  setupSpineView,
} from './ImplantEditorDialog.helpers';
import { ImplantEditorOverlayView } from './ImplantEditorOverlayView';
import { ImplantList } from './ImplantList';
import { getImplantPlanStatusInfo } from './ImplantListLevelView';
import MoveImplantView from './MoveImplantView/MoveImplantView';
import { updateMeshPositionAndRotation } from './MoveImplantView/MoveImplantView.helpers';
import SecondaryImplantOverlay from './SecondaryImplantOverlay';
import MeshViewer from './components/MeshViewer';
import { getImplantHasScrews } from './utils/implantEditor';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100,
  },
  canvasRoot: {
    '& canvas': {
      width: '100%',
      height: '100%',
      touchAction: 'none',
    },
  },
  dialogContainer: {
    flex: 1,
    width: '100%',
    height: 'calc(100vh - 100px)',
  },
  dialogContainerContent: {
    display: 'flex',
    maxWidth: '100%',
  },
  improvedScroller: {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 5,
    },
  },
}));

type ImplantEditorDialogProps = {
  onClose: (shouldUpdate: boolean) => void;
  open: boolean;
  plan: IPlan;
  activeCase: ICase;
  allowedPlusSizeExcludedImplantTypes: ImplantType[];
};

export async function getAssetText(signedDownloadUrl?: string) {
  if (!signedDownloadUrl) {
    return undefined;
  }
  const { data } = await file.downloadFile(signedDownloadUrl, { responseType: 'text' });
  return data;
}

const ImplantEditorDialog = ({
  onClose,
  open,
  plan,
  activeCase,
  allowedPlusSizeExcludedImplantTypes,
}: ImplantEditorDialogProps) => {
  const styles = useStyles();
  const [isImplantListLoading, setIsImplantListLoading] = useState(false);
  const [moveImplantEditorReady, setMoveImplantEditorReady] = useState(false);
  const caseViewContext = useContext(CaseViewContext);
  const implantEditorDialogContext = useContext(ImplantEditorDialogContext);

  const scene = implantEditorDialogContext.scene;
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [findImplantPart] = useLazyQuery(gql`
    query FindImplantPart(
      $partType: LevelPart!
      $ap: Int!
      $ml: Int!
      $cageTaper: Int
      $graftWindow: String
      $level: String
      $view: ImplantView
      $obliqueThreadAngle: Int
      $cranialCaudalThreadAngle: Int
    ) {
      implantPart(
        input: {
          partType: $partType
          ap: $ap
          ml: $ml
          level: $level
          cageTaper: $cageTaper
          graftWindow: $graftWindow
          view: $view
          obliqueThreadAngle: $obliqueThreadAngle
          cranialCaudalThreadAngle: $cranialCaudalThreadAngle
        }
      ) {
        signedDownloadUrl
      }
    }
  `);

  const [findPlanAssetsAndSettings] = useLazyQuery(
    gql`
      query FindPlanImplantsAndAssets($caseId: Int!, $planId: Int!, $assetTypeFilter: [AssetType]) {
        planAssets: assets(caseId: $caseId, planId: $planId, assetTypeFilter: $assetTypeFilter) {
          assetId
          assetType
          planId
          signedDownloadUrl
        }
        settings {
          form19
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async (data) => {
        dispatch({
          type: 'INIT',
          data: {
            assets: data.planAssets,
            settings: data.settings ?? {},
          },
        });
      },
    },
  );

  const state = implantEditorDialogContext.state;
  const dispatch = implantEditorDialogContext.dispatch;

  const handleReady = (scene: Scene) => {
    implantEditorDialogContext.handleSceneReady(scene);
  };

  const handleCloseClick = async () => {
    try {
      onClose(true);
      dispatch({ type: 'DISPOSE' });
    } catch (e) {
      //empty catch
    }
  };

  const handleLoadImplantAssets = useCallback(async () => {
    if (state.isImplantsLoaded) return;

    if (state.moveImplantLevel !== undefined) return;

    for (const planImplant of implantEditorDialogContext.planImplants) {
      if (planImplant && scene) {
        await implantEditorDialogContext.loadImplantAsset(planImplant, scene);
      }
    }

    if (scene) {
      setupSpineView(
        activeCase.spineType,
        activeCase.levels,
        implantEditorDialogContext.planImplants as IPlanImplant[],
        scene,
      );
    }
  }, [implantEditorDialogContext.planImplants]);

  const handleLoad = useCallback(async () => {
    if (!scene || state.isSceneLoaded || !activeCase || !plan) return;

    await setupInitialView(
      plan,
      state.vertebralBodyAssets,
      state.measurements,
      activeCase.spineProfile,
      true,
      scene,
    );

    await implantEditorDialogContext.findPlanImplants();

    dispatch({ type: 'SCENE_LOADED', data: {} });
  }, [scene, state.vertebralBodyAssets, state.caseLevels, state.isSceneLoaded]);

  useEffect(() => {
    if (state.isSceneReady && state.isAssetsLoaded) {
      handleLoad();
    }
  }, [state.isSceneReady, state.isAssetsLoaded, handleLoad]);

  useEffect(() => {
    if (state.isSceneLoaded) {
      handleLoadImplantAssets().then(() => {
        dispatch({ type: 'IMPLANTS_LOADED', data: {} });
      });
    }
  }, [state.isSceneLoaded, handleLoadImplantAssets]);

  useEffect(() => {
    if (!state.isAssetsLoaded) {
      const validLevels = caseUtils.getCaseVertebralBodyAssets(
        activeCase.spineProfile,
        'desc',
      ).standard;

      findPlanAssetsAndSettings({
        variables: {
          caseId: activeCase.caseId,
          planId: plan?.planId,
          assetTypeFilter: [...validLevels, AssetType.Pelvis, AssetType.HardwareDaisy],
        },
      });
    }
  }, [state.isAssetsLoaded]);

  const handleRemoveImplantClick = async (implantLevel: LevelType, partType: PartType) => {
    if (!scene) return;

    const foundImplant = await implantEditorDialogContext.findPlanImplant(implantLevel);

    if (!foundImplant || !foundImplant.planImplantId) {
      return;
    }

    try {
      await confirm({
        title: `Remove implant at ${format.formatLevelType(implantLevel)} ?`,
        description: (
          <>
            This will remove the implant and the cut for level
            {format.formatLevelType(implantLevel)}. Are you sure you wish to continue?
          </>
        ),
      });

      setIsImplantListLoading(true);

      scene.getMeshByName(`${implantLevel}_IMPLANT_SCREW`)?.dispose();
      scene.getMeshByName(`${implantLevel}_IMPLANT_SCREW_GUIDE`)?.dispose();
      scene.getMeshByName(`${implantLevel}_IMPLANT_INSTRUMENT`)?.dispose();
      scene.getMeshByName(`${implantLevel}_CYBORG_IMPLANT`)?.dispose();
      scene.getMeshByName(`${implantLevel}_${partType}_MINI`)?.dispose();
      scene.getMeshByName(`${implantLevel}_APP`)?.dispose();
      scene.getMeshByName(`${implantLevel}_APP_MINUS`)?.dispose();

      await implantEditorDialogContext.removePlanImplant(foundImplant.planImplantId);

      implantEditorDialogContext.dispatch({
        type: 'EDIT_IMPLANT_POSITION',
        data: {
          level: implantLevel,
          implant: undefined,
        },
      });

      setIsImplantListLoading(false);
    } catch (e) {
      console.log('Cancelled removing implant');
    }
  };

  async function handleEditImplant(editedImplant?: IPlanImplant, reloadImplant?: boolean) {
    if (!scene) return;

    if (editedImplant) {
      setIsImplantListLoading(true);

      try {
        if (reloadImplant) {
          // clean up meshes before loading the new one
          const implantMesh = scene?.getMeshByName(`${editedImplant.level}_CYBORG_IMPLANT`) as Mesh;

          // remove existing meshes
          implantMesh.dispose();

          const cutMeshPlan = scene?.getMeshByName(`${editedImplant.level}_APP`) as Mesh;
          if (cutMeshPlan) {
            cutMeshPlan.dispose();
          }

          const cutMeshMinus = scene?.getMeshByName(`${editedImplant.level}_APP_MINUS`) as Mesh;
          if (cutMeshMinus) {
            cutMeshMinus.dispose();
          }

          const miniMesh = scene?.getMeshByName(
            `${editedImplant.level}_${editedImplant.partType}_MINI`,
          ) as Mesh;
          if (miniMesh) {
            miniMesh.dispose();
          }

          const instrumentMesh = scene?.getMeshByName(
            `${editedImplant.level}_${editedImplant.partType}_IMPLANT_INSTRUMENT`,
          ) as Mesh;
          if (instrumentMesh) {
            miniMesh.dispose();
          }

          const hasScrews = getImplantHasScrews(editedImplant.partType);

          if (hasScrews) {
            const screwMesh = scene.getMeshByName(`${editedImplant.level}_IMPLANT_SCREW`);
            screwMesh?.dispose();

            const screwGuide = scene.getMeshByName(`${editedImplant.level}_IMPLANT_SCREW_GUIDE`);
            screwGuide?.dispose();
          }

          // once existing meshes are cleared, load the new ones
          const { data } = await findImplantPart({
            variables: {
              partType: editedImplant.partType,
              ap: editedImplant.ap,
              ml: editedImplant.ml,
              cageTaper: editedImplant.cageTaper,
              graftWindow: editedImplant.graftWindow,
              level: editedImplant.level,
              obliqueThreadAngle: editedImplant.obliqueThreadAngle,
              cranialCaudalThreadAngle: editedImplant.cranialCaudalThreadAngle,
            },
          });

          const editedImplantMesh = await loadImplant(
            editedImplant.level,
            data.implantPart.signedDownloadUrl,
            `${editedImplant.level}_CYBORG_IMPLANT` as AssetType,
            editedImplant,
            scene,
            findImplantPart,
            {
              color: Color3.FromHexString('#e6dca6'), // titanium color
              setPosition: true,
              defaultVisibility: 1,
              tags: ['template', 'implant', editedImplant.level],
            },
          );

          await implantEditorDialogContext.uploadAssets(
            editedImplant?.level,
            editedImplant?.partType,
            editedImplantMesh as Mesh,
            scene,
          );

          dispatch({
            type: 'EDIT_IMPLANT_POSITION',
            data: {
              level: editedImplant.level,
              implant: editedImplant,
            },
          });

          enqueueSnackbar(
            `${format.formatPartType(editedImplant.partType)} ${editedImplant.ap}mm x ${
              editedImplant.ml
            } implant loaded`,
            {
              variant: 'success',
            },
          );
        }

        const existingImplant = await implantEditorDialogContext.findPlanImplant(
          editedImplant.level,
        );
        await implantEditorDialogContext.upsertPlanImplant(
          existingImplant?.planImplantId,
          plan.planId,
          editedImplant,
        );
      } catch (e) {
        console.error('Error loading implant', e);
        enqueueSnackbar(`The implant failed to load`, {
          variant: 'error',
        });
      } finally {
        setIsImplantListLoading(false);
      }
    }

    dispatch({
      type: 'TOGGLE_EDIT_IMPLANT_DIALOG',
      data: { open: false, editImplantLevel: '' },
    });
  }

  async function handleAddImplant(addedImplant?: IPlanImplant) {
    if (!scene) return;

    setIsImplantListLoading(true);

    if (addedImplant) {
      try {
        const {
          partType,
          ap,
          ml,
          cageTaper,
          graftWindow,
          obliqueThreadAngle,
          cranialCaudalThreadAngle,
        } = addedImplant;
        const implantLevel = state.addImplantLevel as LevelType;
        const { data } = await findImplantPart({
          variables: {
            partType,
            ap,
            ml,
            cageTaper,
            graftWindow,
            obliqueThreadAngle,
            cranialCaudalThreadAngle,
            level: implantLevel,
          },
        });

        if (data.implantPart.signedDownloadUrl) {
          const addedImplantMesh = await loadImplant(
            implantLevel,
            data.implantPart.signedDownloadUrl,
            `${implantLevel}_CYBORG_IMPLANT` as AssetType,
            addedImplant,
            scene,
            findImplantPart,
            {
              color: Color3.FromHexString('#e6dca6'), // titanium color
              setPosition: true,
              defaultVisibility: 1,
              tags: ['template', 'implant', implantLevel],
            },
          );

          if (addedImplantMesh) {
            const existingImplant = await implantEditorDialogContext.findPlanImplant(implantLevel);
            await implantEditorDialogContext.upsertPlanImplant(
              existingImplant?.planImplantId,
              plan.planId,
              addedImplant,
            );

            await implantEditorDialogContext.uploadAssets(
              addedImplant?.level,
              addedImplant?.partType,
              addedImplantMesh as Mesh,
              scene,
            );

            dispatch({
              type: 'EDIT_IMPLANT_POSITION',
              data: { level: implantLevel, implant: addedImplant },
            });
          }
        }

        enqueueSnackbar(`${format.formatPartType(addedImplant.partType)} implant loaded`, {
          variant: 'success',
        });

        handleMoveImplantClick(implantLevel, addedImplant.partType);
      } catch (e) {
        console.error('Error loading implant', e);
        enqueueSnackbar(`The implant failed to load`, {
          variant: 'error',
        });
      }
    }

    setIsImplantListLoading(false);
    dispatch({
      type: 'TOGGLE_ADD_IMPLANT_DIALOG',
      data: { open: false, addImplantLevel: '' },
    });
  }

  function handleAddImplantClick(implantLevel: LevelType) {
    dispatch({
      type: 'TOGGLE_ADD_IMPLANT_DIALOG',
      data: { open: true, addImplantLevel: implantLevel },
    });
  }

  const handleEditImplantClick = async (implantLevel: LevelType) => {
    if (!scene) return;

    const meshByName = scene.getMeshByName(`${implantLevel}_APP`);
    const cutMesh = meshByName as Mesh;

    const editingImplant = await implantEditorDialogContext.findPlanImplant(implantLevel);

    try {
      if (cutMesh) {
        await confirm({
          allowClose: false,
          title: `${format.formatLevelType(implantLevel)} cut will be deleted!`,
          description: `The ${format.formatPartType(
            editingImplant?.partType as PartType,
          )} implant at ${format.formatLevelType(
            implantLevel,
          )} has already been cut, editing the size of this implant will delete that cut implant and will require it to be re-cut. Do you wish to continue?.`,
        });
      }

      dispatch({
        type: 'TOGGLE_EDIT_IMPLANT_DIALOG',
        data: {
          open: true,
          editImplantLevel: implantLevel,
        },
      });

      dispatch({
        type: 'EDIT_IMPLANT_POSITION',
        data: {
          level: implantLevel,
          implant: editingImplant,
        },
      });
    } catch (e) {
      console.log('Edit cancelled');
    }
  };

  async function handleMoveImplantClick(implantLevel: LevelType, partType: PartType) {
    if (!scene) return;

    setMoveImplantEditorReady(false);

    setupEditingView(activeCase.spineType, implantLevel, partType, scene);

    const editingImplant = await implantEditorDialogContext.findPlanImplant(implantLevel);

    dispatch({
      type: 'EDIT_IMPLANT_POSITION',
      data: { level: implantLevel, implant: editingImplant },
    });

    setMoveImplantEditorReady(true);

    const cutMesh = scene.getMeshByName(`${implantLevel}_APP`) as Nullable<Mesh>;

    setMoveImplantEnabled(cutMesh === null);
  }

  useEffect(() => {
    scene?.getEngine()?.resize();
  }, [state.moveImplantLevel]);

  const meshViewerLoaded = state.isSceneLoaded && state.isImplantsLoaded;

  const theme = useTheme();

  const handleClearImplant = async (shouldUpdate: boolean) => {
    if (!scene) return;

    if (shouldUpdate) {
      await implantEditorDialogContext.findPlanImplants();
    }

    setupSpineView(
      activeCase.spineType,
      activeCase.levels,
      implantEditorDialogContext.planImplants,
      scene,
    );

    dispatch({
      type: 'EDIT_IMPLANT_POSITION',
      data: { level: undefined, implant: undefined },
    });

    setApplyingImplantChanges(false);
  };

  const handleCancelClick = async (): Promise<boolean> => {
    const levelType = state.moveImplantLevel;

    if (!levelType) return true;

    const planImplant = await implantEditorDialogContext.findPlanImplant(levelType);

    if (!planImplant) return true;

    try {
      if (state.moveImplantIsDirty) {
        await confirm({
          title: `${format.formatLevelType(levelType)} changes will be reverted`,
          description: `The ${format.formatPartType(
            planImplant.partType,
          )} implant at ${format.formatLevelType(
            levelType,
          )} Cancelling will revert the position back to where you started. Do you wish to continue?.`,
        });

        const originalPositionAndRotation =
          implantEditorDialogContext.state.moveImplantOriginalPositionAndRotation;

        const meshName = `${levelType}_CYBORG_IMPLANT`;
        const mesh = scene?.getMeshByName(meshName) as Nullable<Mesh>;

        if (mesh && originalPositionAndRotation && originalPositionAndRotation.rotation) {
          const originalPosition = originalPositionAndRotation.position;
          const originalRotation = originalPositionAndRotation.rotation.toEulerAngles();

          updateMeshPositionAndRotation(
            new Vector3(originalPosition.x, originalPosition.y, originalPosition.z),
            new Vector3(originalRotation.x, originalRotation.y, originalRotation.z),
            mesh,
          );
        }
      }

      await handleClearImplant(state.moveImplantShouldUpdate ?? false);

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHelpAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setHelpAnchorEl(null);
  };

  const helpOpen = Boolean(helpAnchorEl);

  const implantPlanStatusInfo = getImplantPlanStatusInfo(theme);

  const [secondaryDrawerOpen, setSecondaryDrawerOpen] = useState(true);

  const [moveImplantEnabled, setMoveImplantEnabled] = useState(false);

  const [applyingImplantChanges, setApplyingImplantChanges] = useState(false);

  const implantFocusReady = state.moveImplantLevel && scene && meshViewerLoaded;

  const implantToolbarVisible = implantFocusReady && state.moveImplant && !applyingImplantChanges;

  const handleEnableMoveImplant = (levelType: LevelType) => {
    const implantMesh = scene?.getMeshByName(`${levelType}_CYBORG_IMPLANT`) as Nullable<Mesh>;

    if (implantMesh) {
      implantMesh.visibility = 1;
    }

    const cutMeshPlan = scene?.getMeshByName(`${levelType}_APP`) as Nullable<Mesh>;

    if (cutMeshPlan) {
      cutMeshPlan.visibility = 0;
    }

    const cutMeshMinus = scene?.getMeshByName(`${levelType}_APP_MINUS`) as Nullable<Mesh>;

    if (cutMeshMinus) {
      cutMeshMinus.visibility = 0;
    }

    setMoveImplantEnabled(true);
  };

  const handleApplyChangesComplete = async (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      await implantEditorDialogContext.findPlanImplants();
    }
    enqueueSnackbar('Implant changes saved successfully.', { variant: 'success' });
    setApplyingImplantChanges(false);
  };

  return (
    <CustomDialog
      fullScreen={true}
      open={open}
      title={`Editing Implants -  ${activeCase.number} - ${plan.name}`}
      onClose={handleCloseClick}
      dialogContentClassName={styles.dialogContainerContent}
      containerClassName={styles.dialogContainer}
    >
      {implantToolbarVisible ? (
        <AppBar position="relative" sx={{ bgcolor: '#98a8b5' }}>
          <Toolbar variant="dense">
            <Box flexGrow={1} />
            <Button
              color="inherit"
              onClick={() => setSecondaryDrawerOpen(!secondaryDrawerOpen)}
              size="small"
            >
              {secondaryDrawerOpen ? 'Close Controls' : 'Open Controls'}
            </Button>
          </Toolbar>
        </AppBar>
      ) : null}
      <Box
        position={'relative'}
        height={implantToolbarVisible ? 'calc(100% - 50px)' : '100%'}
        overflow="hidden"
      >
        <Box display={'flex'} height={'100%'}>
          <Box className={styles.canvasRoot} width={'100%'}>
            <MeshViewer
              loading={!meshViewerLoaded}
              onMeshControlClick={(position) => {
                dispatch({
                  type: 'POINT_OF_ROTATION_CHANGED',
                  data: position,
                });
              }}
              onReady={handleReady}
              canvasName={'planCanvas'}
            />
          </Box>
        </Box>
        {/* Dialogs */}
        {state.isSceneLoaded && scene ? (
          <Box position="absolute" left={30} top={80}>
            {state.addImplantLevel ? (
              <AddImplantDialog
                planId={plan.planId}
                caseLevels={activeCase?.levels}
                patientRecord={implantEditorDialogContext?.patientRecord}
                open={state.showAddImplantDialog}
                onClose={handleAddImplant}
                level={state.addImplantLevel}
                scene={scene}
                form19={state.settings?.form19}
                allowedPlusSizeExcludedImplantTypes={allowedPlusSizeExcludedImplantTypes}
              />
            ) : null}
            {state.editImplantLevel ? (
              <EditImplantDialog
                open={state.showEditImplantDialog}
                onClose={async (editedImplant, reloadImplant) => {
                  await handleEditImplant(editedImplant, reloadImplant);
                }}
                level={state.editImplantLevel}
                form19={state?.settings?.form19}
                allowedPlusSizeExcludedImplantTypes={allowedPlusSizeExcludedImplantTypes}
                patientRecord={implantEditorDialogContext?.patientRecord}
              />
            ) : null}
          </Box>
        ) : null}

        {/* Main side panel */}
        {scene && meshViewerLoaded ? (
          <Drawer
            hideBackdrop
            anchor={'left'}
            open={true}
            variant="persistent"
            sx={{
              zIndex: 100000,

              '& .MuiDrawer-root': {
                position: 'relative',
              },
              '& .MuiDrawer-paper': {
                position: 'absolute',
                background: 'transparent',
              },
            }}
          >
            <Box
              height="100%"
              data-testid="implant-orientation-coordinates-rotation"
              width={375}
              p={1.5}
            >
              <Box
                height="100%"
                width={'100%'}
                sx={{ backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 1 }}
                position={'relative'}
              >
                <Box
                  data-testid="implant-editor-menu"
                  p={1.5}
                  className={styles.improvedScroller}
                  overflow={'auto'}
                  height="100%"
                  width={'100%'}
                >
                  <Box>
                    <Card>
                      <Box flexGrow={1} p={1} bgcolor={theme.palette.grey[100]}>
                        <Box
                          px={1}
                          display={'flex'}
                          flexGrow={1}
                          alignItems="center"
                          justifyContent={'space-between'}
                        >
                          <Typography variant="h6">Current Implant</Typography>
                          <IconButton size="medium" onClick={handleHelpClick}>
                            <FontAwesomeIcon icon={faQuestionCircle} size="xs" />
                          </IconButton>
                          <Popover
                            open={helpOpen}
                            anchorEl={helpAnchorEl}
                            onClose={handleHelpClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <Stack p={2}>
                              {[
                                implantPlanStatusInfo.noImplant,
                                implantPlanStatusInfo.positionedUncut,
                                implantPlanStatusInfo.positionedCut,
                              ].map((info, index) => (
                                <Box
                                  mt={index !== 0 ? 1 : 0}
                                  display="flex"
                                  alignItems="center"
                                  key={index}
                                >
                                  <Box
                                    bgcolor={info.color}
                                    width={12}
                                    height={12}
                                    borderRadius={5}
                                    mr={2}
                                  />

                                  <Typography>{info.label}</Typography>
                                </Box>
                              ))}
                            </Stack>
                          </Popover>
                        </Box>
                      </Box>
                      <Box flexGrow={1} p={2}>
                        <ImplantList
                          caseId={plan.caseId}
                          disabled={
                            activeCase.stage !== CaseStageType.Planning || applyingImplantChanges
                          }
                          planId={plan.planId}
                          levels={activeCase?.levels ?? {}}
                          loading={!meshViewerLoaded || isImplantListLoading}
                          scene={scene}
                          onAdd={handleAddImplantClick}
                          onMove={handleMoveImplantClick}
                          onEdit={handleEditImplantClick}
                          onRemove={handleRemoveImplantClick}
                          onUpdate={async (level: LevelType) => {
                            const existingPlanImplant =
                              await implantEditorDialogContext.findPlanImplant(level);
                            if (existingPlanImplant) {
                              await implantEditorDialogContext.upsertPlanImplant(
                                existingPlanImplant?.planImplantId,
                                plan.planId,
                                existingPlanImplant,
                              );
                            }
                          }}
                          onClear={handleCancelClick}
                          allowedPlusSizeExcludedImplantTypes={allowedPlusSizeExcludedImplantTypes}
                        />
                      </Box>
                    </Card>

                    {state.moveImplantLevel && state.moveImplant && moveImplantEditorReady ? (
                      <>
                        <Box mb={2} />

                        {moveImplantEnabled ? (
                          <MoveImplantView
                            caseId={activeCase.caseId}
                            disabled={activeCase.stage !== CaseStageType.Planning}
                            levelType={state.moveImplantLevel as LevelType}
                            partType={state.moveImplant.partType}
                            scene={scene}
                            form19={state.settings?.form19}
                            measurements={state.measurements}
                            onApply={handleApplyChangesComplete}
                            handleApply={(applying) => setApplyingImplantChanges(applying)}
                            loading={applyingImplantChanges}
                          />
                        ) : (
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleEnableMoveImplant(state.moveImplantLevel!)}
                          >
                            Edit Implant
                          </Button>
                        )}

                        <Box my={4} />
                      </>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        ) : null}

        {/* Measurement table */}
        {meshViewerLoaded &&
        !state.moveImplantLevel &&
        plan?.assetPositions &&
        scene &&
        activeCase ? (
          <ImplantEditorOverlayView
            caseSpineType={activeCase.spineType}
            assetPositions={plan.assetPositions}
            caseSpineProfile={activeCase.spineProfile}
            caseLevels={state.caseLevels}
            patientRecord={activeCase.patient.patientRecord}
            measurementsVersion={caseViewContext.caseMeasurementsVersion}
          />
        ) : null}

        {implantFocusReady &&
        state.moveImplantLevel &&
        state.moveImplant &&
        !applyingImplantChanges ? (
          <SecondaryImplantOverlay
            activeCase={activeCase}
            levelType={state.moveImplantLevel}
            scene={scene}
            open={secondaryDrawerOpen}
          />
        ) : null}
      </Box>
    </CustomDialog>
  );
};

export default ImplantEditorDialog;
