import { ApolloQueryResult } from '@apollo/client/core/types';
import { OrganizationType } from '@workflow-nx/common';

export type ListOrganizationsActionType = {
  type:
    | 'RESET_FILTERS'
    | 'SEARCH_CHANGED'
    | 'ORDER_BY_CHANGED'
    | 'ORGANIZATION_TYPE_CHANGED'
    | 'UPDATE_PAGINATION'
    | 'REGION_CHANGED';
  data?: any;
};

export type ListOrganizationsStateType = {
  search: string;
  organizationType: OrganizationType;
  regionId?: number;
  orderBy: { name: 'asc' };
  pageNumber: number;
  pageSize: number;
};

export const ListOrganizationsReducer =
  (refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>) =>
  (
    state: ListOrganizationsStateType,
    action: ListOrganizationsActionType,
  ): ListOrganizationsStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'RESET_FILTERS':
        updatedState = {
          organizationType: OrganizationType.Hospital,
          search: '',
          orderBy: { name: 'asc' },
          regionId: null,
        };
        break;
      case 'ORGANIZATION_TYPE_CHANGED':
        updatedState.organizationType = action.data;
        break;
      case 'SEARCH_CHANGED':
        updatedState.search = action.data;
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
      case 'REGION_CHANGED':
        updatedState.regionId = action.data;
        break;
      case 'UPDATE_PAGINATION':
        updatedState.pageSize = action.data.pageSize;
        updatedState.pageNumber = action.data.pageNumber;
        break;
    }

    // Reset page number if a query parameter has changed
    if (action.type !== 'UPDATE_PAGINATION') {
      updatedState.pageNumber = 0;
    }

    refetch({
      organizationTypeFilter: updatedState.organizationType
        ? [updatedState.organizationType]
        : undefined,
      regionId: updatedState.regionId ? updatedState.regionId : undefined,
      orderBy: updatedState.orderBy,
      search: updatedState.search,
      take: updatedState.pageSize,
      skip:
        updatedState.pageSize && updatedState.pageNumber
          ? updatedState.pageSize * updatedState.pageNumber
          : undefined,
    });

    return updatedState;
  };
