import { environment } from '../../environments/environment';
const {
  NX_AUTH_METHOD,
  NX_SERVER_GQL,
  NX_AUTH_CLIENT_ID,
  NX_AUTH_REDIRECT_URI,
  NX_AUTH_SCOPE,
  NX_AUTH_RESPONSE_TYPE,
  NX_AUTH_URL,
  NX_OAUTH_URL,
  NX_OAUTH_IDP_JUMPCLOUD,
  NX_OAUTH_IDP_GOOGLE,
  NX_DICOM_MAX_FILE_SIZE_BYTES,
  NX_PUBLIC_BRANCH,
  NX_PUBLIC_VERSION,
  NX_CYBORG_ENDPOINT,
  NX_CLIENT_URL,
  NX_CLIENT_ID,
  NX_FF_ALIFX_ORIENTATION,
  NX_FF_TLIFC_ORIENTATION,
  NX_FF_AUTO_CORRECT,
  NX_FF_M4_INSERTER_INVENTORY_MANAGEMENT,
  NX_FF_M4L_EXPANDED_INVENTORY_AVAILABLE,
  NX_FF_DASHBOARD_PRODUCTION_CALENDAR,
  NX_MEASUREMENTS_POSTERIOR_HEIGHT_MIN,
  NX_MEASUREMENTS_POSTERIOR_HEIGHT_MAX,
  NX_MEASUREMENTS_ANTERIOR_HEIGHT_MIN,
  NX_MEASUREMENTS_ANTERIOR_HEIGHT_MAX,
  NX_MEASUREMENTS_LORDOTIC_ANGLE_MAX,
  NX_MEASUREMENTS_CORONAL_ANGLE_MAX,
  NX_MEASUREMENTS_ANTERIOR_HEIGHT_MIN_PREOP,
  NX_MEASUREMENTS_SHOULD_DISPLAY_PREOP_DIFFERENCES,
  NX_ONESIGNAL_APP_ID,
  NX_ONESIGNAL_ENABLED,
  NX_SENTRY_DSN,
  NX_MIXPANEL_TOKEN,
  NX_ENVIRONMENT,
  NX_BUILD_NUMBER,
} = environment;

export const config = {
  auth: {
    method: NX_AUTH_METHOD,
    clientId: NX_AUTH_CLIENT_ID,
    redirectUri: NX_AUTH_REDIRECT_URI,
    scope: NX_AUTH_SCOPE,
    responseType: NX_AUTH_RESPONSE_TYPE,
    url: NX_AUTH_URL,
    oauth: {
      url: NX_OAUTH_URL,
      idp: { jumpCloud: NX_OAUTH_IDP_JUMPCLOUD, google: NX_OAUTH_IDP_GOOGLE },
    },
    gql: NX_SERVER_GQL,
  },
  client: { id: NX_CLIENT_ID, url: NX_CLIENT_URL },
  cyborg: {
    endpoint: NX_CYBORG_ENDPOINT,
  },
  oneSignal: {
    appId: NX_ONESIGNAL_APP_ID,
    enabled: NX_ONESIGNAL_ENABLED,
  },
  sentry: {
    dsn: NX_SENTRY_DSN,
  },
  mixpanel: {
    token: NX_MIXPANEL_TOKEN,
  },
  dicomMaxFileSizeBytes: NX_DICOM_MAX_FILE_SIZE_BYTES,
  featureFlags: {
    alifXOrientation: NX_FF_ALIFX_ORIENTATION,
    tlifCOrientation: NX_FF_TLIFC_ORIENTATION,
    autoCorrect: NX_FF_AUTO_CORRECT,
    dashboardProductionCalendar: NX_FF_DASHBOARD_PRODUCTION_CALENDAR,
    m4InserterInventoryManagement: NX_FF_M4_INSERTER_INVENTORY_MANAGEMENT,
    m4lExpandedInventoryAvailable: NX_FF_M4L_EXPANDED_INVENTORY_AVAILABLE,
    designRequireTem013: environment.NX_FF_DESIGN_REQUIRE_TEM_013,
    tem013Automation: environment.NX_FF_TEM_013_AUTOMATION,
    magicLinks: environment.NX_FF_MAGIC_LINKS,
  },
  measurementsConfig: {
    posteriorHeightMin: NX_MEASUREMENTS_POSTERIOR_HEIGHT_MIN,
    posteriorHeightMax: NX_MEASUREMENTS_POSTERIOR_HEIGHT_MAX,
    anteriorHeightMin: NX_MEASUREMENTS_ANTERIOR_HEIGHT_MIN,
    anteriorHeightMax: NX_MEASUREMENTS_ANTERIOR_HEIGHT_MAX,
    lordoticAngleMax: NX_MEASUREMENTS_LORDOTIC_ANGLE_MAX,
    coronalAngleMax: NX_MEASUREMENTS_CORONAL_ANGLE_MAX,
    anteriorHeightPreOp: NX_MEASUREMENTS_ANTERIOR_HEIGHT_MIN_PREOP,
    shouldDisplayPreopDifferences: NX_MEASUREMENTS_SHOULD_DISPLAY_PREOP_DIFFERENCES,
  },
  environmentName: NX_ENVIRONMENT,
  version: NX_PUBLIC_VERSION,
  branch: NX_PUBLIC_BRANCH,
  buildNumber: NX_BUILD_NUMBER,
  operations: {
    mainstreamDocumentsUrl: 'https://carlsmed.app.box.com/folder/123286877434',
  },
};

export default config;
